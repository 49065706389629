import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, Box, Divider, Button, Typography, IconButton } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import logo from '../../assets/images/logo.png';

// Importamos los iconos
import { FiHome, FiCalendar, FiUsers, FiUserPlus, FiMessageSquare, FiSettings, FiLogOut, FiCpu, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BsCashCoin } from 'react-icons/bs';
import { AiOutlineUserAdd } from 'react-icons/ai';
import logoIcono from '../../assets/images/imagen_redimensionada.png';
import logoDiscord from '../../assets/images/logo_discord_claro.png';
import Modal from '@mui/material/Modal';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Tooltip from '@mui/material/Tooltip';
import { Lightning } from "@phosphor-icons/react";

const Navbar = () => {
    const expandedDrawerWidth = 240;
    const collapsedDrawerWidth = 65;
    const [isExpanded, setIsExpanded] = useState(true);
    const [openDiscordModal, setOpenDiscordModal] = useState(false);
    const drawerWidth = isExpanded ? expandedDrawerWidth : collapsedDrawerWidth;
    const { logout, userDetails } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const navItems = [
        { text: 'Inicio', icon: <FiHome size={24} />, path: '/' },
        { text: 'Generar', icon: <FiCpu size={24} />, path: '/generar' },
        { text: 'Asistente', icon: <Lightning size={24} weight="duotone" />, path: '/chatbot' },
        // Páginas temporalmente ocultas:
        // { text: 'Clases', icon: <FiCalendar size={24} />, path: '/classes' },
        { text: 'Clientes', icon: <FiUsers size={24} />, path: '/clients' },
        { text: 'Recursos', icon: <VideoLibraryIcon />, path: '/recursos' },
        // { text: 'Leads', icon: <AiOutlineUserAdd size={24} />, path: '/potential-clients', tag: 'Soon' },
        { text: 'Mensajes', icon: <FiMessageSquare size={24} />, path: '/messages' },
        // { text: 'Facturación', icon: <BsCashCoin size={24} />, path: '/facturacion' },
        { text: 'Configuración', icon: <FiSettings size={24} />, path: '/settings' },
        // Agregar el ítem de Admin solo si el usuario es administrador
        ...(userDetails?.admin ? [{ text: 'Admin', icon: <FiSettings size={24} />, path: '/admin' }] : [])
    ];

    const DiscordModal = () => (
        <Modal
            open={openDiscordModal}
            onClose={() => setOpenDiscordModal(false)}
            aria-labelledby="discord-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="discord-modal-title" variant="h6" component="h2" gutterBottom>
                    Únete a la comunidad de FitAI Labs
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    ¿Quieres formar parte de nuestra comunidad de entrenadores? ¡Únete a nuestro Discord!
                </Typography>
                <Button
                    variant="contained"
                    fullWidth
                    href="https://discord.gg/tu-link"
                    target="_blank"
                    sx={{
                        backgroundColor: '#5865F2',
                        '&:hover': {
                            backgroundColor: '#4752C4',
                        },
                    }}
                >
                    Unirse a Discord
                </Button>
            </Box>
        </Modal>
    );

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        background: 'linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,243,224,0.95) 100%)',
                        color: '#333',
                        transition: 'width 0.3s ease, border-radius 0.3s ease',
                        overflowX: 'hidden',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: isExpanded ? '0px 16px 16px 0px' : '0px 16px 16px 0px',
                        backdropFilter: 'blur(8px)',
                    },
                }}
            >
                <Box 
                    sx={{ 
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column',
                        overflow: 'hidden'
                    }}
                >
                    <Box sx={{ 
                        p: 2, 
                        textAlign: 'center', 
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <img 
                            src={isExpanded ? logo : logoIcono} 
                            alt="FitAI Labs Logo" 
                            style={{ 
                                maxWidth: isExpanded ? '80%' : '40px', 
                                height: 'auto',
                                objectFit: 'contain',
                                marginRight: isExpanded ? 'auto' : '0',
                                marginLeft: isExpanded ? 'auto' : '0'
                            }} 
                        />
                        <IconButton
                            onClick={() => setIsExpanded(!isExpanded)}
                            size="small"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                width: '32px',
                                height: '32px',
                                borderRadius: '8px',
                                '&:hover': { 
                                    backgroundColor: 'rgba(255, 87, 34, 0.2)',
                                },
                                '& svg': {
                                    fontSize: '1.4rem',
                                    color: '#FF5722',
                                    transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                    transition: 'transform 0.3s ease'
                                },
                                transition: 'background-color 0.3s ease',
                                marginRight: '-8px'
                            }}
                        >
                            {isExpanded ? <FiChevronLeft /> : <FiChevronRight />}
                        </IconButton>
                    </Box>
                    <Divider sx={{ backgroundColor: 'rgba(0,0,0,0.1)' }} />
                    <List sx={{ 
                        flexGrow: 1, 
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#ddd',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#ccc',
                        },
                    }}>
                        {navItems.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                {isExpanded ? (
                                    <Button
                                        component={RouterLink}
                                        to={item.path}
                                        startIcon={React.cloneElement(item.icon, { style: { color: '#FF5722' } })}
                                        fullWidth
                                        sx={{
                                            justifyContent: 'flex-start',
                                            color: '#333',
                                            backgroundColor: location.pathname === item.path ? 'rgba(255, 87, 34, 0.1)' : 'transparent',
                                            borderLeft: location.pathname === item.path ? '4px solid #FF5722' : '4px solid transparent',
                                            transition: 'all 0.3s',
                                            py: 1.5,
                                            px: 3,
                                            borderRadius: 1,
                                            transform: 'scale(1)',
                                            '&:hover': {
                                                transform: 'scale(1.02)',
                                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                                boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
                                                borderLeft: '4px solid rgba(255, 87, 34, 0.8)',
                                            },
                                        }}
                                    >
                                        <Typography 
                                            variant="subtitle1" 
                                            sx={{ 
                                                ml: 2, 
                                                fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {item.text}
                                        </Typography>
                                        {item.tag && (
                                            <Typography
                                                component="span"
                                                variant="caption"
                                                sx={{
                                                    ml: 1,
                                                    fontSize: '0.7rem',
                                                    color: 'text.secondary',
                                                    fontStyle: 'bold',
                                                    opacity: 0.7,
                                                }}
                                            >
                                                ({item.tag})
                                            </Typography>
                                        )}
                                    </Button>
                                ) : (
                                    <Tooltip title={item.text} placement="right">
                                        <Button
                                            component={RouterLink}
                                            to={item.path}
                                            startIcon={React.cloneElement(item.icon, { style: { color: '#FF5722' } })}
                                            fullWidth
                                            sx={{
                                                justifyContent: 'center',
                                                color: '#333',
                                                backgroundColor: location.pathname === item.path ? 'rgba(255, 87, 34, 0.1)' : 'transparent',
                                                borderLeft: location.pathname === item.path ? '4px solid #FF5722' : '4px solid transparent',
                                                transition: 'all 0.3s',
                                                py: 1.5,
                                                px: 3,
                                                borderRadius: 1,
                                                transform: 'scale(1)',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                                    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
                                                    borderLeft: '4px solid rgba(255, 87, 34, 0.8)',
                                                },
                                            }}
                                        >
                                            {/* En modo colapsado solo se muestra el ícono */}
                                        </Button>
                                    </Tooltip>
                                )}
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ mt: 'auto', p: 2 }}>
                        <Button
                            fullWidth
                            onClick={() => setOpenDiscordModal(true)}
                            sx={{
                                mb: 2,
                                minWidth: 'auto',
                                justifyContent: isExpanded ? 'flex-start' : 'center',
                                py: 1,
                                color: '#666',
                                transition: 'transform 0.3s, color 0.3s',
                                transform: 'scale(1)',
                                '&:hover': {
                                    color: '#FF5722',
                                    transform: 'scale(1.05)',
                                }
                            }}
                        >
                            <FiUsers size={24} />
                        </Button>
                        <Button
                            fullWidth
                            onClick={handleLogout}
                            sx={{
                                color: '#666',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                    color: '#FF5722',
                                },
                                borderRadius: 2,
                                py: 1,
                                minWidth: 'auto',
                                justifyContent: isExpanded ? 'flex-start' : 'center',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        >
                            <FiLogOut size={24} />
                            {isExpanded && (
                                <Typography sx={{ ml: 2 }}>Cerrar Sesión</Typography>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <DiscordModal />
        </>
    );
};

export default Navbar;
