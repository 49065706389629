import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    CircularProgress,
    Alert,
    Button,
    Tabs,
    Tab,
    Grid,
    Card,
    CardContent,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    TextField,
    IconButton,
    keyframes
} from '@mui/material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import { Restaurant as RestaurantIcon, ExpandMore as ExpandMoreIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FiCoffee, FiSun, FiSunrise, FiMoon, FiClock } from 'react-icons/fi';

// Constantes para hacer el código resiliente a cambios en la nomenclatura
const DIET_STRUCTURE = {
    DAILY: {
        TYPE: 'diario',
        MEALS: {
            BREAKFAST: 'desayuno',
            MORNING_SNACK: 'media_manana',
            LUNCH: 'almuerzo',
            AFTERNOON_SNACK: 'media_tarde',
            DINNER: 'cena',
            BEFORE_BED: 'antes_de_dormir'
        }
    },
    WEEKLY: {
        TYPE: 'semanal',
        DAYS: {
            MONDAY: 'lunes',
            TUESDAY: 'martes',
            WEDNESDAY: 'miercoles',
            THURSDAY: 'jueves',
            FRIDAY: 'viernes',
            SATURDAY: 'sabado',
            SUNDAY: 'domingo'
        },
        MEALS: {
            BREAKFAST: 'desayuno',
            LUNCH: 'almuerzo',
            DINNER: 'cena',
            SNACKS: {
                KEY: 'snacks',
                MORNING: 'media_manana',
                AFTERNOON: 'media_tarde',
                BEFORE_BED: 'antes_de_dormir'
            }
        }
    }
};

const MealIcon = ({ mealType, size = 20 }) => {
    switch (mealType) {
        case DIET_STRUCTURE.DAILY.MEALS.BREAKFAST:
        case DIET_STRUCTURE.WEEKLY.MEALS.BREAKFAST:
            return <FiCoffee size={size} />;
        case DIET_STRUCTURE.DAILY.MEALS.LUNCH:
        case DIET_STRUCTURE.WEEKLY.MEALS.LUNCH:
            return <FiSun size={size} />;
        case DIET_STRUCTURE.DAILY.MEALS.DINNER:
        case DIET_STRUCTURE.WEEKLY.MEALS.DINNER:
            return <FiMoon size={size} />;
        case DIET_STRUCTURE.DAILY.MEALS.MORNING_SNACK:
        case DIET_STRUCTURE.WEEKLY.MEALS.SNACKS.MORNING:
            return <FiSunrise size={size} />;
        case DIET_STRUCTURE.DAILY.MEALS.AFTERNOON_SNACK:
        case DIET_STRUCTURE.WEEKLY.MEALS.SNACKS.AFTERNOON:
            return <FiClock size={size} />;
        case DIET_STRUCTURE.DAILY.MEALS.BEFORE_BED:
        case DIET_STRUCTURE.WEEKLY.MEALS.SNACKS.BEFORE_BED:
            return <FiMoon size={size} />;
        default:
            return <FiClock size={size} />;
    }
};

const DailyMealCard = ({ meal, mealType, onEdit }) => {
    const [editingField, setEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState('');

    const handleStartEdit = (field, value) => {
        setEditingField(field);
        setEditingValue(value);
    };

    const handleSaveEdit = () => {
        if (onEdit) {
            onEdit(mealType, editingField, editingValue);
        }
        setEditingField(null);
        setEditingValue('');
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setEditingValue('');
    };

    return (
        <Card sx={{ mb: 2, width: '100%' }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <MealIcon mealType={mealType} />
                    <Typography variant="h6" sx={{ ml: 1 }}>
                        {mealType.charAt(0).toUpperCase() + mealType.slice(1).replace(/_/g, ' ')}
                    </Typography>
                    {editingField === 'calories' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto' }}>
                            <TextField
                                value={editingValue}
                                onChange={(e) => setEditingValue(e.target.value)}
                                variant="standard"
                                size="small"
                                type="number"
                                autoFocus
                                InputProps={{
                                    endAdornment: <Typography>kcal</Typography>
                                }}
                            />
                            <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                <CheckIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleCancelEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Chip 
                            label={`${meal.calorias_aproximadas || meal.calorias || 0} kcal`}
                            color="primary"
                            size="small"
                            sx={{ ml: 'auto', cursor: 'pointer' }}
                            onClick={() => handleStartEdit('calories', meal.calorias_aproximadas || meal.calorias || 0)}
                        />
                    )}
                </Box>
                {meal.opciones ? (
                    meal.opciones.map((opcion, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            {editingField === `option_name_${index}` ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                    <TextField
                                        value={editingValue}
                                        onChange={(e) => setEditingValue(e.target.value)}
                                        variant="standard"
                                        size="small"
                                        autoFocus
                                    />
                                    <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={handleCancelEdit}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Typography 
                                    variant="subtitle1" 
                                    color="primary"
                                    onClick={() => handleStartEdit(`option_name_${index}`, opcion.nombre)}
                                    sx={{ 
                                        cursor: 'pointer',
                                        '&:hover': { bgcolor: 'action.hover' },
                                        p: 0.5,
                                        borderRadius: 1,
                                        display: 'inline-block'
                                    }}
                                >
                                    {opcion.nombre}
                                </Typography>
                            )}
                            <ul style={{ margin: 0, paddingLeft: 20 }}>
                                {opcion.items.map((item, i) => (
                                    <li key={i}>
                                        {editingField === `option_item_${index}_${i}` ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <TextField
                                                    value={editingValue}
                                                    onChange={(e) => setEditingValue(e.target.value)}
                                                    variant="standard"
                                                    size="small"
                                                    autoFocus
                                                    fullWidth
                                                />
                                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                                    <CheckIcon />
                                                </IconButton>
                                                <IconButton size="small" onClick={handleCancelEdit}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Typography 
                                                variant="body2"
                                                onClick={() => handleStartEdit(`option_item_${index}_${i}`, item)}
                                                sx={{ 
                                                    cursor: 'pointer',
                                                    '&:hover': { bgcolor: 'action.hover' },
                                                    p: 0.5,
                                                    borderRadius: 1,
                                                    display: 'inline-block'
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    ))
                ) : (
                    <ul style={{ margin: 0, paddingLeft: 20 }}>
                        {meal.items?.map((item, i) => (
                            <li key={i}>
                                {editingField === `item_${i}` ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="standard"
                                            size="small"
                                            autoFocus
                                            fullWidth
                                        />
                                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={handleCancelEdit}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Typography 
                                        variant="body2"
                                        onClick={() => handleStartEdit(`item_${i}`, item)}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: 'action.hover' },
                                            p: 0.5,
                                            borderRadius: 1,
                                            display: 'inline-block'
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </CardContent>
        </Card>
    );
};

const WeeklyView = ({ dietData, onEdit }) => {
    const [selectedDay, setSelectedDay] = useState(DIET_STRUCTURE.WEEKLY.DAYS.MONDAY);
    const days = Object.values(DIET_STRUCTURE.WEEKLY.DAYS);

    return (
        <Box>
            <Tabs 
                value={selectedDay}
                onChange={(_, newValue) => setSelectedDay(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ mb: 3 }}
            >
                {days.map((day) => (
                    <Tab 
                        key={day}
                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                        value={day}
                    />
                ))}
            </Tabs>

            {dietData.data.dias[selectedDay] && (
                <Box>
                    <DailyMealCard 
                        meal={dietData.data.dias[selectedDay].desayuno}
                        mealType={DIET_STRUCTURE.WEEKLY.MEALS.BREAKFAST}
                        onEdit={(mealType, field, value) => onEdit(selectedDay, mealType, field, value)}
                    />
                    <DailyMealCard 
                        meal={dietData.data.dias[selectedDay].almuerzo}
                        mealType={DIET_STRUCTURE.WEEKLY.MEALS.LUNCH}
                        onEdit={(mealType, field, value) => onEdit(selectedDay, mealType, field, value)}
                    />
                    <DailyMealCard 
                        meal={dietData.data.dias[selectedDay].cena}
                        mealType={DIET_STRUCTURE.WEEKLY.MEALS.DINNER}
                        onEdit={(mealType, field, value) => onEdit(selectedDay, mealType, field, value)}
                    />
                    
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Snacks del día</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(dietData.data.dias[selectedDay].snacks).map(([snackType, snack]) => (
                                snack.items[0] !== "" && (
                                    <DailyMealCard 
                                        key={snackType}
                                        meal={snack}
                                        mealType={snackType}
                                        onEdit={(mealType, field, value) => onEdit(selectedDay, mealType, field, value)}
                                    />
                                )
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </Box>
    );
};

const DailyView = ({ dietData, onEdit }) => {
    const mainMeals = ['desayuno', 'almuerzo', 'cena'];
    const snacks = ['media_manana', 'media_tarde', 'antes_de_dormir'];

    return (
        <Box>
            {/* Comidas principales */}
            {mainMeals.map((mealType) => (
                dietData.data.comidas[mealType] && (
                    <DailyMealCard 
                        key={mealType}
                        meal={dietData.data.comidas[mealType]}
                        mealType={mealType}
                        onEdit={onEdit}
                    />
                )
            ))}

            {/* Snacks en un Accordion */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Snacks del día</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {snacks.map((snackType) => (
                        dietData.data.comidas[snackType] && 
                        dietData.data.comidas[snackType].items?.[0] !== "" && (
                            <DailyMealCard 
                                key={snackType}
                                meal={dietData.data.comidas[snackType]}
                                mealType={snackType}
                                onEdit={onEdit}
                            />
                        )
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const DietView = ({ clientId }) => {
    const [dieta, setDieta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDieta = async () => {
            try {
                console.log('Buscando dieta para:', {
                    IDPT: userDetails.IDPT,
                    clientId: clientId,
                });
                
                const dietsRef = collection(db, 'diets');
                const q = query(
                    dietsRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId),
                    orderBy('createdAt', 'desc'),
                    limit(1)
                );
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const dietaData = querySnapshot.docs[0].data();
                    console.log('Dieta encontrada:', dietaData);
                    setDieta(dietaData);
                }
            } catch (error) {
                console.error('Error al cargar la dieta:', error);
                setError('Error al cargar la dieta del cliente');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.IDPT && clientId) {
            fetchDieta();
        }
    }, [clientId, userDetails?.IDPT, db]);

    const handleCreateDiet = () => {
        navigate('/generar', { state: { clientId, type: 'dieta' } });
    };

    const handleEditDiet = async (day, mealType, field, value) => {
        try {
            const newDieta = JSON.parse(JSON.stringify(dieta));
            const dietData = newDieta.diet_data;

            if (dietData.tipo === DIET_STRUCTURE.DAILY.TYPE) {
                const meal = dietData.data.comidas[mealType];
                
                if (field === 'calories') {
                    meal.calorias_aproximadas = parseInt(value) || 0;
                } else if (field.startsWith('option_name_')) {
                    const optionIndex = parseInt(field.split('_')[2]);
                    meal.opciones[optionIndex].nombre = value;
                } else if (field.startsWith('option_item_')) {
                    const [_, __, optionIndex, itemIndex] = field.split('_');
                    meal.opciones[parseInt(optionIndex)].items[parseInt(itemIndex)] = value;
                } else if (field.startsWith('item_')) {
                    const itemIndex = parseInt(field.split('_')[1]);
                    meal.items[itemIndex] = value;
                }
            } else {
                const dayData = dietData.data.dias[day];
                const meal = mealType.startsWith('media_') || mealType === 'antes_de_dormir' 
                    ? dayData.snacks[mealType]
                    : dayData[mealType];

                if (field === 'calories') {
                    meal.calorias = parseInt(value) || 0;
                } else if (field.startsWith('option_name_')) {
                    const optionIndex = parseInt(field.split('_')[2]);
                    meal.opciones[optionIndex].nombre = value;
                } else if (field.startsWith('option_item_')) {
                    const [_, __, optionIndex, itemIndex] = field.split('_');
                    meal.opciones[parseInt(optionIndex)].items[parseInt(itemIndex)] = value;
                } else if (field.startsWith('item_')) {
                    const itemIndex = parseInt(field.split('_')[1]);
                    meal.items[itemIndex] = value;
                }
            }

            // Actualizar en Firestore
            const dietsRef = collection(db, 'diets');
            const q = query(
                dietsRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const dietDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'diets', dietDoc.id), {
                    diet_data: dietData,
                    updatedAt: serverTimestamp()
                });
                setDieta(newDieta);
            }
        } catch (error) {
            console.error("Error al editar la dieta:", error);
            alert('Error al guardar los cambios: ' + error.message);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 3 }}>
                {error}
            </Alert>
        );
    }

    if (!dieta) {
        return (
            <Box sx={{ p: 3 }}>
                <Paper 
                    elevation={1} 
                    sx={{ 
                        p: 4, 
                        borderRadius: 2,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <RestaurantIcon 
                        sx={{ 
                            fontSize: 60,
                            color: 'primary.main',
                            mb: 2
                        }}
                    />
                    <Typography variant="h6" gutterBottom>
                        No hay plan de alimentación asignado
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        Este cliente aún no tiene un plan de alimentación asignado. 
                        Puedes crear uno nuevo haciendo clic en el botón de abajo.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleCreateDiet}
                        sx={{
                            background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #388E3C 30%, #4CAF50 90%)',
                            }
                        }}
                    >
                        Crear Plan de Alimentación
                    </Button>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(45deg, rgba(255,165,0,0.1) 0%, rgba(255,192,203,0.1) 100%)',
                    backgroundSize: '400% 400%',
                    animation: `${gradientAnimation} 15s ease infinite`,
                    zIndex: -1
                }}
            />
            <Paper elevation={1} sx={{ 
                p: 3, 
                borderRadius: 2,
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)'
            }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Plan de Alimentación
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Última actualización: {new Date(dieta.createdAt.toDate()).toLocaleDateString()}
                    </Typography>
                </Box>

                {dieta.diet_data && (
                    <Box sx={{ mt: 3 }}>
                        {dieta.diet_data.tipo === DIET_STRUCTURE.DAILY.TYPE ? (
                            <DailyView 
                                dietData={dieta.diet_data} 
                                onEdit={handleEditDiet} 
                            />
                        ) : (
                            <WeeklyView 
                                dietData={dieta.diet_data} 
                                onEdit={handleEditDiet} 
                            />
                        )}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default DietView; 