// src/components/clients/ClientList.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
    Box, 
    Grid, 
    Card, 
    CardActionArea, 
    Typography, 
    Avatar,
    IconButton,
    CircularProgress,
    Chip,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';
import { collection, onSnapshot } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext'; // Asegúrate de que la ruta sea correcta
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddClient from './AddClient';
import { db } from '../../config/firebaseConfig';

const theme = createTheme({
    components: {
        MuiChip: {
            variants: [
                {
                    props: { variant: 'pro' },
                    style: {
                        background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                        color: 'white',
                        fontWeight: 'bold',
                    },
                },
            ],
        },
    },
});

const ClientCard = ({ client }) => (
    <ThemeProvider theme={theme}>
        <Card sx={{ display: 'flex', alignItems: 'center', height: '100%', borderRadius: 4, maxWidth: 340 }}>
            <CardActionArea component={Link} to={`/client-v2/${client.id}`} sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Avatar 
                    src={client.photo_url} 
                    alt={`${client.nombre} ${client.apellidos}`}
                    sx={{ width: 70, height: 70, m: 1.5 }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1.5, flexGrow: 1, overflow: 'hidden' }}>
                    <Typography variant="h6" component="div" noWrap>
                        {`${client.nombre} ${client.apellidos}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {client.email}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 0.75 }}>
                        <Chip 
                            label={client.plan === 'Pro' ? 'Pro' : 'Free'}
                            variant={client.plan === 'Pro' ? 'pro' : 'default'}
                            size="small"
                        />
                        <Chip
                            icon={<CircleIcon sx={{ fontSize: 12 }} />}
                            label={client.estado || 'Pendiente'}
                            size="small"
                            sx={{
                                backgroundColor: (() => {
                                    switch(client.estado) {
                                        case 'Activo':
                                            return '#4CAF50';
                                        case 'Inactivo':
                                            return '#FFA726';
                                        case 'Perdido':
                                            return '#EF5350';
                                        default:
                                            return '#9E9E9E';
                                    }
                                })(),
                                color: 'white',
                                '& .MuiChip-icon': {
                                    color: 'white'
                                }
                            }}
                        />
                    </Stack>
                </Box>
                <IconButton aria-label="ver detalles" sx={{ mr: 1.5 }}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </CardActionArea>
        </Card>
    </ThemeProvider>
);

const ClientList = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddClient, setOpenAddClient] = useState(false);
    const [selectedState, setSelectedState] = useState('todos');

    useEffect(() => {
        let unsubscribe;
        
        const setupClientListener = async () => {
            if (!userDetails) {
                console.log("No se encontraron detalles del usuario");
                setError("No se ha encontrado un usuario autenticado o detalles del usuario");
                setLoading(false);
                return;
            }

            try {
                let clientsCollectionRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    console.log("Accediendo a clientes de entrenador independiente");
                    clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    console.log("Accediendo a clientes de centro");
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
                }

                console.log("Referencia de colección:", clientsCollectionRef.path);

                // Usar onSnapshot en lugar de getDocs
                unsubscribe = onSnapshot(clientsCollectionRef, (snapshot) => {
                    console.log("Número de documentos obtenidos:", snapshot.size);
                    
                    const clientsData = snapshot.docs.map(doc => {
                        const data = doc.data();
                        const isInSolicitudes = userDetails?.solicitudes?.some(
                            solicitud => solicitud.uid === data.uidcliente
                        ) || false;

                        return {
                            id: doc.id,
                            nombre: data.nombre || 'Nombre no disponible',
                            apellidos: data.apellidos || 'Apellido no disponible',
                            email: data.email || 'Email no disponible',
                            photo_url: isInSolicitudes ? '' : (data.photo_url || ''),
                            plan: data.plan || 'Free',
                            estado: data.estado || 'Pendiente',
                            isInSolicitudes: isInSolicitudes
                        };
                    });

                    // Solo filtramos los que no están en solicitudes
                    const acceptedClients = clientsData.filter(client => !client.isInSolicitudes);

                    console.log("Clientes procesados:", acceptedClients);
                    setClients(acceptedClients);
                    setLoading(false);
                });

            } catch (error) {
                console.error("Error al obtener los clientes:", error);
                setError("Error al cargar los clientes: " + error.message);
                setLoading(false);
            }
        };

        setupClientListener();

        // Cleanup function
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userDetails]);

    const filteredClients = clients.filter(client => {
        const matchesSearch = `${client.nombre} ${client.apellidos}`.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesState = selectedState === 'todos' || client.estado === selectedState;
        return matchesSearch && matchesState;
    });

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)', // Ajusta este valor según la altura de tu Navbar
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${clientsBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.15,
                    zIndex: -1,
                }
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
            }}>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        Clientes
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Gestiona a tus clientes desde esta página.
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenAddClient(true)}
                    sx={{
                        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                        color: 'white',
                        px: 3,
                        py: 1.5,
                        borderRadius: '25px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: '1rem',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 20px rgba(255, 87, 34, 0.4)',
                        }
                    }}
                >
                    Añadir Cliente
                </Button>
            </Box>
            
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3,
                gap: 2,
                flexWrap: 'wrap'
            }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    flexGrow: 1,
                    flexWrap: 'wrap'
                }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Buscar cliente"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            minWidth: 200,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 4,
                                backgroundColor: 'white',
                            }
                        }}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
                        }}
                    />
                    <FormControl 
                        size="small" 
                        sx={{ 
                            minWidth: 200,
                            backgroundColor: 'white',
                            borderRadius: 4,
                        }}
                    >
                        <InputLabel>Estado</InputLabel>
                        <Select
                            value={selectedState}
                            label="Estado"
                            onChange={(e) => setSelectedState(e.target.value)}
                        >
                            <MenuItem value="todos">Todos los estados</MenuItem>
                            <MenuItem value="Pendiente">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CircleIcon sx={{ fontSize: 12, color: '#9E9E9E' }} />
                                    Pendiente
                                </Box>
                            </MenuItem>
                            <MenuItem value="Activo">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CircleIcon sx={{ fontSize: 12, color: '#4CAF50' }} />
                                    Activo
                                </Box>
                            </MenuItem>
                            <MenuItem value="Inactivo">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CircleIcon sx={{ fontSize: 12, color: '#FFA726' }} />
                                    Inactivo
                                </Box>
                            </MenuItem>
                            <MenuItem value="Perdido">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CircleIcon sx={{ fontSize: 12, color: '#EF5350' }} />
                                    Perdido
                                </Box>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {filteredClients.length > 0 && (
                    <Typography variant="body2" color="text.secondary">
                        {filteredClients.length} cliente{filteredClients.length !== 1 ? 's' : ''} encontrado{filteredClients.length !== 1 ? 's' : ''}
                    </Typography>
                )}
            </Box>

            <Grid container spacing={3}>
                {filteredClients.map((client) => (
                    <Grid item xs={12} sm={6} md={4} key={client.id}>
                        <ClientCard client={client} />
                    </Grid>
                ))}
            </Grid>

            {/* Modal para añadir cliente */}
            <Dialog
                open={openAddClient}
                onClose={() => setOpenAddClient(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Añadir Nuevo Cliente
                    <IconButton
                        onClick={() => setOpenAddClient(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <AddClient />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ClientList;
