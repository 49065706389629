import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
    Box,
    Paper,
    TextField,
    IconButton,
    Typography,
    Avatar,
    CircularProgress,
    Button,
    Skeleton,
} from '@mui/material';
import { PaperPlaneTilt, Lightning, User } from "@phosphor-icons/react";
import { AuthContext } from '../../contexts/AuthContext';
import { 
    collection, 
    addDoc,
    doc,
    query, 
    where, 
    orderBy, 
    onSnapshot,
    serverTimestamp,
    getDocs,
    setDoc,
    getDoc
} from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { useSnackbar } from 'notistack';

// Verificar que la variable de entorno está cargada
console.log('Variables de entorno disponibles:', {
    ASSISTANT_ID: process.env.REACT_APP_OPENAI_ASSISTANT_ID,
    ALL_ENV: process.env
});

const ASSISTANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
const CHATBOT_URL = 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/chatbot-dashboard';

if (!ASSISTANT_ID) {
    console.error('ASSISTANT_ID no está definido en las variables de entorno. Asegúrate de que existe REACT_APP_OPENAI_ASSISTANT_ID en el archivo .env y que has reiniciado el servidor de desarrollo.');
}

const Chatbot = () => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [currentChatId, setCurrentChatId] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Efecto para el autoscroll cuando cambian los mensajes
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Verificar la configuración al montar el componente
    useEffect(() => {
        if (!ASSISTANT_ID) {
            enqueueSnackbar('Error de configuración: Assistant ID no disponible. Por favor, contacta con soporte.', {
                variant: 'error',
                persist: true
            });
        }
    }, [enqueueSnackbar]);

    // Inicializar o cargar el chat actual
    useEffect(() => {
        const initializeChat = async () => {
            if (!userDetails?.uid) return;

            try {
                // Buscar el chat activo más reciente del usuario
                const chatsRef = collection(db, 'chatbot');
                const q = query(
                    chatsRef,
                    where('userId', '==', userDetails.uid),
                    where('status', '==', 'active'),
                    orderBy('createdAt', 'desc')
                );

                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    // Usar el chat activo más reciente
                    setCurrentChatId(querySnapshot.docs[0].id);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error al inicializar el chat:", error);
                setLoading(false);
            }
        };

        initializeChat();
    }, [userDetails]);

    // Cargar mensajes del chat actual
    useEffect(() => {
        if (!currentChatId) return;

        const messagesRef = collection(db, 'chatbot', currentChatId, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'asc'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const loadedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(loadedMessages);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [currentChatId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!ASSISTANT_ID) {
            enqueueSnackbar('Error de configuración: Assistant ID no disponible. Por favor, contacta con soporte.', {
                variant: 'error'
            });
            return;
        }

        if (!message.trim() || !userDetails?.uid) {
            console.log('Condiciones no cumplidas:', {
                message: !message.trim(),
                userId: !userDetails?.uid
            });
            return;
        }

        const userMessage = message;
        setMessage('');
        setSending(true);

        try {
            console.log('Iniciando proceso de envío');
            let chatId = currentChatId;
            let threadId = null;

            // Si no existe un chat, crear uno nuevo
            if (!chatId) {
                console.log('Creando nuevo chat');
                const chatsRef = collection(db, 'chatbot');
                const newChatDoc = {
                    userId: userDetails.uid,
                    status: 'active',
                    createdAt: serverTimestamp(),
                    lastMessageAt: serverTimestamp(),
                    title: 'Nuevo Chat',
                    threadId: null
                };
                console.log('Documento a crear:', newChatDoc);
                
                const newChatRef = await addDoc(chatsRef, newChatDoc);
                console.log('Chat creado con ID:', newChatRef.id);
                chatId = newChatRef.id;
                setCurrentChatId(chatId);
            } else {
                console.log('Usando chat existente:', chatId);
                const chatDoc = await getDoc(doc(db, 'chatbot', chatId));
                threadId = chatDoc.data()?.threadId;
                console.log('Thread ID existente:', threadId);
            }

            // Referencia a la subcolección de mensajes
            console.log('Guardando mensaje del usuario');
            const messagesRef = collection(db, 'chatbot', chatId, 'messages');
            await addDoc(messagesRef, {
                content: userMessage,
                type: 'user',
                timestamp: serverTimestamp()
            });

            // Actualizar lastMessageAt
            await setDoc(doc(db, 'chatbot', chatId), {
                lastMessageAt: serverTimestamp()
            }, { merge: true });

            // Preparar el payload para la cloud function
            const payload = {
                question: userMessage,
                assistant_id: ASSISTANT_ID
            };

            if (threadId) {
                payload.thread_id = threadId;
            }
            
            console.log('Enviando payload a cloud function:', payload);

            // Llamar a la cloud function
            const response = await fetch(CHATBOT_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Error en la respuesta del chatbot');
            }

            const data = await response.json();
            console.log('Respuesta recibida:', data);

            // Si es una conversación nueva, guardar el thread_id
            if (!threadId && data.thread_id) {
                console.log('Guardando nuevo thread_id:', data.thread_id);
                await setDoc(doc(db, 'chatbot', chatId), {
                    threadId: data.thread_id
                }, { merge: true });
            }

            // Guardar la respuesta del asistente
            console.log('Guardando respuesta del asistente');
            await addDoc(messagesRef, {
                content: data.respuesta,
                type: 'assistant',
                timestamp: serverTimestamp()
            });

        } catch (error) {
            console.error("Error detallado:", error);
            enqueueSnackbar('Error al procesar el mensaje. Por favor, intenta de nuevo.', { 
                variant: 'error' 
            });
        } finally {
            setSending(false);
        }
    };

    const handleNewChat = () => {
        setCurrentChatId(null);
        setMessages([]);
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const MessageBubble = ({ message }) => (
        <Box sx={{ 
            display: 'flex', 
            gap: 1,
            alignItems: 'flex-start',
            justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
            mb: 1
        }}>
            {message.type === 'assistant' && (
                <Avatar 
                    sx={{ 
                        bgcolor: 'primary.main',
                        width: 32,
                        height: 32
                    }}
                >
                    <Lightning size={20} weight="duotone" />
                </Avatar>
            )}
            
            <Paper 
                sx={{ 
                    p: 1.5,
                    maxWidth: '85%',
                    bgcolor: message.type === 'user' ? 'primary.main' : 'white',
                    color: message.type === 'user' ? 'white' : 'text.primary',
                    borderRadius: '12px',
                    borderTopLeftRadius: message.type === 'assistant' ? '4px' : '12px',
                    borderTopRightRadius: message.type === 'user' ? '4px' : '12px',
                    transition: 'all 0.3s ease-in-out',
                    '& code': {
                        backgroundColor: message.type === 'user' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        fontFamily: 'monospace'
                    },
                    '& pre': {
                        backgroundColor: message.type === 'user' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                        padding: '6px',
                        borderRadius: '4px',
                        overflowX: 'auto'
                    },
                    '& a': {
                        color: message.type === 'user' ? 'inherit' : 'primary.main',
                        textDecoration: 'underline'
                    },
                    '& ul, & ol': {
                        marginLeft: '16px'
                    },
                    '& blockquote': {
                        borderLeft: '3px solid',
                        borderColor: message.type === 'user' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                        paddingLeft: '12px',
                        margin: '6px 0'
                    }
                }}
            >
                <ReactMarkdown 
                    remarkPlugins={[remarkGfm]}
                    components={{
                        p: ({node, ...props}) => <Typography variant="body2" {...props} />,
                        a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />
                    }}
                >
                    {message.content}
                </ReactMarkdown>
                {message.timestamp && (
                    <Typography variant="caption" sx={{ display: 'block', mt: 0.5, textAlign: message.type === 'user' ? 'right' : 'left' }}>
                        {formatTimestamp(message.timestamp)}
                    </Typography>
                )}
            </Paper>

            {message.type === 'user' && (
                <Avatar 
                    sx={{ 
                        bgcolor: 'grey.300',
                        width: 32,
                        height: 32
                    }}
                >
                    <User size={20} weight="duotone" />
                </Avatar>
            )}
        </Box>
    );

    const TypingIndicator = () => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-start', mb: 1 }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}>
                <Lightning size={20} weight="duotone" />
            </Avatar>
            <Paper sx={{ 
                p: 1.5,
                maxWidth: '85%',
                borderRadius: '12px',
                bgcolor: 'white',
                transition: 'all 0.3s ease-in-out'
            }}>
                <Typography variant="body2" color="text.secondary">
                    El asistente está escribiendo...
                </Typography>
            </Paper>
        </Box>
    );

    return (
        <Box sx={{ 
            height: 'calc(100vh - 64px)',
            display: 'flex', 
            flexDirection: 'column',
            p: { xs: 1, sm: 2 }
        }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="h5">
                    FitAI Assistant
                </Typography>
                <Button
                    variant="outlined"
                    onClick={handleNewChat}
                    startIcon={<Lightning weight="duotone" />}
                    size="small"
                    sx={{ 
                        borderRadius: '16px',
                        textTransform: 'none'
                    }}
                >
                    Nuevo Chat
                </Button>
            </Box>

            {/* Área de mensajes */}
            <Paper 
                elevation={2}
                sx={{ 
                    flex: 1, 
                    mb: 0.5,
                    p: 1.5,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    bgcolor: '#f8f9fa'
                }}
            >
                {loading ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
                        <Skeleton variant="rounded" height={40} width="60%" />
                        <Skeleton variant="rounded" height={40} width="80%" />
                        <Skeleton variant="rounded" height={40} width="70%" />
                    </Box>
                ) : (
                    <>
                        {!currentChatId || messages.length === 0 ? (
                            <MessageBubble 
                                message={{
                                    type: 'assistant',
                                    content: "¡Hola! Soy tu asistente personal de FitAI para la gestión de tu negocio. Estoy aquí para ayudarte a optimizar tu trabajo como entrenador personal. Puedo asistirte con estrategias de marketing, gestión de clientes, planificación de servicios, análisis de métricas de negocio, y cualquier otra consulta relacionada con el crecimiento de tu empresa. ¿En qué puedo ayudarte hoy?"
                                }}
                            />
                        ) : (
                            messages.map((msg) => (
                                <MessageBubble key={msg.id} message={msg} />
                            ))
                        )}
                        {sending && <TypingIndicator />}
                        <div ref={messagesEndRef} />
                    </>
                )}
            </Paper>

            {/* Disclaimer */}
            <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ 
                    textAlign: 'center', 
                    mb: 0.5,
                    fontStyle: 'italic',
                    fontSize: '0.7rem'
                }}
            >
                Nota: Las respuestas del asistente de FitAI son generadas por Inteligencia Artificial y pueden contener errores.
            </Typography>

            {/* Mensajes predefinidos */}
            <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                mb: 1, 
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setMessage("¿Qué estrategias me recomiendas para conseguir nuevos clientes?")}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.5)',
                        color: '#FF5722',
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Conseguir nuevos clientes
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setMessage("¿Cómo puedo establecer precios competitivos para mis servicios de entrenamiento personal?")}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.5)',
                        color: '#FF5722',
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Estrategia de precios
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setMessage("¿Qué estrategias me recomiendas para fidelizar a mis clientes actuales y reducir las cancelaciones?")}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.5)',
                        color: '#FF5722',
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Fidelización de clientes
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setMessage("¿Cómo puedo mejorar mi presencia en redes sociales para atraer más clientes?")}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.5)',
                        color: '#FF5722',
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Marketing en redes sociales
                </Button>
            </Box>

            {/* Área de input */}
            <Paper 
                component="form" 
                onSubmit={handleSubmit}
                sx={{ 
                    p: 1,
                    display: 'flex', 
                    gap: 1,
                    alignItems: 'center',
                    borderRadius: '12px',
                    bgcolor: 'white'
                }}
            >
                <TextField
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Escribe tu mensaje aquí..."
                    variant="standard"
                    disabled={sending}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    sx={{ 
                        '& .MuiInputBase-root': {
                            padding: '6px 12px',
                        }
                    }}
                />
                <IconButton 
                    type="submit" 
                    color="primary"
                    disabled={!message.trim() || sending}
                    sx={{ 
                        bgcolor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'primary.dark'
                        },
                        '&.Mui-disabled': {
                            bgcolor: 'grey.300',
                            color: 'grey.500'
                        },
                        width: 36,
                        height: 36
                    }}
                >
                    {sending ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <PaperPlaneTilt size={20} weight="fill" />
                    )}
                </IconButton>
            </Paper>
        </Box>
    );
};

export default Chatbot; 