import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Typography, Accordion, AccordionSummary, AccordionDetails,
    Button, Dialog, DialogTitle, DialogContent, IconButton,
    Paper, CircularProgress, Alert, ButtonGroup
} from '@mui/material';
import { getFirestore, doc, collection, getDocs, updateDoc, query, where, orderBy, limit } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CloseIcon from '@mui/icons-material/Close';
import Exercise from './Exercise';
import ExerciseBlock from './ExerciseBlock';
import ExercisePicker from './ExercisePicker';
import CreateBlockModal from './CreateBlockModal';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';

const ClientRoutine = ({ 
    rutina, 
    loadingRutina, 
    errorRutina, 
    setRutina,
    onOpenAIEdit,
    onRefreshRoutine,
    userDetails: propsUserDetails
}) => {
    const { userDetails: contextUserDetails } = useContext(AuthContext);
    const [openAddExercise, setOpenAddExercise] = useState(false);
    const [openCreateBlock, setOpenCreateBlock] = useState(false);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [exerciseImages, setExerciseImages] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const db = getFirestore();
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [openAddExerciseToBlock, setOpenAddExerciseToBlock] = useState(false);

    useEffect(() => {
        const fetchExerciseImages = async () => {
            try {
                const zonasRef = collection(db, 'leezonafotos');
                const querySnapshot = await getDocs(zonasRef);
                const images = {};

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.zona && data.imagen) {
                        images[data.zona.toLowerCase()] = data.imagen;
                    }
                });

                setExerciseImages(images);
            } catch (error) {
                console.error('Error al obtener imágenes de ejercicios:', error);
            }
        };

        fetchExerciseImages();
    }, [db]);

    useEffect(() => {
        console.log('UserDetails from props:', propsUserDetails);
        console.log('UserDetails from context:', contextUserDetails);
        console.log('Rutina:', rutina);
    }, [propsUserDetails, contextUserDetails, rutina]);

    const handleDeleteExercise = async (exercise, dayIndex) => {
        try {
            const effectiveUserDetails = propsUserDetails || contextUserDetails;

            console.log('Effective UserDetails:', effectiveUserDetails);
            console.log('Exercise to delete:', exercise);
            console.log('Day Index:', dayIndex);

            if (!rutina) {
                console.error('No hay rutina disponible');
                return;
            }

            if (!effectiveUserDetails) {
                console.error('No hay detalles de usuario disponibles');
                console.error('Props UserDetails:', propsUserDetails);
                console.error('Context UserDetails:', contextUserDetails);
                enqueueSnackbar('Error: No se pudo verificar el usuario', { variant: 'error' });
                return;
            }

            // Construir la query para encontrar la rutina actual
            const rutinaQuery = query(
                collection(db, 'Rutina_cliente'),
                where('uidcliente', '==', rutina.uidcliente),
                where('IDPT', '==', effectiveUserDetails.IDPT),
                orderBy('hora_creacion', 'desc'),
                limit(1)
            );

            // Obtener el documento actual
            const rutinaSnapshot = await getDocs(rutinaQuery);
            
            if (rutinaSnapshot.empty) {
                throw new Error('No se encontró la rutina en la base de datos');
            }

            const rutinaDoc = rutinaSnapshot.docs[0];
            const rutinaId = rutinaDoc.id;
            
            // Obtener el día correspondiente
            const diasSemana = ['rutinalunes', 'rutinamartes', 'rutinamiercoles', 'rutinajueves', 'rutinaviernes', 'rutinasabado', 'rutinadomingo'];
            const diaKey = diasSemana[dayIndex];
            
            if (!rutina[diaKey] || !Array.isArray(rutina[diaKey])) {
                console.error('El día seleccionado no existe o no es un array:', diaKey);
                return;
            }

            // Crear una copia profunda de la rutina actual
            const nuevaRutina = JSON.parse(JSON.stringify(rutina));
            
            // Si el ejercicio está dentro de un bloque
            if (exercise.parentBlockIndex !== undefined) {
                const bloque = nuevaRutina[diaKey][exercise.parentBlockIndex];
                if (bloque && Array.isArray(bloque.Ejercicios_Bloque)) {
                    const ejercicioIndex = bloque.Ejercicios_Bloque.findIndex(
                        ej => ej.Nombre === exercise.Nombre
                    );
                    if (ejercicioIndex !== -1) {
                        bloque.Ejercicios_Bloque.splice(ejercicioIndex, 1);
                    }
                }
            } else {
                // Si es un ejercicio individual
                const ejercicioIndex = nuevaRutina[diaKey].findIndex(
                    ej => ej.Nombre === exercise.Nombre && !ej.esbloque
                );
                if (ejercicioIndex !== -1) {
                    nuevaRutina[diaKey].splice(ejercicioIndex, 1);
                }
            }

            // Obtener la referencia del documento
            const docRef = doc(db, 'Rutina_cliente', rutinaId);

            // Preparar el objeto de actualización
            const updateData = {
                [diaKey]: nuevaRutina[diaKey]
            };

            // Actualizar en Firestore
            await updateDoc(docRef, updateData);
            
            // Actualizar el estado local
            setRutina(nuevaRutina);
            
            enqueueSnackbar('Ejercicio eliminado correctamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al eliminar ejercicio:', error);
            enqueueSnackbar('Error al eliminar el ejercicio', { variant: 'error' });
        }
    };

    const handleAddExercise = async (exercise, dayIndex, blockIndex = null) => {
        const effectiveUserDetails = propsUserDetails || contextUserDetails;

        try {
            if (!rutina) {
                console.error('No hay rutina disponible');
                return;
            }

            if (!effectiveUserDetails) {
                console.error('No hay detalles de usuario disponibles');
                enqueueSnackbar('Error: No se pudo verificar el usuario', { variant: 'error' });
                return;
            }

            // Construir la query para encontrar la rutina actual
            const rutinaQuery = query(
                collection(db, 'Rutina_cliente'),
                where('uidcliente', '==', rutina.uidcliente),
                where('IDPT', '==', effectiveUserDetails.IDPT),
                orderBy('hora_creacion', 'desc'),
                limit(1)
            );

            // Obtener el documento actual
            const rutinaSnapshot = await getDocs(rutinaQuery);
            
            if (rutinaSnapshot.empty) {
                throw new Error('No se encontró la rutina en la base de datos');
            }

            const rutinaDoc = rutinaSnapshot.docs[0];
            const rutinaId = rutinaDoc.id;

            // Obtener el día correspondiente
            const diasSemana = ['rutinalunes', 'rutinamartes', 'rutinamiercoles', 'rutinajueves', 'rutinaviernes', 'rutinasabado', 'rutinadomingo'];
            const diaKey = diasSemana[dayIndex];

            if (!diaKey) {
                throw new Error('Día no válido');
            }

            // Crear una copia profunda de la rutina actual
            const nuevaRutina = JSON.parse(JSON.stringify(rutina));

            // Asegurarse de que el día existe
            if (!nuevaRutina[diaKey]) {
                nuevaRutina[diaKey] = [];
            }

            // Preparar el nuevo ejercicio
            const nuevoEjercicio = {
                Nombre: exercise.nombre,
                Nombre_extenso: exercise.nombre_extenso || exercise.nombre,
                Zona: exercise.zona,
                Zonadeverdad: exercise.zona,
                Series: 4,
                repeticiones: [12, 12, 12, 12],
                pesos: [0, 0, 0, 0],
                Descanso: 60000,
                NotasEntrenador: '',
                NotasCliente: '',
                NotasPrivadas: '',
                esbloque: false,
                PR: null,
                tiempo: null,
                kg: null
            };

            // Si es para un bloque existente
            if (blockIndex !== null && nuevaRutina[diaKey][blockIndex]) {
                if (!nuevaRutina[diaKey][blockIndex].Ejercicios_Bloque) {
                    nuevaRutina[diaKey][blockIndex].Ejercicios_Bloque = [];
                }
                nuevaRutina[diaKey][blockIndex].Ejercicios_Bloque.push(nuevoEjercicio);
            } else {
                // Si es un ejercicio individual
                nuevaRutina[diaKey].push(nuevoEjercicio);
            }

            // Obtener la referencia del documento
            const docRef = doc(db, 'Rutina_cliente', rutinaId);

            // Preparar el objeto de actualización
            const updateData = {
                [diaKey]: nuevaRutina[diaKey]
            };

            // Verificar que los datos son válidos antes de actualizar
            if (!updateData[diaKey]) {
                throw new Error('Datos de actualización no válidos');
            }

            // Actualizar en Firestore
            await updateDoc(docRef, updateData);
            
            // Actualizar el estado local
            setRutina(nuevaRutina);
            
            enqueueSnackbar('Ejercicio añadido correctamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al añadir ejercicio:', error);
            console.error('Detalles del error:', {
                exercise,
                dayIndex,
                blockIndex,
                rutina: rutina ? 'exists' : 'null',
                effectiveUserDetails: effectiveUserDetails ? 'exists' : 'null'
            });
            enqueueSnackbar('Error al añadir el ejercicio', { variant: 'error' });
        }
    };

    const handleAddExerciseToBlock = async (selectedExercise, dayIndex, block) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            if (!rutina.id) {
                throw new Error('ID de rutina no encontrado');
            }

            const newExercise = {
                Nombre: selectedExercise.nombre,
                Nombre_extenso: selectedExercise.nombre_extenso || selectedExercise.nombre,
                Zona: selectedExercise.zona,
                Zonadeverdad: selectedExercise.zona,
                Series: 4,
                repeticiones: [12, 12, 12, 12],
                pesos: [0, 0, 0, 0],
                Descanso: 60000,
                NotasEntrenador: '',
                NotasCliente: '',
                NotasPrivadas: '',
                PR: null,
                tiempo: null,
                kg: null
            };

            const dayKey = `rutina${dias[dayIndex]}`;
            const updatedRutina = { ...rutina };
            const currentExercises = [...rutina[dayKey]];

            // Encontrar el bloque correcto
            const blockIndex = currentExercises.findIndex(
                item => item.esbloque && item.Nombre === block.Nombre
            );

            if (blockIndex === -1) {
                throw new Error('Bloque no encontrado');
            }

            // Verificar si el ejercicio ya existe en el bloque
            if (!currentExercises[blockIndex].Ejercicios_Bloque) {
                currentExercises[blockIndex].Ejercicios_Bloque = [];
            }

            if (currentExercises[blockIndex].Ejercicios_Bloque.some(
                ex => ex.Nombre === newExercise.Nombre
            )) {
                throw new Error('Este ejercicio ya existe en el bloque');
            }

            // Añadir el nuevo ejercicio al bloque
            currentExercises[blockIndex].Ejercicios_Bloque.push(newExercise);
            updatedRutina[dayKey] = currentExercises;

            // Actualizar Firestore
            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: currentExercises
            });

            // Actualizar estado local
            setRutina(updatedRutina);
            
            enqueueSnackbar('Ejercicio añadido al bloque correctamente', { variant: 'success' });
            setOpenAddExerciseToBlock(false);

        } catch (error) {
            console.error('Error al añadir ejercicio al bloque:', error);
            setRutina(previousState);
            enqueueSnackbar(error.message || 'Error al añadir el ejercicio al bloque', { variant: 'error' });
        } finally {
            setLoadingState(false);
        }
    };

    const handleReplaceExercise = async (oldExercise, newExercise, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            const dayKey = `rutina${dias[dayIndex]}`;
            const currentExercises = [...rutina[dayKey]];

            const exerciseToAdd = {
                Nombre: newExercise.nombre,
                Zona: newExercise.zona,
                Series: 3,
                Descanso: 60000,
                Nombre_extenso: newExercise.nombre_extenso || newExercise.nombre,
                Zonadeverdad: newExercise.zona
            };

            const indexToReplace = currentExercises.findIndex(ex => ex.Nombre === oldExercise.Nombre);

            if (indexToReplace === -1) {
                throw new Error('Ejercicio no encontrado');
            }

            const updatedExercises = [...currentExercises];
            updatedExercises[indexToReplace] = exerciseToAdd;

            const updatedRutina = { ...rutina };
            updatedRutina[dayKey] = updatedExercises;
            setRutina(updatedRutina);

            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedExercises
            });

            enqueueSnackbar('Ejercicio reemplazado correctamente', {
                variant: 'success'
            });

        } catch (error) {
            console.error('Error al reemplazar ejercicio:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al reemplazar el ejercicio', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    const handleAddBlock = async (blockData) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            // Primero verificamos si tenemos una rutina válida
            if (!rutina) {
                throw new Error('No hay una rutina disponible');
            }

            // Construir la query para encontrar la rutina actual
            const effectiveUserDetails = propsUserDetails || contextUserDetails;
            
            if (!effectiveUserDetails) {
                throw new Error('No hay detalles de usuario disponibles');
            }

            // Construir la query para encontrar la rutina actual
            const rutinaQuery = query(
                collection(db, 'Rutina_cliente'),
                where('uidcliente', '==', rutina.uidcliente),
                where('IDPT', '==', effectiveUserDetails.IDPT),
                orderBy('hora_creacion', 'desc'),
                limit(1)
            );

            // Obtener el documento actual
            const rutinaSnapshot = await getDocs(rutinaQuery);
            
            if (rutinaSnapshot.empty) {
                throw new Error('No se encontró la rutina en la base de datos');
            }

            const rutinaDoc = rutinaSnapshot.docs[0];
            const rutinaId = rutinaDoc.id;
            
            const dayKey = `rutina${dias[selectedDayIndex]}`;

            const newBlock = {
                esbloque: true,
                tipobloque: blockData.tipo,
                Nombre: blockData.nombre,
                Nombre_extenso: blockData.nombre_extenso,
                NotasEntrenador: blockData.notasEntrenador,
                NotasCliente: '',
                NotasPrivadas: blockData.notasPrivadas,
                Ejercicios_Bloque: []
            };

            const updatedRutina = { ...rutina };
            const currentExercises = Array.isArray(rutina[dayKey]) ? [...rutina[dayKey]] : [];

            // Verificar si ya existe un bloque con el mismo nombre
            if (currentExercises.some(item => 
                item.esbloque && item.Nombre.toLowerCase() === newBlock.Nombre.toLowerCase()
            )) {
                throw new Error('Ya existe un bloque con este nombre');
            }

            const updatedExercises = [...currentExercises, newBlock];
            updatedRutina[dayKey] = updatedExercises;

            // Actualizar Firestore usando el ID obtenido
            const rutinaRef = doc(db, 'Rutina_cliente', rutinaId);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedExercises
            });

            // Actualizar estado local
            setRutina({
                ...updatedRutina,
                id: rutinaId // Asegurarnos de que el ID está en el estado local
            });
            
            enqueueSnackbar('Bloque añadido correctamente', { variant: 'success' });
            setOpenCreateBlock(false);

        } catch (error) {
            console.error('Error al añadir bloque:', error);
            setRutina(previousState);
            enqueueSnackbar(error.message || 'Error al añadir el bloque', { variant: 'error' });
        } finally {
            setLoadingState(false);
        }
    };

    const handleDeleteBlock = async (block, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);
            const dayKey = `rutina${dias[dayIndex]}`;
            
            const updatedRutina = { ...rutina };
            const currentItems = [...rutina[dayKey]];
            const updatedItems = currentItems.filter(item => 
                !(item.esbloque && item.Nombre === block.Nombre)
            );

            updatedRutina[dayKey] = updatedItems;
            setRutina(updatedRutina);

            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: updatedItems
            });

            enqueueSnackbar('Bloque eliminado correctamente', {
                variant: 'success'
            });
        } catch (error) {
            console.error('Error al eliminar bloque:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al eliminar el bloque', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    const handleDeleteExerciseFromBlock = async (exercise, blockIndex, block, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);

            if (!rutina.id) {
                throw new Error('ID de rutina no encontrado');
            }

            const dayKey = `rutina${dias[dayIndex]}`;
            const updatedRutina = { ...rutina };
            const currentExercises = [...rutina[dayKey]];
            
            const blockToUpdate = currentExercises.find(
                item => item.esbloque && item.Nombre === block.Nombre
            );

            if (!blockToUpdate) {
                throw new Error('Bloque no encontrado');
            }

            blockToUpdate.Ejercicios_Bloque = blockToUpdate.Ejercicios_Bloque.filter(
                ex => ex.Nombre !== exercise.Nombre
            );

            setRutina(updatedRutina);

            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: currentExercises
            });

            enqueueSnackbar('Ejercicio eliminado del bloque correctamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al eliminar ejercicio del bloque:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al eliminar el ejercicio del bloque: ' + error.message, { variant: 'error' });
        } finally {
            setLoadingState(false);
        }
    };

    const handleReplaceExerciseInBlock = async (oldExercise, newExercise, block, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);
            const dayKey = `rutina${dias[dayIndex]}`;
            
            const updatedRutina = { ...rutina };
            const currentExercises = [...rutina[dayKey]];
            
            const blockToUpdate = currentExercises.find(
                item => item.esbloque && item.Nombre === block.Nombre
            );

            if (!blockToUpdate) {
                throw new Error('Bloque no encontrado');
            }

            const exerciseToAdd = {
                Nombre: newExercise.nombre,
                Zona: newExercise.zona,
                Series: oldExercise.Series || 3,
                Descanso: oldExercise.Descanso || 60000,
                Nombre_extenso: newExercise.nombre_extenso || newExercise.nombre,
                Zonadeverdad: newExercise.zona,
                NotasEntrenador: oldExercise.NotasEntrenador || '',
                NotasCliente: oldExercise.NotasCliente || '',
                NotasPrivadas: oldExercise.NotasPrivadas || '',
                pesos: oldExercise.pesos || [],
                repeticiones: oldExercise.repeticiones || [],
                PR: oldExercise.PR || null,
                tiempo: oldExercise.tiempo || null,
                kg: oldExercise.kg || null
            };

            const exerciseIndex = blockToUpdate.Ejercicios_Bloque.findIndex(
                ex => ex.Nombre === oldExercise.Nombre
            );

            if (exerciseIndex === -1) {
                throw new Error('Ejercicio no encontrado en el bloque');
            }

            blockToUpdate.Ejercicios_Bloque[exerciseIndex] = exerciseToAdd;

            setRutina(updatedRutina);

            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: currentExercises
            });

            enqueueSnackbar('Ejercicio reemplazado correctamente', {
                variant: 'success'
            });

        } catch (error) {
            console.error('Error al reemplazar ejercicio en el bloque:', error);
            setRutina(previousState);
            enqueueSnackbar('Error al reemplazar el ejercicio', {
                variant: 'error'
            });
        } finally {
            setLoadingState(false);
        }
    };

    const handleUpdateBlock = async (oldBlock, updatedBlockData, dayIndex) => {
        const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
        const previousState = { ...rutina };

        try {
            setLoadingState(true);
            const dayKey = `rutina${dias[dayIndex]}`;
            
            const updatedRutina = { ...rutina };
            const currentItems = [...rutina[dayKey]];
            
            const blockIndex = currentItems.findIndex(
                item => item.esbloque && item.Nombre === oldBlock.Nombre
            );

            if (blockIndex === -1) {
                throw new Error('Bloque no encontrado');
            }

            // Actualizar el bloque manteniendo los ejercicios existentes
            currentItems[blockIndex] = {
                ...currentItems[blockIndex],
                tipobloque: updatedBlockData.tipo,
                Nombre: updatedBlockData.nombre,
                Nombre_extenso: updatedBlockData.nombre_extenso,
                NotasEntrenador: updatedBlockData.notasEntrenador,
                NotasPrivadas: updatedBlockData.notasPrivadas
            };

            updatedRutina[dayKey] = currentItems;

            // Actualizar Firestore
            const rutinaRef = doc(db, 'Rutina_cliente', rutina.id);
            await updateDoc(rutinaRef, {
                [dayKey]: currentItems
            });

            // Actualizar estado local
            setRutina(updatedRutina);
            
            enqueueSnackbar('Bloque actualizado correctamente', { variant: 'success' });

        } catch (error) {
            console.error('Error al actualizar bloque:', error);
            setRutina(previousState);
            enqueueSnackbar(error.message || 'Error al actualizar el bloque', { variant: 'error' });
        } finally {
            setLoadingState(false);
        }
    };

    const renderDayContent = (exercises, dayIndex) => {
        return exercises.map((item, index) => {
            if (item.esbloque) {
                return (
                    <ExerciseBlock
                        key={`block-${index}`}
                        block={item}
                        dayIndex={dayIndex}
                        onDelete={handleDeleteBlock}
                        onAddExercise={() => {
                            setSelectedBlock(item);
                            setSelectedDayIndex(dayIndex);
                            setOpenAddExerciseToBlock(true);
                        }}
                        onDeleteExercise={(exercise, blockIndex) => 
                            handleDeleteExerciseFromBlock(exercise, blockIndex, item, dayIndex)
                        }
                        onReplaceExercise={(oldExercise, newExercise) => 
                            handleReplaceExerciseInBlock(oldExercise, newExercise, item, dayIndex)
                        }
                        exerciseImages={exerciseImages}
                    />
                );
            } else {
                return (
                    <Exercise
                        key={`exercise-${index}`}
                        exercise={item}
                        dayIndex={dayIndex}
                        exerciseIndex={index}
                        onDelete={handleDeleteExercise}
                        onReplace={handleReplaceExercise}
                        loading={loadingState}
                        exerciseImages={exerciseImages}
                    />
                );
            }
        });
    };

    if (loadingRutina) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (errorRutina) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                {errorRutina}
            </Alert>
        );
    }

    if (!rutina) {
        return (
            <Alert severity="info" sx={{ mt: 2 }}>
                No hay rutina asignada para este cliente
            </Alert>
        );
    }

    const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const rutinas = [
        rutina.rutinalunes || [],
        rutina.rutinamartes || [],
        rutina.rutinamiercoles || [],
        rutina.rutinajueves || [],
        rutina.rutinaviernes || [],
        rutina.rutinasabado || [],
        rutina.rutinadomingo || []
    ];

    return (
        <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5" sx={{
                    fontSize: '2rem',
                    color: 'text.primary',
                    mb: 4,
                    fontWeight: 'bold',
                    borderBottom: '3px solid',
                    borderColor: 'primary.main',
                    paddingBottom: '0.5rem',
                    display: 'inline-block'
                }}>
                    Rutina Semanal
                </Typography>
                <Box>
                    <Button
                        onClick={onOpenAIEdit}
                        sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            color: 'white',
                            px: 3,
                            py: 1,
                            borderRadius: '25px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            fontSize: '1rem',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 6px 20px rgba(255, 87, 34, 0.4)',
                            }
                        }}
                    >
                        <span className="button-content">✨ Editar con IA</span>
                    </Button>
                </Box>
            </Box>

            {dias.map((dia, index) => (
                <Accordion key={dia} defaultExpanded={rutinas[index].length > 0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <FitnessCenterIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">{dia}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderDayContent(rutinas[index], index)}

                        <ButtonGroup 
                            variant="outlined" 
                            sx={{ mt: 2, width: '100%' }}
                        >
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setSelectedDayIndex(index);
                                    setOpenAddExercise(true);
                                }}
                                sx={{ flex: 1 }}
                            >
                                Añadir ejercicio
                            </Button>
                            <Button
                                startIcon={<PlaylistAddIcon />}
                                onClick={() => {
                                    setSelectedDayIndex(index);
                                    setOpenCreateBlock(true);
                                }}
                                sx={{ flex: 1 }}
                            >
                                Crear bloque
                            </Button>
                        </ButtonGroup>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Dialog
                open={openAddExercise}
                onClose={() => setOpenAddExercise(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Añadir ejercicio
                    <IconButton
                        onClick={() => setOpenAddExercise(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <ExercisePicker
                        onSelect={(exercise) => handleAddExercise(exercise, selectedDayIndex, selectedBlock)}
                        dayIndex={selectedDayIndex}
                        blockIndex={selectedBlock}
                    />
                </DialogContent>
            </Dialog>

            <CreateBlockModal
                open={openCreateBlock}
                onClose={() => setOpenCreateBlock(false)}
                onSubmit={handleAddBlock}
                loading={loadingState}
            />

            <Dialog
                open={openAddExerciseToBlock}
                onClose={() => setOpenAddExerciseToBlock(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Añadir ejercicio al bloque: {selectedBlock?.Nombre}
                    <IconButton
                        onClick={() => setOpenAddExerciseToBlock(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <ExercisePicker
                        dayIndex={selectedDayIndex}
                        onSelect={(exercise) => handleAddExerciseToBlock(exercise, selectedDayIndex, selectedBlock)}
                    />
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

export default ClientRoutine;
