import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    CircularProgress,
    IconButton,
    Divider
} from '@mui/material';
import { 
    CloudUpload as CloudUploadIcon,
    Cancel as CancelIcon,
    PictureAsPdf as PictureAsPdfIcon,
    AudioFile as AudioFileIcon,
    Bolt as BoltIcon
} from '@mui/icons-material';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import Lottie from 'lottie-react';
import loadingAnimation from '../../../assets/animations/Resumen_Home_Loading.json';

const analyzeMessages = [
    "La IA está afinando sus oídos digitales... 🎧",
    "Mis circuitos procesan cada palabra... 🤖",
    "Activando módulos de empatía artificial... 💫",
    "Analizando patrones conversacionales... 🧠",
    "Mis algoritmos toman nota velozmente... ⚡",
    "Calibrando el detector de objetivos... 🎯",
    "Procesando en modo consulta inteligente... 💭",
    "Mi IA entrena para entenderte mejor... 💪"
];

const SummaryView = ({ clientId }) => {
    const [audioFile, setAudioFile] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [analyzing, setAnalyzing] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(0);
    const { userDetails } = useContext(AuthContext);
    const storage = getStorage();
    const db = getFirestore();

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                let clientDocRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
                }

                const clientDoc = await getDoc(clientDocRef);
                if (clientDoc.exists() && clientDoc.data().url_pdf_sesion) {
                    const pdfRef = ref(storage, clientDoc.data().url_pdf_sesion);
                    const downloadURL = await getDownloadURL(pdfRef);
                    setPdfUrl(downloadURL);
                }
            } catch (error) {
                console.error('Error al cargar datos del cliente:', error);
            }
        };

        fetchClientData();
    }, [clientId, userDetails, storage, db]);

    useEffect(() => {
        let messageInterval;
        if (analyzing) {
            messageInterval = setInterval(() => {
                setCurrentMessage(prev => (prev + 1) % analyzeMessages.length);
            }, 3000);
        }
        return () => clearInterval(messageInterval);
    }, [analyzing]);

    const handleAudioUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setAudioFile(file);
        setUploading(true);

        try {
            const storageRef = ref(storage, `sesiones_audio/${userDetails.uid}/${clientId}/${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(storageRef);

            setAudioUrl(downloadUrl);
        } catch (error) {
            console.error('Error al subir el audio:', error);
            alert('Error al subir el audio');
        } finally {
            setUploading(false);
        }
    };

    const handleAnalyzeSession = async () => {
        if (!audioUrl) return;

        setAnalyzing(true);
        setPdfUrl(null);

        try {
            const response = await fetch('https://us-central1-fitai-pt.cloudfunctions.net/analyzeSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    audio_url: audioUrl,
                    uid_pt: userDetails.uid,
                    uid_cliente: clientId
                })
            });

            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }

            const data = await response.json();

            // Actualizar el documento del cliente con la URL del PDF
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
            }

            await updateDoc(clientDocRef, {
                url_pdf_sesion: data.pdfUrl
            });

            const pdfRef = ref(storage, data.pdfUrl);
            const downloadURL = await getDownloadURL(pdfRef);
            setPdfUrl(downloadURL);

        } catch (error) {
            console.error('Error al analizar la sesión:', error);
            alert('Error al analizar la sesión');
        } finally {
            setAnalyzing(false);
        }
    };

    const handleCancelAudio = async () => {
        try {
            if (audioUrl) {
                const audioRef = ref(storage, audioUrl);
                await deleteObject(audioRef);
            }
            setAudioFile(null);
            setAudioUrl(null);
            setPdfUrl(null);
        } catch (error) {
            console.error('Error al cancelar el audio:', error);
        }
    };

    const handleDeletePdf = async () => {
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
            }

            // Eliminar el PDF del storage
            if (pdfUrl) {
                const pdfRef = ref(storage, pdfUrl);
                await deleteObject(pdfRef);
            }

            // Actualizar el documento del cliente
            await updateDoc(clientDocRef, {
                url_pdf_sesion: null
            });

            setPdfUrl(null);
        } catch (error) {
            console.error('Error al eliminar el PDF:', error);
            alert('Error al eliminar el PDF');
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Resumen de Sesión
                </Typography>

                <Box sx={{ mt: 3, p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}>
                    <Typography variant="h6" gutterBottom color="primary">
                        ¿Cómo generar informes de sesiones?
                    </Typography>
                    
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mt: 2 }}>
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            flex: 1,
                            p: 2,
                            bgcolor: 'background.default',
                            borderRadius: 2
                        }}>
                            <AudioFileIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                Paso 1
                            </Typography>
                            <Typography variant="body2" textAlign="center">
                                Graba el audio de tu sesión con el cliente usando cualquier grabadora de voz
                            </Typography>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            flex: 1,
                            p: 2,
                            bgcolor: 'background.default',
                            borderRadius: 2
                        }}>
                            <CloudUploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                Paso 2
                            </Typography>
                            <Typography variant="body2" textAlign="center">
                                Sube el archivo de audio en la sección de abajo
                            </Typography>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            flex: 1,
                            p: 2,
                            bgcolor: 'background.default',
                            borderRadius: 2
                        }}>
                            <PictureAsPdfIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                Paso 3
                            </Typography>
                            <Typography variant="body2" textAlign="center">
                                ¡Listo! Generaremos automáticamente un informe detallado de la sesión
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                    Subir Audio de Sesión
                </Typography>

                <Box sx={{
                    mt: 3,
                    p: 3,
                    border: '2px dashed',
                    borderColor: 'divider',
                    borderRadius: 2,
                    backgroundColor: 'background.default',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}>
                    {!audioUrl ? (
                        <>
                            <input
                                type="file"
                                accept="audio/*"
                                onChange={handleAudioUpload}
                                style={{ display: 'none' }}
                                id="audio-upload"
                                disabled={uploading}
                            />
                            <label htmlFor="audio-upload">
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                    {uploading ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                                            <Typography variant="h6" color="primary">
                                                {audioFile ? audioFile.name : 'Subir audio de sesión'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Arrastra y suelta un archivo de audio o haz clic para seleccionar
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </label>
                        </>
                    ) : (
                        <Box sx={{ position: 'relative' }}>
                            <IconButton
                                onClick={handleCancelAudio}
                                sx={{
                                    position: 'absolute',
                                    top: -16,
                                    right: -16,
                                    backgroundColor: 'background.paper',
                                    '&:hover': { backgroundColor: 'error.light' }
                                }}
                            >
                                <CancelIcon color="error" />
                            </IconButton>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2,
                                p: 2,
                                backgroundColor: 'background.paper',
                                borderRadius: 1
                            }}>
                                <AudioFileIcon color="primary" />
                                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                    Audio cargado exitosamente
                                </Typography>
                            </Box>

                            {!pdfUrl ? (
                                analyzing ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        p: 3,
                                        position: 'relative'
                                    }}>
                                        <Lottie
                                            animationData={loadingAnimation}
                                            style={{
                                                width: 150,
                                                height: 150,
                                                filter: 'drop-shadow(0 0 10px rgba(255, 87, 34, 0.3))'
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                mt: 2,
                                                color: '#FF5722',
                                                textAlign: 'center',
                                                animation: 'pulse 1.5s infinite',
                                                '@keyframes pulse': {
                                                    '0%': { opacity: 0.6 },
                                                    '50%': { opacity: 1 },
                                                    '100%': { opacity: 0.6 }
                                                }
                                            }}
                                        >
                                            {analyzeMessages[currentMessage]}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAnalyzeSession}
                                        startIcon={<BoltIcon />}
                                        sx={{ mt: 1 }}
                                    >
                                        Analizar sesión
                                    </Button>
                                )
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PictureAsPdfIcon />}
                                    href={pdfUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ mt: 1 }}
                                >
                                    Ver/Descargar PDF
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>

                {pdfUrl && (
                    <>
                        <Divider sx={{ my: 3 }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6">
                                Informe del Usuario
                            </Typography>
                            <Button
                                onClick={handleDeletePdf}
                                variant="outlined"
                                color="error"
                                startIcon={<CancelIcon />}
                                sx={{
                                    '&:hover': { 
                                        backgroundColor: 'error.light',
                                        color: 'white'
                                    }
                                }}
                            >
                                Eliminar Informe
                            </Button>
                        </Box>
                        <Box sx={{ height: '600px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
                            <iframe
                                src={`${pdfUrl}#view=FitH`}
                                width="100%"
                                height="100%"
                                style={{ border: 'none' }}
                                title="Resumen de sesión"
                            />
                        </Box>
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default SummaryView; 