import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Card,
    CardMedia,
    CardContent,
    IconButton,
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    DialogActions,
    CircularProgress,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tabs,
    Tab
} from '@mui/material';
import {
    Add as AddIcon,
    Close as CloseIcon,
    PhotoCamera as PhotoCameraIcon,
    Collections as CollectionsIcon,
    Delete as DeleteIcon,
    FitnessCenter as FitnessCenterIcon,
    Restaurant as RestaurantIcon,
    PhotoLibrary as PhotoLibraryIcon,
    Construction as ConstructionIcon,
    Rocket as RocketIcon
} from '@mui/icons-material';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, collection, addDoc, query, where, getDocs, orderBy, serverTimestamp, doc, deleteDoc } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';

const EmptySection = ({ title }) => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center',
            py: 8,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            minHeight: '400px'
        }}
    >
        {/* Fondo animado */}
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
                backgroundSize: '400% 400%',
                animation: 'gradient 15s ease infinite',
                '@keyframes gradient': {
                    '0%': {
                        backgroundPosition: '0% 50%'
                    },
                    '50%': {
                        backgroundPosition: '100% 50%'
                    },
                    '100%': {
                        backgroundPosition: '0% 50%'
                    }
                }
            }}
        />

        <RocketIcon 
            sx={{ 
                fontSize: 100,
                color: 'primary.main',
                mb: 4,
                animation: 'float 3s ease-in-out infinite',
                '@keyframes float': {
                    '0%, 100%': {
                        transform: 'translateY(0)'
                    },
                    '50%': {
                        transform: 'translateY(-20px)'
                    }
                }
            }}
        />
        
        <Typography 
            variant="h5" 
            sx={{ 
                mb: 2,
                color: 'primary.main',
                fontWeight: 'bold',
                textAlign: 'center'
            }}
        >
            ¡Última fase de desarrollo! 🚀
        </Typography>
        
        <Typography 
            variant="h6" 
            color="text.secondary"
            sx={{ 
                mb: 1,
                textAlign: 'center'
            }}
        >
            {title}
        </Typography>
        
        <Typography 
            variant="body1" 
            color="text.secondary"
            align="center"
            sx={{
                maxWidth: '600px',
                px: 3
            }}
        >
            Estamos añadiendo las últimas sugerencias de nuestro equipo de entrenadores expertos. 
            ¡Esta funcionalidad estará disponible en las próximas 24-48 horas! 💪
        </Typography>
    </Box>
);

const ProgressView = ({ clientId }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [newPhotoFile, setNewPhotoFile] = useState(null);
    const [photoDescription, setPhotoDescription] = useState('');
    const [photoCategory, setPhotoCategory] = useState('frente');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { userDetails } = useContext(AuthContext);
    const storage = getStorage();
    const db = getFirestore();

    useEffect(() => {
        fetchPhotos();
    }, [clientId, userDetails]);

    const fetchPhotos = async () => {
        try {
            const photosRef = collection(db, 'progress_photos');
            const q = query(
                photosRef,
                where('clientId', '==', clientId),
                where('profesionalId', '==', userDetails.uid),
                orderBy('createdAt', 'desc')
            );
            
            const querySnapshot = await getDocs(q);
            const photosData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            setPhotos(photosData);
        } catch (error) {
            console.error('Error al cargar las fotos:', error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las fotos de progreso',
                severity: 'error'
            });
        }
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedPhoto(null);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
        setNewPhotoFile(null);
        setPhotoDescription('');
        setPhotoCategory('frente');
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setNewPhotoFile(file);
        } else {
            setSnackbar({
                open: true,
                message: 'Por favor, selecciona un archivo de imagen válido',
                severity: 'error'
            });
        }
    };

    const handleUploadPhoto = async () => {
        if (!newPhotoFile) return;

        setUploading(true);
        try {
            // 1. Subir la imagen a Firebase Storage con la ruta corregida
            const fileName = `${Date.now()}_${newPhotoFile.name}`;
            const storageRef = ref(storage, `progress_pictures/${userDetails.uid}/${clientId}/${fileName}`);
            const uploadResult = await uploadBytes(storageRef, newPhotoFile);
            const downloadURL = await getDownloadURL(uploadResult.ref);

            // 2. Guardar la referencia en Firestore
            const photoData = {
                url: downloadURL,
                date: serverTimestamp(),
                description: photoDescription,
                categoria: photoCategory,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                createdBy: userDetails.uid,
                clientId: clientId,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null
            };

            await addDoc(collection(db, 'progress_photos'), photoData);

            setSnackbar({
                open: true,
                message: 'Foto subida exitosamente',
                severity: 'success'
            });
            
            handleCloseUploadDialog();
            fetchPhotos();
        } catch (error) {
            console.error('Error al subir la foto:', error);
            setSnackbar({
                open: true,
                message: 'Error al subir la foto',
                severity: 'error'
            });
        } finally {
            setUploading(false);
        }
    };

    const handleDeleteClick = (e, photo) => {
        e.stopPropagation(); // Evitar que se abra el diálogo de ver foto
        setSelectedPhoto(photo);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedPhoto(null);
    };

    const handleDeletePhoto = async () => {
        if (!selectedPhoto) return;

        try {
            // 1. Obtener la referencia del storage de la URL
            const photoUrl = new URL(selectedPhoto.url);
            const storagePath = decodeURIComponent(photoUrl.pathname.split('/o/')[1].split('?')[0]);
            const storageRef = ref(storage, storagePath);

            // 2. Eliminar la foto de Storage
            await deleteObject(storageRef);

            // 3. Eliminar el documento de Firestore
            const photoRef = doc(db, 'progress_photos', selectedPhoto.id);
            await deleteDoc(photoRef);

            setSnackbar({
                open: true,
                message: 'Foto eliminada exitosamente',
                severity: 'success'
            });

            handleCloseDeleteDialog();
            fetchPhotos();
        } catch (error) {
            console.error('Error al eliminar la foto:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar la foto',
                severity: 'error'
            });
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const PhotosSection = () => (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenUploadDialog(true)}
                >
                    Agregar Foto
                </Button>
            </Box>

            {photos.length > 0 ? (
                <Grid container spacing={3}>
                    {photos.map((photo) => (
                        <Grid item xs={12} sm={6} md={4} key={photo.id}>
                            <Card 
                                sx={{ 
                                    cursor: 'pointer',
                                    transition: 'transform 0.2s',
                                    '&:hover': { transform: 'scale(1.02)' },
                                    position: 'relative'
                                }}
                                onClick={() => handlePhotoClick(photo)}
                            >
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                        },
                                        color: 'white',
                                        zIndex: 1
                                    }}
                                    onClick={(e) => handleDeleteClick(e, photo)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <CardMedia
                                    component="img"
                                    height="300"
                                    image={photo.url}
                                    alt={`Progreso ${photo.categoria}`}
                                />
                                <CardContent>
                                    <Typography variant="subtitle1">
                                        {photo.date?.toDate().toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {photo.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        justifyContent: 'center',
                        py: 8,
                        backgroundColor: 'grey.50',
                        borderRadius: 1
                    }}
                >
                    <CollectionsIcon 
                        sx={{ 
                            fontSize: 100, 
                            color: 'grey.300',
                            mb: 2
                        }} 
                    />
                    <Typography 
                        variant="h6" 
                        color="text.secondary"
                        align="center"
                        sx={{ mb: 1 }}
                    >
                        No hay fotos de progreso todavía
                    </Typography>
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        align="center"
                    >
                        Comienza a documentar el progreso de tu cliente añadiendo la primera foto
                    </Typography>
                </Box>
            )}
        </Box>
    );

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                    Progreso del Cliente
                </Typography>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                    <Tabs 
                        value={currentTab} 
                        onChange={handleTabChange}
                        variant="fullWidth"
                    >
                        <Tab 
                            icon={<PhotoLibraryIcon />} 
                            label="Fotos" 
                            iconPosition="start"
                        />
                        <Tab 
                            icon={<FitnessCenterIcon />} 
                            label="Rutina" 
                            iconPosition="start"
                        />
                        <Tab 
                            icon={<RestaurantIcon />} 
                            label="Dieta" 
                            iconPosition="start"
                        />
                    </Tabs>
                </Box>

                {currentTab === 0 && <PhotosSection />}
                {currentTab === 1 && <EmptySection title="Seguimiento de Rutina" />}
                {currentTab === 2 && <EmptySection title="Seguimiento de Dieta" />}
            </Paper>

            {/* Dialog para ver foto */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogContent sx={{ position: 'relative', p: 0 }}>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.7)',
                            }
                        }}
                        onClick={handleCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedPhoto && (
                        <img
                            src={selectedPhoto.url}
                            alt={selectedPhoto.description}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Dialog para subir foto */}
            <Dialog
                open={openUploadDialog}
                onClose={handleCloseUploadDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir Nueva Foto</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<PhotoCameraIcon />}
                            fullWidth
                        >
                            Seleccionar Foto
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileSelect}
                            />
                        </Button>
                        {newPhotoFile && (
                            <Typography variant="body2" color="text.secondary">
                                Archivo seleccionado: {newPhotoFile.name}
                            </Typography>
                        )}
                        <FormControl fullWidth>
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                value={photoCategory}
                                label="Categoría"
                                onChange={(e) => setPhotoCategory(e.target.value)}
                            >
                                <MenuItem value="frente">Frente</MenuItem>
                                <MenuItem value="espalda">Espalda</MenuItem>
                                <MenuItem value="perfil">Perfil</MenuItem>
                                <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Descripción"
                            multiline
                            rows={3}
                            value={photoDescription}
                            onChange={(e) => setPhotoDescription(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUploadDialog}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleUploadPhoto}
                        variant="contained"
                        disabled={!newPhotoFile || uploading}
                        startIcon={uploading ? <CircularProgress size={20} /> : null}
                    >
                        {uploading ? 'Subiendo...' : 'Subir Foto'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog de confirmación para eliminar */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Eliminar Foto</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar esta foto? Esta acción no se puede deshacer.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeletePhoto}
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProgressView; 