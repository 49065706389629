import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
} from '@mui/material';
import {
    PeopleOutline as PeopleIcon,
    FitnessCenter as FitnessCenterIcon,
    RestaurantOutlined as RestaurantIcon,
    TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, collectionGroup, Timestamp, doc, getDoc, orderBy, limit } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const Admin = () => {
    const { userDetails } = useContext(AuthContext);
    const navigate = useNavigate();
    const [totalEntrenadores, setTotalEntrenadores] = useState('...');
    const [totalClientes, setTotalClientes] = useState('...');
    const [rutinasGeneradas, setRutinasGeneradas] = useState('...');
    const [dietasGeneradas, setDietasGeneradas] = useState('...');
    const [historial, setHistorial] = useState([]);
    const [loadingHistorial, setLoadingHistorial] = useState(true);

    // Verificar si el usuario tiene acceso de administrador
    React.useEffect(() => {
        if (!userDetails?.admin) {
            navigate('/');
        }
    }, [userDetails, navigate]);

    // Obtener el número total de entrenadores y sus clientes
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Obtener entrenadores
                const entrenadoresQuery = query(
                    collection(db, 'userDetails'),
                    where('es_entrenador', '==', true)
                );
                const entrenadoresSnapshot = await getDocs(entrenadoresQuery);
                setTotalEntrenadores(entrenadoresSnapshot.size.toString());

                // Obtener total de clientes usando collectionGroup
                const clientesQuery = collectionGroup(db, 'Clientes_asociados');
                const clientesSnapshot = await getDocs(clientesQuery);
                setTotalClientes(clientesSnapshot.size.toString());

                // Obtener estadísticas globales
                const statsRef = doc(db, 'stats', 'global');
                const statsDoc = await getDoc(statsRef);
                
                if (statsDoc.exists()) {
                    const stats = statsDoc.data();
                    setRutinasGeneradas(stats.total_rutinas?.toString() || '0');
                    setDietasGeneradas(stats.total_dietas?.toString() || '0');
                } else {
                    setRutinasGeneradas('0');
                    setDietasGeneradas('0');
                }

                // Obtener historial de generaciones
                const historialRef = collection(db, 'stats', 'global', 'historial');
                const historialQuery = query(
                    historialRef,
                    orderBy('fecha', 'desc'),
                    limit(30)
                );
                const historialSnapshot = await getDocs(historialQuery);
                const historialData = historialSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fecha: doc.data().fecha?.toDate() // Convertir timestamp a Date
                }));
                setHistorial(historialData);
                setLoadingHistorial(false);

            } catch (error) {
                console.error('Error al obtener datos:', error);
                setTotalEntrenadores('Error');
                setTotalClientes('Error');
                setRutinasGeneradas('Error');
                setDietasGeneradas('Error');
                setLoadingHistorial(false);
            }
        };

        fetchData();
    }, []);

    if (!userDetails?.admin) {
        return null;
    }

    // Datos de las métricas
    const metrics = [
        {
            title: 'Total Entrenadores',
            value: totalEntrenadores,
            icon: <PeopleIcon sx={{ fontSize: 40, color: '#FF5722' }} />,
            description: 'Entrenadores activos en la plataforma',
        },
        {
            title: 'Total Clientes',
            value: totalClientes,
            icon: <PeopleIcon sx={{ fontSize: 40, color: '#2196F3' }} />,
            description: 'Clientes registrados en total',
        },
        {
            title: 'Rutinas Generadas',
            value: rutinasGeneradas,
            icon: <FitnessCenterIcon sx={{ fontSize: 40, color: '#4CAF50' }} />,
            description: 'Total de rutinas generadas con IA',
        },
        {
            title: 'Dietas Generadas',
            value: dietasGeneradas,
            icon: <RestaurantIcon sx={{ fontSize: 40, color: '#FFC107' }} />,
            description: 'Total de dietas generadas con IA',
        },
    ];

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Panel de Administración
            </Typography>

            <Grid container spacing={3} sx={{ mb: 4 }}>
                {metrics.map((metric, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                height: '100%',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                {metric.icon}
                                <Typography variant="h4" sx={{ ml: 2, fontWeight: 'bold' }}>
                                    {metric.value}
                                </Typography>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                {metric.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {metric.description}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                    Últimas Generaciones
                </Typography>
                {loadingHistorial ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Entrenador</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Modelo</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historial.map((item) => (
                                    <TableRow key={item.id} hover>
                                        <TableCell>
                                            <Chip
                                                label={item.tipo === 'rutina' ? 'Rutina' : 'Dieta'}
                                                color={item.tipo === 'rutina' ? 'success' : 'warning'}
                                                size="small"
                                                icon={item.tipo === 'rutina' ? 
                                                    <FitnessCenterIcon fontSize="small" /> : 
                                                    <RestaurantIcon fontSize="small" />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {item.entrenador.nombre}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {item.entrenador.tipo_profesional}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{item.cliente.nombre}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={item.modelo === 'pro' ? 'Pro' : 'Standard'}
                                                variant="outlined"
                                                size="small"
                                                color={item.modelo === 'pro' ? 'primary' : 'default'}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.fecha ? new Date(item.fecha).toLocaleString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }) : 'Fecha no disponible'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    );
};

export default Admin; 