import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Alert,
    Button,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    ImageList,
    ImageListItem,
    IconButton,
    TextField,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    DialogActions,
    Tabs,
    Tab,
    Grid,
    Card,
    CardContent,
    Divider,
    keyframes,
    Tooltip
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    FitnessCenter as FitnessCenterIcon,
    Info as InfoIcon,
    VideoLibrary as VideoLibraryIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    Image as ImageIcon,
    CalendarMonth as CalendarIcon,
    Check as CheckIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const RoutineView = ({ clientId }) => {
    const [rutina, setRutina] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openResourceModal, setOpenResourceModal] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedType, setSelectedType] = useState('all');
    const [previewResource, setPreviewResource] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState('');
    const [editingDayIndex, setEditingDayIndex] = useState(null);
    const [editingExerciseIndex, setEditingExerciseIndex] = useState(null);
    const [openNewExerciseModal, setOpenNewExerciseModal] = useState(false);
    const [newExerciseDayIndex, setNewExerciseDayIndex] = useState(null);
    const [newExercise, setNewExercise] = useState({
        name: '',
        sets: '',
        reps: '',
        weight: '',
        muscleGroups: [],
        restTimeSeconds: '',
        restBetweenSets: '',
        restBetweenExercises: '',
        instructions: '',
        rir: ''
    });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteExerciseData, setDeleteExerciseData] = useState(null);
    const [openNewDayModal, setOpenNewDayModal] = useState(false);
    const [newDay, setNewDay] = useState({
        dayName: '',
        weekDays: []
    });
    const [deleteDayConfirmOpen, setDeleteDayConfirmOpen] = useState(false);
    const [deleteDayIndex, setDeleteDayIndex] = useState(null);
    const navigate = useNavigate();
    const [suggestedResource, setSuggestedResource] = useState(null);

    const diasSemana = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
    ];

    useEffect(() => {
        const fetchRutina = async () => {
            try {
                console.log('Valores de búsqueda:', {
                    IDPT: userDetails.IDPT,
                    clientId: clientId,
                });
                
                // Obtener todas las rutinas del cliente
                const routinesRef = collection(db, 'routines');
                const q = query(
                    routinesRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId)
                );
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    // Convertir todas las rutinas y ordenarlas manualmente
                    const rutinas = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Función para convertir cualquier formato de fecha a timestamp
                    const getTimestamp = (date) => {
                        if (!date) return 0;
                        if (typeof date === 'string') {
                            return new Date(date).getTime();
                        }
                        if (date.seconds) {
                            return date.seconds * 1000 + (date.nanoseconds || 0) / 1000000;
                        }
                        return 0;
                    };

                    // Ordenar las rutinas por updatedAt
                    rutinas.sort((a, b) => {
                        const timestampA = getTimestamp(a.updatedAt);
                        const timestampB = getTimestamp(b.updatedAt);
                        return timestampB - timestampA;
                    });

                    if (rutinas.length > 0) {
                        const rutinaData = rutinas[0];
                        console.log('Rutina más reciente encontrada:', rutinaData);
                        
                        // Si la rutina tiene updatedAt como string, actualizarla a timestamp
                        if (typeof rutinaData.updatedAt === 'string') {
                            const docRef = doc(db, 'routines', rutinaData.id);
                            await updateDoc(docRef, {
                                updatedAt: serverTimestamp()
                            });
                            console.log('Actualizado formato de fecha para rutina:', rutinaData.id);
                        }
                        
                        setRutina(rutinaData);
                    } else {
                        console.log('No se encontraron rutinas para este cliente');
                    }
                } else {
                    console.log('No se encontraron rutinas con los filtros aplicados');
                }
            } catch (error) {
                console.error('Error al cargar la rutina:', error);
                setError('Error al cargar la rutina del cliente');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.IDPT && clientId) {
            fetchRutina();
        }
    }, [clientId, userDetails?.IDPT, db]);

    const fetchRecursos = async () => {
        setLoadingRecursos(true);
        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar recursos:", error);
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleOpenResourceModal = (exercise, dayIndex, exerciseIndex) => {
        // Encontrar el índice real del día en el array
        const realDayIndex = rutina.days.findIndex(d => d.dayIndex === dayIndex);
        setSelectedExercise({ ...exercise, dayIndex: realDayIndex, exerciseIndex });
        setOpenResourceModal(true);
        fetchRecursos();
    };

    const handleCloseResourceModal = () => {
        setOpenResourceModal(false);
        setSelectedExercise(null);
    };

    const handleAddResource = async (resource) => {
        if (!selectedExercise || !rutina) return;

        try {
            // Crear una copia profunda de la rutina para evitar problemas de referencia
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            // Verificar que el día y el ejercicio existen
            if (!newRutina.days || !newRutina.days[selectedExercise.dayIndex]) {
                throw new Error('Día no encontrado en la rutina');
            }

            const day = newRutina.days[selectedExercise.dayIndex];
            if (!day.exercises || !day.exercises[selectedExercise.exerciseIndex]) {
                throw new Error('Ejercicio no encontrado en el día');
            }

            const exercise = day.exercises[selectedExercise.exerciseIndex];
            
            // Inicializar el array de recursos si no existe
            if (!exercise.recursos) {
                exercise.recursos = [];
            }

            // Evitar duplicados
            if (!exercise.recursos.find(r => r.id === resource.id)) {
                exercise.recursos.push({
                    id: resource.id,
                    tipo: resource.fileType,
                    url: resource.fileUrl,
                    titulo: resource.titulo
                });
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                handleCloseResourceModal();
            }
        } catch (error) {
            console.error("Error al añadir recurso:", error);
            alert(`Error al añadir el recurso al ejercicio: ${error.message}`);
        }
    };

    const handleDeleteResource = async (dayIndex, exerciseIndex, resourceId) => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            // Encontrar el índice real del día
            const realDayIndex = newRutina.days.findIndex(d => d.dayIndex === dayIndex);
            if (realDayIndex === -1) throw new Error('Día no encontrado');
            
            const exercise = newRutina.days[realDayIndex].exercises[exerciseIndex];
            if (!exercise) throw new Error('Ejercicio no encontrado');
            
            exercise.recursos = exercise.recursos.filter(r => r.id !== resourceId);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar recurso:", error);
            alert('Error al eliminar el recurso del ejercicio: ' + error.message);
        }
    };

    const handleDayClick = (event, dayIndex) => {
        setAnchorEl(event.currentTarget);
        setSelectedDayIndex(dayIndex);
    };

    const handleDayClose = () => {
        setAnchorEl(null);
        setSelectedDayIndex(null);
    };

    const handleDaySelect = async (weekDay) => {
        if (!rutina || selectedDayIndex === null) return;

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayToUpdate = newRutina.days.find(d => d.dayIndex === selectedDayIndex);

            if (!dayToUpdate) {
                throw new Error('Día no encontrado');
            }

            // Inicializar el array de días de la semana si no existe
            if (!dayToUpdate.weekDays) {
                dayToUpdate.weekDays = [];
            }

            // Toggle el día seleccionado
            const index = dayToUpdate.weekDays.indexOf(weekDay);
            if (index === -1) {
                dayToUpdate.weekDays.push(weekDay);
            } else {
                dayToUpdate.weekDays.splice(index, 1);
            }

            // Ordenar los días según el orden de la semana
            dayToUpdate.weekDays.sort((a, b) => diasSemana.indexOf(a) - diasSemana.indexOf(b));

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al actualizar días de la semana:", error);
            alert('Error al actualizar los días de la semana: ' + error.message);
        }
    };

    const handleStartEdit = (field, value, dayIndex = null, exerciseIndex = null) => {
        setEditingField(field);
        setEditingValue(value);
        setEditingDayIndex(dayIndex);
        setEditingExerciseIndex(exerciseIndex);
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setEditingValue('');
        setEditingDayIndex(null);
        setEditingExerciseIndex(null);
    };

    const handleSaveEdit = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));

            if (editingField === 'name' || editingField === 'description' || editingField === 'generalInstructions') {
                newRutina[editingField] = editingValue;
            } else if (editingField === 'dayName') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day) {
                    day.dayName = editingValue;
                }
            } else if (editingField.startsWith('exercise')) {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day && day.exercises[editingExerciseIndex]) {
                    const fieldMap = {
                        'exerciseName': 'name',
                        'exerciseSets': 'sets',
                        'exerciseReps': 'reps',
                        'exerciseWeight': 'weight',
                        'exerciseMuscleGroups': 'muscleGroups',
                        'exerciseRestTimeSeconds': 'restTimeSeconds',
                        'exerciseRestBetweenSets': 'restBetweenSets',
                        'exerciseRestBetweenExercises': 'restBetweenExercises',
                        'exerciseInstructions': 'instructions',
                        'exerciseRir': 'rir'
                    };

                    const field = fieldMap[editingField];
                    if (field) {
                        if (field === 'muscleGroups') {
                            day.exercises[editingExerciseIndex][field] = editingValue.split(',').map(g => g.trim()).filter(g => g);
                        } else if (['sets', 'reps', 'weight', 'restTimeSeconds', 'restBetweenSets', 'restBetweenExercises'].includes(field)) {
                            day.exercises[editingExerciseIndex][field] = editingValue ? parseInt(editingValue) : null;
                        } else if (field === 'rir') {
                            day.exercises[editingExerciseIndex][field] = editingValue || '';
                        } else {
                            day.exercises[editingExerciseIndex][field] = editingValue;
                        }
                    }
                }
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    ...newRutina,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }

            handleCancelEdit();
        } catch (error) {
            console.error("Error al guardar la edición:", error);
            alert('Error al guardar los cambios: ' + error.message);
        }
    };

    const handleOpenNewExerciseModal = (dayIndex) => {
        setNewExerciseDayIndex(dayIndex);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            weight: '',
            muscleGroups: [],
            restTimeSeconds: '',
            restBetweenSets: '',
            restBetweenExercises: '',
            instructions: '',
            rir: ''
        });
        setOpenNewExerciseModal(true);
    };

    const handleCloseNewExerciseModal = () => {
        setOpenNewExerciseModal(false);
        setNewExerciseDayIndex(null);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            weight: '',
            muscleGroups: [],
            restTimeSeconds: '',
            restBetweenSets: '',
            restBetweenExercises: '',
            instructions: '',
            rir: ''
        });
    };

    const findResourceSuggestion = async (exerciseName) => {
        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Normalizar nombres para la búsqueda
            const normalizeText = (text) => text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const exerciseNameNormalized = normalizeText(exerciseName);

            // Buscar coincidencias
            const matchingResource = recursosData.find(recurso => {
                const tituloNormalized = normalizeText(recurso.titulo);
                return tituloNormalized.includes(exerciseNameNormalized) || 
                       exerciseNameNormalized.includes(tituloNormalized);
            });

            setSuggestedResource(matchingResource || null);
        } catch (error) {
            console.error("Error al buscar sugerencias:", error);
            setSuggestedResource(null);
        }
    };

    const handleAddNewExercise = async () => {
        if (!newExercise.name || !newExercise.sets) {
            alert('El nombre y las series son obligatorios');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === newExerciseDayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            if (!day.exercises) {
                day.exercises = [];
            }

            // Buscar sugerencia de recurso
            await findResourceSuggestion(newExercise.name);

            // Añadir el nuevo ejercicio con la estructura correcta
            day.exercises.push({
                name: newExercise.name,
                sets: parseInt(newExercise.sets),
                reps: newExercise.reps ? parseInt(newExercise.reps) : 0,
                weight: newExercise.weight ? parseInt(newExercise.weight) : 0,
                muscleGroups: newExercise.muscleGroups,
                restTimeSeconds: newExercise.restTimeSeconds ? parseInt(newExercise.restTimeSeconds) : 0,
                restBetweenSets: newExercise.restBetweenSets ? parseInt(newExercise.restBetweenSets) : 0,
                restBetweenExercises: newExercise.restBetweenExercises ? parseInt(newExercise.restBetweenExercises) : 0,
                instructions: newExercise.instructions || '',
                rir: newExercise.rir || '',
                recursos: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                // No cerramos el modal si hay una sugerencia
                if (!suggestedResource) {
                    handleCloseNewExerciseModal();
                }
            }
        } catch (error) {
            console.error("Error al añadir ejercicio:", error);
            alert('Error al añadir el ejercicio: ' + error.message);
        }
    };

    const handleDeleteExercise = async (dayIndex, exerciseIndex) => {
        setDeleteExerciseData({ dayIndex, exerciseIndex });
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const { dayIndex, exerciseIndex } = deleteExerciseData;
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === dayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el ejercicio del array
            day.exercises.splice(exerciseIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar ejercicio:", error);
            alert('Error al eliminar el ejercicio: ' + error.message);
        } finally {
            setDeleteConfirmOpen(false);
            setDeleteExerciseData(null);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
        setDeleteExerciseData(null);
    };

    const handleOpenNewDayModal = () => {
        setNewDay({
            dayName: '',
            weekDays: []
        });
        setOpenNewDayModal(true);
    };

    const handleCloseNewDayModal = () => {
        setOpenNewDayModal(false);
        setNewDay({
            dayName: '',
            weekDays: []
        });
    };

    const handleAddNewDay = async () => {
        if (!newDay.dayName) {
            alert('El nombre del día es obligatorio');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            if (!newRutina.days) {
                newRutina.days = [];
            }

            // Encontrar el siguiente índice disponible
            const maxDayIndex = Math.max(...newRutina.days.map(d => d.dayIndex), 0);
            const newDayIndex = maxDayIndex + 1;

            // Añadir el nuevo día
            newRutina.days.push({
                dayIndex: newDayIndex,
                dayName: newDay.dayName,
                weekDays: newDay.weekDays,
                exercises: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                handleCloseNewDayModal();
            }
        } catch (error) {
            console.error("Error al añadir día:", error);
            alert('Error al añadir el día: ' + error.message);
        }
    };

    const handleDeleteDay = (dayIndex) => {
        setDeleteDayIndex(dayIndex);
        setDeleteDayConfirmOpen(true);
    };

    const handleConfirmDeleteDay = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayIndex = newRutina.days.findIndex(d => d.dayIndex === deleteDayIndex);
            
            if (dayIndex === -1) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el día
            newRutina.days.splice(dayIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar día:", error);
            alert('Error al eliminar el día: ' + error.message);
        } finally {
            setDeleteDayConfirmOpen(false);
            setDeleteDayIndex(null);
        }
    };

    const handleCancelDeleteDay = () => {
        setDeleteDayConfirmOpen(false);
        setDeleteDayIndex(null);
    };

    const filteredRecursos = recursos.filter(recurso => {
        const matchesSearch = recurso.titulo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesType = selectedType === 'all' || recurso.fileType === selectedType;
        return matchesSearch && matchesType;
    });

    const handleCreateRoutine = () => {
        navigate('/generar', { state: { clientId, type: 'rutina' } });
    };

    const handleAddSuggestedResource = async (dayIndex, exerciseIndex) => {
        if (!suggestedResource) return;

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === dayIndex);
            if (!day || !day.exercises[exerciseIndex]) return;

            const exercise = day.exercises[exerciseIndex];
            
            // Añadir el recurso sugerido
            if (!exercise.recursos) {
                exercise.recursos = [];
            }

            exercise.recursos.push({
                id: suggestedResource.id,
                tipo: suggestedResource.fileType,
                url: suggestedResource.fileUrl,
                titulo: suggestedResource.titulo
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                setSuggestedResource(null);
                handleCloseNewExerciseModal();
            }
        } catch (error) {
            console.error("Error al añadir recurso sugerido:", error);
            alert('Error al añadir el recurso sugerido: ' + error.message);
        }
    };

    const renderResourceModal = () => {
        return (
            <Dialog 
                open={openResourceModal} 
                onClose={handleCloseResourceModal}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Añadir Recursos a {selectedExercise?.name}
                        </Typography>
                        <IconButton onClick={handleCloseResourceModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ mb: 3 }}>
                        {/* Filtros y búsqueda */}
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Buscar recursos..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    variant={selectedType === 'all' ? 'contained' : 'outlined'}
                                    size="small"
                                    onClick={() => setSelectedType('all')}
                                    sx={{ mr: 1 }}
                                >
                                    Todos
                                </Button>
                                <Button
                                    variant={selectedType === 'video' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<VideoLibraryIcon />}
                                    onClick={() => setSelectedType('video')}
                                    sx={{ mr: 1 }}
                                >
                                    Videos
                                </Button>
                                <Button
                                    variant={selectedType === 'imagen' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<ImageIcon />}
                                    onClick={() => setSelectedType('imagen')}
                                    sx={{ mr: 1 }}
                                >
                                    Imágenes
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {loadingRecursos ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : filteredRecursos.length === 0 ? (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography color="text.secondary">
                                No se encontraron recursos
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
                            <ImageList cols={3} gap={8} sx={{ width: '100%' }}>
                                {filteredRecursos.map((recurso) => (
                                    <ImageListItem 
                                        key={recurso.id}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': { 
                                                '& .resource-overlay': {
                                                    opacity: 1
                                                }
                                            },
                                            position: 'relative',
                                            borderRadius: 1,
                                            overflow: 'hidden',
                                            height: '240px !important'
                                        }}
                                        onClick={() => handleAddResource(recurso)}
                                        onMouseEnter={() => setPreviewResource(recurso)}
                                        onMouseLeave={() => setPreviewResource(null)}
                                    >
                                        <Box sx={{ height: '200px', overflow: 'hidden' }}>
                                            {recurso.fileType === 'video' ? (
                                                <video
                                                    src={recurso.fileUrl}
                                                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                />
                                            ) : recurso.fileType === 'imagen' ? (
                                                <img
                                                    src={recurso.fileUrl}
                                                    alt={recurso.titulo}
                                                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        bgcolor: 'grey.100'
                                                    }}
                                                >
                                                    <VideoLibraryIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                bgcolor: 'background.paper',
                                                borderTop: '1px solid',
                                                borderColor: 'divider',
                                                p: 1,
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="subtitle2" noWrap>
                                                {recurso.titulo}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className="resource-overlay"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: '200px',
                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                opacity: 0,
                                                transition: 'opacity 0.2s'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Click para añadir
                                            </Typography>
                                        </Box>
                                    </ImageListItem>
                                ))}
                            </ImageList>

                            {/* Preview Panel - Fixed position */}
                            {previewResource && (
                                <Box 
                                    sx={{ 
                                        position: 'absolute',
                                        top: 0,
                                        right: -320,
                                        width: '300px',
                                        p: 2,
                                        bgcolor: 'grey.50',
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        boxShadow: 2,
                                        zIndex: 1
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {previewResource.titulo}
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Chip 
                                            label={previewResource.fileType}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    {previewResource.fileType === 'video' && (
                                        <video
                                            src={previewResource.fileUrl}
                                            controls
                                            style={{ width: '100%', borderRadius: 4 }}
                                        />
                                    )}
                                    {previewResource.fileType === 'imagen' && (
                                        <img
                                            src={previewResource.fileUrl}
                                            alt={previewResource.titulo}
                                            style={{ width: '100%', borderRadius: 4 }}
                                        />
                                    )}
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => handleAddResource(previewResource)}
                                        sx={{ mt: 2 }}
                                    >
                                        Añadir Recurso
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };

    const renderNewExerciseModal = () => {
        return (
            <Dialog
                open={openNewExerciseModal}
                onClose={handleCloseNewExerciseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Ejercicio
                        </Typography>
                        <IconButton onClick={handleCloseNewExerciseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del ejercicio"
                            value={newExercise.name}
                            onChange={(e) => setNewExercise({ ...newExercise, name: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Series"
                            type="number"
                            value={newExercise.sets}
                            onChange={(e) => setNewExercise({ ...newExercise, sets: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Repeticiones"
                            type="number"
                            value={newExercise.reps}
                            onChange={(e) => setNewExercise({ ...newExercise, reps: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Peso (kg)"
                            type="number"
                            value={newExercise.weight}
                            onChange={(e) => setNewExercise({ ...newExercise, weight: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Grupos musculares (separados por comas)"
                            value={newExercise.muscleGroups.join(', ')}
                            onChange={(e) => setNewExercise({ 
                                ...newExercise, 
                                muscleGroups: e.target.value.split(',').map(g => g.trim()).filter(g => g)
                            })}
                            fullWidth
                        />
                        <TextField
                            label="Descanso entre series (segundos)"
                            type="number"
                            value={newExercise.restBetweenSets}
                            onChange={(e) => setNewExercise({ ...newExercise, restBetweenSets: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Descanso entre ejercicios (segundos)"
                            type="number"
                            value={newExercise.restBetweenExercises}
                            onChange={(e) => setNewExercise({ ...newExercise, restBetweenExercises: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Tiempo de descanso general (segundos)"
                            type="number"
                            value={newExercise.restTimeSeconds}
                            onChange={(e) => setNewExercise({ ...newExercise, restTimeSeconds: e.target.value })}
                            fullWidth
                        />
                        <Tooltip title="RIR (Repeticiones en Reserva) indica cuántas repeticiones podrías hacer además de las prescritas. También puedes usar notación RPE." placement="top">
                            <TextField
                                label="RIR"
                                value={newExercise.rir}
                                onChange={(e) => setNewExercise({ ...newExercise, rir: e.target.value })}
                                fullWidth
                                size="small"
                                placeholder="ej: 2, 2-3, RPE8"
                                sx={{ mt: 1 }}
                            />
                        </Tooltip>
                        <TextField
                            label="Instrucciones"
                            multiline
                            rows={4}
                            value={newExercise.instructions}
                            onChange={(e) => setNewExercise({ ...newExercise, instructions: e.target.value })}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column', alignItems: 'stretch', p: 2, gap: 2 }}>
                    {suggestedResource && (
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 2, 
                            bgcolor: 'success.light', 
                            p: 2, 
                            borderRadius: 1 
                        }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle2" color="white">
                                    ¡Sugerencia encontrada!
                                </Typography>
                                <Typography variant="body2" color="white">
                                    {suggestedResource.titulo}
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleAddSuggestedResource(newExerciseDayIndex, rutina.days.find(d => d.dayIndex === newExerciseDayIndex)?.exercises?.length || 0)}
                            >
                                Añadir Sugerencia
                            </Button>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, width: '100%' }}>
                        <Button onClick={handleCloseNewExerciseModal}>
                            Cancelar
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={handleAddNewExercise}
                            disabled={!newExercise.name || !newExercise.sets}
                        >
                            Añadir Ejercicio
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNewDayModal = () => {
        return (
            <Dialog
                open={openNewDayModal}
                onClose={handleCloseNewDayModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Día
                        </Typography>
                        <IconButton onClick={handleCloseNewDayModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del día"
                            value={newDay.dayName}
                            onChange={(e) => setNewDay({ ...newDay, dayName: e.target.value })}
                            fullWidth
                            required
                        />
                        <Typography variant="subtitle2" gutterBottom>
                            Días de la semana
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {diasSemana.map((dia) => (
                                <Chip
                                    key={dia}
                                    label={dia}
                                    onClick={() => {
                                        const weekDays = newDay.weekDays.includes(dia)
                                            ? newDay.weekDays.filter(d => d !== dia)
                                            : [...newDay.weekDays, dia];
                                        setNewDay({ ...newDay, weekDays });
                                    }}
                                    color={newDay.weekDays.includes(dia) ? "primary" : "default"}
                                    variant={newDay.weekDays.includes(dia) ? "filled" : "outlined"}
                                />
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewDayModal}>
                        Cancelar
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleAddNewDay}
                        disabled={!newDay.dayName}
                    >
                        Añadir Día
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderExercise = (exercise, dayIndex, exerciseIndex) => {
        return (
            <Box
                sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                        {editingField === 'exerciseName' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="subtitle1" 
                                fontWeight="bold"
                                onClick={() => handleStartEdit('exerciseName', exercise.name, dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {exercise.name}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            startIcon={<AddIcon />}
                            size="small"
                            onClick={() => handleOpenResourceModal(exercise, dayIndex, exerciseIndex)}
                        >
                            Añadir Recurso
                        </Button>
                        <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteExercise(dayIndex, exerciseIndex)}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'error.light',
                                    color: 'white'
                                }
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>

                {/* Grupos Musculares */}
                <Box sx={{ mb: 2 }}>
                    {editingField === 'exerciseMuscleGroups' && 
                     editingDayIndex === dayIndex && 
                     editingExerciseIndex === exerciseIndex ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                value={editingValue}
                                onChange={(e) => setEditingValue(e.target.value)}
                                variant="standard"
                                size="small"
                                autoFocus
                                placeholder="Separar grupos musculares por comas"
                            />
                            <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                <CheckIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleCancelEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    ) : exercise.muscleGroups && exercise.muscleGroups.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {exercise.muscleGroups.map((muscle, index) => (
                                <Chip
                                    key={index}
                                    label={muscle}
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleStartEdit('exerciseMuscleGroups', exercise.muscleGroups.join(', '), dayIndex, exerciseIndex)}
                                />
                            ))}
                        </Box>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => handleStartEdit('exerciseMuscleGroups', '', dayIndex, exerciseIndex)}
                            sx={{ mb: 1 }}
                        >
                            Añadir grupos musculares
                        </Button>
                    )}
                </Box>

                {/* Series, Repeticiones y Peso */}
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            p: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                            width: '100%'
                        }}
                    >
                        {editingField === 'exerciseSets' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseSets', exercise.sets.toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.sets}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Series
                                </Typography>
                            </Box>
                        )}

                        {editingField === 'exerciseReps' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseReps', (exercise.reps || 0).toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100,
                                    borderLeft: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.reps || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Repeticiones
                                </Typography>
                            </Box>
                        )}

                        {editingField === 'exerciseWeight' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseWeight', (exercise.weight || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100,
                                    borderLeft: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.weight || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Peso (kg)
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Tiempos de descanso */}
                <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {/* Descanso entre series */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestBetweenSets' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso entre series: ${exercise.restBetweenSets || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestBetweenSets', (exercise.restBetweenSets || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>

                    {/* Descanso entre ejercicios */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestBetweenExercises' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso después: ${exercise.restBetweenExercises || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestBetweenExercises', (exercise.restBetweenExercises || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>

                    {/* Tiempo de descanso general */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestTimeSeconds' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso general: ${exercise.restTimeSeconds || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestTimeSeconds', (exercise.restTimeSeconds || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>
                </Box>

                {editingField === 'exerciseRir' && 
                 editingDayIndex === dayIndex && 
                 editingExerciseIndex === exerciseIndex ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="standard"
                            size="small"
                            autoFocus
                            placeholder="ej: 2, 2-3, RPE8"
                        />
                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                            <CheckIcon />
                        </IconButton>
                        <IconButton size="small" onClick={handleCancelEdit}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ) : (
                    <Tooltip title="RIR (Repeticiones en Reserva) indica cuántas repeticiones podrías hacer además de las prescritas. También puedes usar notación RPE." placement="top">
                        <Chip
                            label={exercise.rir ? `RIR: ${exercise.rir}` : "Añadir RIR"}
                            onClick={() => handleStartEdit('exerciseRir', exercise.rir || '', dayIndex, exerciseIndex)}
                            sx={{ 
                                cursor: 'pointer',
                                bgcolor: exercise.rir ? 'primary.main' : 'grey.100',
                                color: exercise.rir ? 'white' : 'text.primary',
                                '&:hover': {
                                    bgcolor: exercise.rir ? 'primary.dark' : 'grey.200'
                                },
                                height: '24px'
                            }}
                            size="small"
                        />
                    </Tooltip>
                )}

                {editingField === 'exerciseInstructions' && 
                 editingDayIndex === dayIndex && 
                 editingExerciseIndex === exerciseIndex ? (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : exercise.instructions ? (
                    <Box sx={{ 
                        mt: 2,
                        p: 2,
                        backgroundColor: 'grey.50',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'grey.200',
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'grey.100' }
                    }}
                    onClick={() => handleStartEdit('exerciseInstructions', exercise.instructions, dayIndex, exerciseIndex)}
                    >
                        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                            {exercise.instructions}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('exerciseInstructions', '', dayIndex, exerciseIndex)}
                        sx={{ mt: 2 }}
                        size="small"
                    >
                        Añadir instrucciones
                    </Button>
                )}
                
                {exercise.recursos && exercise.recursos.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Recursos:
                        </Typography>
                        <ImageList cols={3} gap={8} sx={{ mt: 1 }}>
                            {exercise.recursos.map((recurso) => (
                                <ImageListItem 
                                    key={recurso.id}
                                    sx={{ position: 'relative' }}
                                >
                                    {recurso.tipo === 'video' ? (
                                        <video
                                            src={recurso.url}
                                            controls
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    ) : recurso.tipo === 'imagen' ? (
                                        <img
                                            src={recurso.url}
                                            alt={recurso.titulo}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'grey.100',
                                                p: 2
                                            }}
                                        >
                                            <VideoLibraryIcon sx={{ fontSize: 40 }} />
                                            <Typography variant="caption" sx={{ mt: 1 }}>
                                                {recurso.titulo}
                                            </Typography>
                                        </Box>
                                    )}
                                    <IconButton
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.7)',
                                            },
                                            color: 'white'
                                        }}
                                        onClick={() => handleDeleteResource(dayIndex, exerciseIndex, recurso.id)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                )}
            </Box>
        );
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 3 }}>
                {error}
            </Alert>
        );
    }

    if (!rutina) {
        return (
            <Box sx={{ p: 3 }}>
                <Paper 
                    elevation={1} 
                    sx={{ 
                        p: 4, 
                        borderRadius: 2,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <FitnessCenterIcon 
                        sx={{ 
                            fontSize: 60,
                            color: 'primary.main',
                            mb: 2
                        }}
                    />
                    <Typography variant="h6" gutterBottom>
                        No hay rutina de entrenamiento asignada
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        Este cliente aún no tiene una rutina de entrenamiento asignada. 
                        Puedes crear una nueva haciendo clic en el botón de abajo.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleCreateRoutine}
                        sx={{
                            background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #388E3C 30%, #4CAF50 90%)',
                            }
                        }}
                    >
                        Crear Rutina de Entrenamiento
                    </Button>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(45deg, rgba(255,165,0,0.1) 0%, rgba(255,192,203,0.1) 100%)',
                    backgroundSize: '400% 400%',
                    animation: `${gradientAnimation} 15s ease infinite`,
                    zIndex: -1
                }}
            />
            <Paper elevation={1} sx={{ 
                p: 3, 
                borderRadius: 2,
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)'
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Box>
                        <Alert 
                            severity="info" 
                            sx={{ 
                                mb: 3,
                                '& .MuiAlert-message': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                🔄 <Typography variant="body2" sx={{ ml: 1 }}>
                                    Estamos mejorando la funcionalidad para permitir múltiples rutinas. Por ahora, solo se puede tener una rutina activa a la vez.
                                    Esta actualización estará disponible en las próximas 24-48 horas.
                                </Typography>
                            </Box>
                        </Alert>
                        {editingField === 'name' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    autoFocus
                                    size="small"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="h6" 
                                gutterBottom
                                onClick={() => handleStartEdit('name', rutina.name)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {rutina.name}
                            </Typography>
                        )}
                        <Typography variant="body2" color="text.secondary">
                            Última actualización: {rutina.updatedAt ? new Date(rutina.updatedAt.seconds * 1000).toLocaleDateString() : 'No disponible'}
                        </Typography>
                    </Box>
                </Box>

                {editingField === 'description' ? (
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : rutina.description ? (
                    <Box sx={{ 
                        p: 2, 
                        mb: 3, 
                        backgroundColor: 'info.main', 
                        borderRadius: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.9 }
                    }}
                    onClick={() => handleStartEdit('description', rutina.description)}
                    >
                        <InfoIcon sx={{ color: 'white', mt: 0.5 }} />
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {rutina.description}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('description', '')}
                        sx={{ mb: 3 }}
                    >
                        Añadir descripción
                    </Button>
                )}

                {editingField === 'generalInstructions' ? (
                    <Box sx={{ mb: 3 }}>
                        <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        autoFocus
                        placeholder="Instrucciones generales de la rutina..."
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                        <Button size="small" onClick={handleCancelEdit}>
                            Cancelar
                        </Button>
                        <Button size="small" variant="contained" onClick={handleSaveEdit}>
                            Guardar
                        </Button>
                    </Box>
                </Box>
                ) : rutina.generalInstructions ? (
                    <Box sx={{ 
                        p: 2, 
                        mb: 3, 
                        backgroundColor: 'background.paper', 
                        borderRadius: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        border: '1px solid',
                        borderColor: 'divider',
                        '&:hover': { bgcolor: 'grey.50' }
                    }}
                    onClick={() => handleStartEdit('generalInstructions', rutina.generalInstructions)}
                    >
                        <InfoIcon sx={{ color: 'text.primary', mt: 0.5 }} />
                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                            {rutina.generalInstructions}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('generalInstructions', '')}
                        sx={{ mb: 3 }}
                    >
                        Añadir instrucciones generales
                    </Button>
                )}

                {rutina.days?.map((day) => (
                    <Accordion
                        key={day.dayIndex}
                        defaultExpanded={true}
                        sx={{
                            mb: 2,
                            backgroundColor: 'white',
                            '&:before': { display: 'none' },
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            borderRadius: '8px !important',
                            overflow: 'hidden'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: 'background.default',
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }
                            }}
                        >
                            <FitnessCenterIcon color="primary" />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Día {day.dayIndex}
                                </Typography>
                                {editingField === 'dayName' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="standard"
                                            size="small"
                                            autoFocus
                                        />
                                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={handleCancelEdit}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Chip 
                                        label={day.dayName} 
                                        size="small" 
                                        color="primary" 
                                        variant="outlined"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartEdit('dayName', day.dayName, day.dayIndex);
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                )}
                                <Box sx={{ flex: 1 }} />
                                <Button
                                    size="small"
                                    startIcon={<CalendarIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDayClick(e, day.dayIndex);
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    Días de la semana
                                </Button>
                                {day.weekDays && day.weekDays.length > 0 && (
                                    <Typography variant="caption" color="text.secondary">
                                        ({day.weekDays.join(', ')})
                                    </Typography>
                                )}
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteDay(day.dayIndex);
                                    }}
                                    sx={{
                                        ml: 1,
                                        '&:hover': {
                                            bgcolor: 'error.light',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 2 }}>
                            {day.exercises?.length > 0 ? (
                                <>
                                    {day.exercises.map((exercise, exerciseIndex) => (
                                        <React.Fragment key={exerciseIndex}>
                                            {renderExercise(exercise, day.dayIndex, exerciseIndex)}
                                        </React.Fragment>
                                    ))}
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        Añadir Ejercicio
                                    </Button>
                                </>
                            ) : (
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="body1" color="text.secondary" gutterBottom>
                                        No hay ejercicios programados para este día
                                    </Typography>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        variant="outlined"
                                    >
                                        Añadir Ejercicio
                                    </Button>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenNewDayModal}
                    variant="contained"
                    fullWidth
                    sx={{ 
                        mt: 2,
                        bgcolor: 'grey.200',
                        color: 'text.primary',
                        '&:hover': {
                            bgcolor: 'grey.300'
                        }
                    }}
                >
                    Añadir Día
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleDayClose}
                >
                    {diasSemana.map((dia) => (
                        <MenuItem 
                            key={dia} 
                            onClick={() => handleDaySelect(dia)}
                            sx={{ minWidth: 200 }}
                        >
                            <Checkbox 
                                checked={rutina?.days
                                    ?.find(d => d.dayIndex === selectedDayIndex)
                                    ?.weekDays?.includes(dia) || false}
                            />
                            <ListItemText primary={dia} />
                        </MenuItem>
                    ))}
                </Menu>
            </Paper>
            {renderResourceModal()}
            {renderNewExerciseModal()}
            {renderNewDayModal()}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleCancelDelete}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este ejercicio?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDelete} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDayConfirmOpen}
                onClose={handleCancelDeleteDay}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este día?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeleteDay}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDeleteDay} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RoutineView; 