import React, { useState, useContext, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    InputAdornment,
    Paper,
    CircularProgress,
    Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FilterListIcon from '@mui/icons-material/FilterList';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import resourcesBackground from '../../assets/images/imagen-dashboard-clients.png'; // Asegúrate de tener esta imagen
import { AuthContext } from '../../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../config/firebaseConfig';
import { doc, collection, addDoc, getDocs, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { deleteObject, ref as storageRef } from 'firebase/storage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const Recursos = () => {
    const { userDetails } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(true);
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        categoria: '',
        dificultad: 0, // 0-3: principiante, intermedio, avanzado, experto
        file: null
    });
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCategoria, setSelectedCategoria] = useState('');
    const [showFilters, setShowFilters] = useState(false);

    // Categorías basadas en las zonas musculares del sistema
    const categorias = [
        "Pecho",
        "Espalda",
        "Hombros",
        "Bíceps",
        "Tríceps",
        "Abdominales",
        "Pierna",
        "Glúteos",
        "Cardio",
        "Estiramiento",
        "Otro"
    ];

    const dificultades = [
        { valor: 0, label: 'Principiante' },
        { valor: 1, label: 'Intermedio' },
        { valor: 2, label: 'Avanzado' },
        { valor: 3, label: 'Experto' }
    ];

    const getFileType = (file) => {
        if (file.type.startsWith('video/')) return 'video';
        if (file.type.startsWith('image/')) return 'imagen';
        if (file.type.startsWith('audio/')) return 'audio';
        if (file.type === 'application/pdf') return 'pdf';
        return 'otro';
    };

    useEffect(() => {
        fetchRecursos();
    }, [userDetails]);

    const fetchRecursos = async () => {
        if (!userDetails) return;

        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            } else {
                throw new Error('Tipo de profesional no válido');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar los recursos:", error);
            enqueueSnackbar('Error al cargar los recursos', { 
                variant: 'error' 
            });
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                file: file
            }));
        }
    };

    const handleSubmit = async () => {
        if (!formData.titulo || !formData.categoria || !formData.file) {
            enqueueSnackbar('Por favor, completa todos los campos obligatorios', { 
                variant: 'warning' 
            });
            return;
        }

        setIsLoading(true);
        try {
            const storage = getStorage();
            const fileName = `${Date.now()}_${formData.file.name}`;
            const storageRefPath = `recursos/${fileName}`;
            const storageRef = ref(storage, storageRefPath);
            
            await uploadBytes(storageRef, formData.file);
            const fileUrl = await getDownloadURL(storageRef);

            const fileType = getFileType(formData.file);
            const recursoData = {
                titulo: formData.titulo,
                descripcion: formData.descripcion,
                categoria: formData.categoria,
                dificultad: formData.dificultad,
                fileUrl: fileUrl,
                filePath: storageRefPath,
                fileType: fileType,
                fecha: serverTimestamp(),
                autor: userDetails?.display_name || 'Anónimo',
                vistas: 0
            };

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                // Guardar en userDetails/uid/recursos
                const recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
                await addDoc(recursosRef, recursoData);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                // Guardar en Centros/IDBO/recursos
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                const recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
                await addDoc(recursosRef, recursoData);
            } else {
                throw new Error('Tipo de profesional no válido');
            }

            enqueueSnackbar('Recurso subido exitosamente', { 
                variant: 'success' 
            });
            setOpenDialog(false);
            setFormData({
                titulo: '',
                descripcion: '',
                categoria: '',
                dificultad: 0,
                file: null
            });
            
            // Actualizar la lista de recursos
            await fetchRecursos();
            
        } catch (error) {
            console.error("Error al subir el recurso:", error);
            enqueueSnackbar(`Error al subir el recurso: ${error.message}`, { 
                variant: 'error' 
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecurso = async (recurso) => {
        if (!window.confirm('¿Estás seguro de que quieres eliminar este recurso?')) {
            return;
        }

        try {
            // 1. Eliminar el archivo de Storage usando filePath
            const storage = getStorage();
            const fileRef = ref(storage, recurso.filePath);
            await deleteObject(fileRef);

            // 2. Eliminar el documento de Firestore
            let recursoRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursoRef = doc(db, 'userDetails', userDetails.uid, 'recursos', recurso.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursoRef = doc(db, 'Centros', userDetails.IDBO, 'recursos', recurso.id);
            }

            await deleteDoc(recursoRef);
            await fetchRecursos();
            
            enqueueSnackbar('Recurso eliminado con éxito', { 
                variant: 'success' 
            });
        } catch (error) {
            console.error("Error al eliminar el recurso:", error);
            enqueueSnackbar(`Error al eliminar el recurso: ${error.message}`, { 
                variant: 'error' 
            });
        }
    };

    // Función para filtrar recursos
    const filteredRecursos = recursos.filter(recurso => {
        const matchesSearch = recurso.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            recurso.descripcion.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategoria = !selectedCategoria || recurso.categoria === selectedCategoria;
        
        return matchesSearch && matchesCategoria;
    });

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${resourcesBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            {/* Encabezado */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Biblioteca de Recursos
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Gestiona y comparte videos de ejercicios con tus clientes
                </Typography>
                
                <Alert 
                    severity="info" 
                    sx={{ 
                        mt: 2, 
                        backgroundColor: 'rgba(232, 244, 253, 0.8)', 
                        '& .MuiAlert-icon': {
                            color: '#1976d2'
                        }
                    }}
                >
                    ¡Luces, Cámara, Fitness! 🎬 Estamos produciendo nuestros propios videos de alta calidad para la comunidad. ¡Muy pronto disponibles!
                </Alert>
            </Box>

            {/* Barra de acciones */}
            <Paper 
                elevation={2} 
                sx={{ 
                    p: 3, 
                    mb: 4, 
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Buscar recursos..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            startIcon={<FilterListIcon />}
                            onClick={() => setShowFilters(!showFilters)}
                            sx={{ 
                                borderRadius: 2,
                                borderColor: showFilters ? 'primary.main' : 'inherit',
                                backgroundColor: showFilters ? 'rgba(255, 87, 34, 0.1)' : 'inherit'
                            }}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setOpenDialog(true)}
                            sx={{
                                backgroundColor: '#FF5722',
                                '&:hover': {
                                    backgroundColor: '#F4511E'
                                },
                                borderRadius: 2
                            }}
                        >
                            Nuevo Recurso
                        </Button>
                    </Grid>

                    {/* Panel de filtros */}
                    {showFilters && (
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth variant="outlined" sx={{ maxWidth: 300 }}>
                                <InputLabel>Categoría</InputLabel>
                                <Select
                                    value={selectedCategoria}
                                    onChange={(e) => setSelectedCategoria(e.target.value)}
                                    label="Categoría"
                                >
                                    <MenuItem value="">Todas las categorías</MenuItem>
                                    {categorias.map((categoria) => (
                                        <MenuItem key={categoria} value={categoria}>
                                            {categoria}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            {/* Grid de recursos (usar filteredRecursos en lugar de recursos) */}
            {loadingRecursos ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : filteredRecursos.length > 0 ? (
                <Grid container spacing={3}>
                    {filteredRecursos.map((recurso) => (
                        <Grid item xs={12} sm={6} md={4} key={recurso.id}>
                            <Card sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                    boxShadow: 6,
                                },
                                '&:hover .delete-button': {
                                    opacity: 1
                                }
                            }}>
                                {/* Botón de eliminar */}
                                <IconButton
                                    className="delete-button"
                                    onClick={() => handleDeleteRecurso(recurso)}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                        opacity: 0,
                                        transition: 'opacity 0.2s ease',
                                        zIndex: 2,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            color: 'error.main'
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>

                                {/* Contenido según el tipo de archivo */}
                                <Box sx={{ position: 'relative', paddingTop: '56.25%', bgcolor: 'grey.100' }}>
                                    {recurso.fileType === 'video' && (
                                        <video
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                            src={recurso.fileUrl}
                                            controls
                                        />
                                    )}
                                    {recurso.fileType === 'imagen' && (
                                        <img
                                            src={recurso.fileUrl}
                                            alt={recurso.titulo}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    )}
                                    {recurso.fileType === 'audio' && (
                                        <Box sx={{ 
                                            position: 'absolute', 
                                            top: 0, 
                                            left: 0, 
                                            width: '100%', 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2
                                        }}>
                                            <AudiotrackIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                            <audio controls src={recurso.fileUrl} style={{ width: '80%' }} />
                                        </Box>
                                    )}
                                    {recurso.fileType === 'pdf' && (
                                        <Box sx={{ 
                                            position: 'absolute', 
                                            top: 0, 
                                            left: 0, 
                                            width: '100%', 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1
                                        }}>
                                            <PictureAsPdfIcon sx={{ fontSize: 60, color: 'error.main' }} />
                                            <Button 
                                                variant="contained" 
                                                href={recurso.fileUrl} 
                                                target="_blank"
                                                sx={{ mt: 1 }}
                                            >
                                                Ver PDF
                                            </Button>
                                        </Box>
                                    )}
                                </Box>

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {recurso.titulo}
                                    </Typography>
                                    <Chip
                                        icon={<FitnessCenterIcon />}
                                        label={recurso.categoria}
                                        size="small"
                                        sx={{ mb: 1 }}
                                    />
                                    <Typography variant="body2" color="text.secondary">
                                        {recurso.descripcion}
                                    </Typography>
                                    <Typography variant="caption" display="block" sx={{ mt: 2 }}>
                                        Por {recurso.autor} • {recurso.fecha?.toDate?.() ? 
                                            new Date(recurso.fecha.toDate()).toLocaleDateString() : 
                                            'Fecha no disponible'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box sx={{ 
                    mt: 4, 
                    p: 3, 
                    textAlign: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 2
                }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        No se encontraron recursos
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {searchTerm || selectedCategoria ? 
                            'Prueba con otros criterios de búsqueda' : 
                            'Comienza subiendo tu primer recurso'}
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenDialog(true)}
                        sx={{
                            mt: 2,
                            backgroundColor: '#FF5722',
                            '&:hover': {
                                backgroundColor: '#F4511E'
                            }
                        }}
                    >
                        Subir Recurso
                    </Button>
                </Box>
            )}

            {/* Modal para nuevo recurso */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Subir Nuevo Recurso</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                            <TextField
                                fullWidth
                                label="Título del ejercicio"
                                variant="outlined"
                                value={formData.titulo}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    titulo: e.target.value
                                }))}
                            />
                            <Typography 
                                variant="caption" 
                                color="warning.main"
                                sx={{ 
                                    display: 'block', 
                                    mt: 1,
                                    px: 1,
                                    py: 0.5,
                                    bgcolor: 'warning.light',
                                    borderRadius: 1,
                                    color: 'black'
                                }}
                            >
                                ⚠️ El título debe ser descriptivo y específico (ej: "Press Banca con Mancuernas", 
                                "Sentadilla con Barra"). Evita títulos genéricos o poco descriptivos.
                            </Typography>
                        </Box>

                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.descripcion}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <TextField
                            fullWidth
                            label="Categoría"
                            variant="outlined"
                            select
                            value={formData.categoria}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                categoria: e.target.value
                            }))}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value="">Selecciona una categoría</option>
                            {categorias.map((cat) => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}
                        </TextField>

                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Dificultad (opcional)
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 2,
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                borderRadius: 1,
                                p: 2
                            }}>
                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                    {[...Array(4)].map((_, index) => (
                                        <StarIcon 
                                            key={index}
                                            sx={{ 
                                                cursor: 'pointer',
                                                color: formData.dificultad >= index ? '#FFD700' : 'grey.400',
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    transform: 'scale(1.2)'
                                                }
                                            }}
                                            onClick={() => setFormData(prev => ({
                                                ...prev,
                                                dificultad: index
                                            }))}
                                        />
                                    ))}
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ ml: 1 }}
                                >
                                    {dificultades[formData.dificultad].label}
                                </Typography>
                            </Box>
                        </Box>

                        <Button
                            variant="outlined"
                            component="label"
                            fullWidth
                            startIcon={<UploadFileIcon />}
                        >
                            {formData.file ? 'Archivo seleccionado' : 'Subir Archivo'}
                            <input
                                type="file"
                                hidden
                                accept="video/*,image/*,audio/*,.pdf"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button 
                        onClick={() => setOpenDialog(false)}
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        sx={{ 
                            backgroundColor: '#FF5722',
                            '&:hover': {
                                backgroundColor: '#F4511E'
                            }
                        }}
                    >
                        {isLoading ? 'Subiendo...' : 'Subir'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Recursos;