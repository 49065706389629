import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const DIAS_SEMANA = [
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
    { value: 0, label: 'Domingo' }
];

const DIAS_MES = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `Día ${i + 1}`
}));

const FormDialog = ({
    open,
    onClose,
    formTitle,
    formDescription,
    questions,
    frequency,
    selectedDay,
    onFormTitleChange,
    onFormDescriptionChange,
    onAddQuestion,
    onRemoveQuestion,
    onQuestionChange,
    onCreateForm,
    onFrequencyChange,
    onSelectedDayChange,
    isEditing,
    onOpenAIDialog
}) => {
    const showDaySelector = frequency === 'weekly' || frequency === 'monthly';
    const dayOptions = frequency === 'weekly' ? DIAS_SEMANA : DIAS_MES;

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <DialogTitle>{isEditing ? 'Editar Formulario' : 'Crear Nuevo Formulario'}</DialogTitle>
            <DialogContent sx={{ 
                flex: 1, 
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ 
                    pt: 2, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 3,
                    height: '100%'
                }}>
                    {!isEditing && (
                        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                startIcon={<AutoAwesomeIcon />}
                                onClick={onOpenAIDialog}
                                sx={{ borderRadius: 2 }}
                            >
                                Generar con IA
                            </Button>
                        </Box>
                    )}

                    <TextField
                        fullWidth
                        label="Título del Formulario"
                        value={formTitle}
                        onChange={(e) => onFormTitleChange(e.target.value)}
                        placeholder="Ej: Seguimiento semanal de entrenamiento"
                    />
                    
                    <TextField
                        fullWidth
                        label="Descripción"
                        value={formDescription}
                        onChange={(e) => onFormDescriptionChange(e.target.value)}
                        placeholder="Describe el propósito de este formulario"
                        multiline
                        rows={2}
                    />

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Frecuencia</InputLabel>
                            <Select
                                value={frequency}
                                label="Frecuencia"
                                onChange={(e) => onFrequencyChange(e.target.value)}
                            >
                                <MenuItem value="daily">Diaria</MenuItem>
                                <MenuItem value="weekly">Semanal</MenuItem>
                                <MenuItem value="monthly">Mensual</MenuItem>
                            </Select>
                            <FormHelperText>¿Con qué frecuencia se enviará este formulario?</FormHelperText>
                        </FormControl>

                        {showDaySelector && (
                            <FormControl fullWidth>
                                <InputLabel>{frequency === 'weekly' ? 'Día de la semana' : 'Día del mes'}</InputLabel>
                                <Select
                                    value={selectedDay}
                                    label={frequency === 'weekly' ? 'Día de la semana' : 'Día del mes'}
                                    onChange={(e) => onSelectedDayChange(e.target.value)}
                                >
                                    {dayOptions.map((day) => (
                                        <MenuItem key={day.value} value={day.value}>
                                            {day.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Preguntas
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            {questions.map((question, index) => (
                                <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                                    <TextField
                                        fullWidth
                                        label={`Pregunta ${index + 1}`}
                                        value={question.question}
                                        onChange={(e) => onQuestionChange(index, e.target.value)}
                                        placeholder="Escribe tu pregunta aquí"
                                        multiline
                                        rows={2}
                                    />
                                    <IconButton 
                                        color="error" 
                                        onClick={() => onRemoveQuestion(index)}
                                        disabled={questions.length === 1}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                        
                        <Button
                            startIcon={<AddIcon />}
                            onClick={onAddQuestion}
                            variant="outlined"
                            sx={{ mt: 1 }}
                        >
                            Añadir Pregunta
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
                <Button onClick={onClose}>Cancelar</Button>
                <Button 
                    variant="contained" 
                    onClick={onCreateForm}
                    disabled={
                        !formTitle || 
                        !formDescription || 
                        questions.some(q => !q.question) ||
                        (showDaySelector && !selectedDay)
                    }
                >
                    {isEditing ? 'Guardar Cambios' : 'Crear Formulario'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormDialog; 