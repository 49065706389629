import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Grid,
    Paper,
    Chip,
    CircularProgress,
    IconButton,
    Divider,
    TextField,
    Button,
    InputAdornment,
    Snackbar,
    Alert
} from '@mui/material';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import { format, differenceInYears } from 'date-fns';
import { es } from 'date-fns/locale';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WcIcon from '@mui/icons-material/Wc';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import TimerIcon from '@mui/icons-material/Timer';
import { FiZap, FiTarget, FiDroplet, FiHexagon } from 'react-icons/fi';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import RocketIcon from '@mui/icons-material/Rocket';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotesIcon from '@mui/icons-material/Notes';

const ProfileView = ({ clientId }) => {
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedClient, setEditedClient] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const fetchClientDetails = async () => {
            if (!userDetails || !clientId) {
                setError("Información de usuario o ID de cliente no disponible");
                setLoading(false);
                return;
            }

            try {
                let clientDocRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
                }

                const clientDoc = await getDoc(clientDocRef);

                if (!clientDoc.exists()) {
                    throw new Error("Cliente no encontrado");
                }

                const clientData = clientDoc.data();
                setClient({
                    ...clientData,
                    id: clientId
                });

            } catch (error) {
                console.error("Error al obtener detalles del cliente:", error);
                setError("Error al cargar los detalles del cliente");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [clientId, userDetails, db]);

    const formatFechaNacimiento = (fecha) => {
        if (!fecha) return null;
        
        try {
            // Si la fecha es un timestamp de Firestore
            if (fecha?.seconds) {
                return format(new Date(fecha.seconds * 1000), "d 'de' MMMM 'de' yyyy", { locale: es });
            }
            
            // Si la fecha es una cadena, intentamos parsearla
            const date = new Date(fecha);
            if (isNaN(date.getTime())) {
                console.error('Fecha inválida:', fecha);
                return null;
            }
            
            return format(date, "d 'de' MMMM 'de' yyyy", { locale: es });
        } catch (error) {
            console.error('Error al formatear la fecha:', error);
            return null;
        }
    };

    const calculateAge = (fechanacimiento) => {
        if (!fechanacimiento) return null;
        
        try {
            let date;
            if (fechanacimiento?.seconds) {
                date = new Date(fechanacimiento.seconds * 1000);
            } else {
                date = new Date(fechanacimiento);
            }
            
            if (isNaN(date.getTime())) return null;
            
            return differenceInYears(new Date(), date);
        } catch (error) {
            console.error('Error al calcular la edad:', error);
            return null;
        }
    };

    const handleEditClick = () => {
        setEditedClient({...client});
        setEditMode(true);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setEditedClient(null);
    };

    const handleSaveClick = async () => {
        setSaving(true);
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
            }

            const updatedData = {
                nombre: editedClient.nombre,
                apellidos: editedClient.apellidos,
                sexo: editedClient.sexo,
                peso: editedClient.peso,
                tipodieta: editedClient.tipodieta,
                objetivocalorias: editedClient.objetivocalorias,
                objetivoagua: editedClient.objetivoagua,
                objetivograsas: editedClient.objetivograsas,
                objetivohidratos: editedClient.objetivohidratos,
                objetivoprote: editedClient.objetivoprote,
                objetivotiempo: editedClient.objetivotiempo,
                objetivos: editedClient.objetivos,
                lesiones: editedClient.lesiones,
                notas: editedClient.notas
            };

            await updateDoc(clientDocRef, updatedData);
            setClient(editedClient);
            setEditMode(false);
            setSnackbar({
                open: true,
                message: 'Cambios guardados correctamente',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error al guardar los cambios:', error);
            setSnackbar({
                open: true,
                message: 'Error al guardar los cambios: ' + error.message,
                severity: 'error'
            });
        } finally {
            setSaving(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedClient(prev => ({
            ...prev,
            [name]: value
        }));
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (!client) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>No se encontró información del cliente</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    {editMode ? (
                        <>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleCancelEdit}
                                startIcon={<CloseIcon />}
                                sx={{ mr: 2 }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveClick}
                                startIcon={<SaveIcon />}
                                disabled={saving}
                            >
                                {saving ? 'Guardando...' : 'Guardar Cambios'}
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEditClick}
                            startIcon={<EditIcon />}
                        >
                            Editar Información
                        </Button>
                    )}
                </Box>

                <Grid container spacing={3}>
                    {/* Sección de cabecera con foto y nombre */}
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Avatar
                                src={client.photo_url}
                                alt={`${client.nombre} ${client.apellidos}`}
                                sx={{
                                    width: 120,
                                    height: 120,
                                    mr: 3,
                                    border: '4px solid #fff',
                                    boxShadow: '0 0 15px rgba(0,0,0,0.1)'
                                }}
                            />
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                    <Typography variant="h4" component="h1">
                                        {client.nombre} {client.apellidos}
                                    </Typography>
                                    <IconButton size="small">
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                                <Chip
                                    label={client.plan === 'Pro' ? 'Cliente Pro' : 'Cliente Free'}
                                    color={client.plan === 'Pro' ? 'primary' : 'default'}
                                    sx={{
                                        background: client.plan === 'Pro' ? 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)' : undefined,
                                        color: client.plan === 'Pro' ? 'white' : undefined,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                    </Grid>

                    {/* Sección de información personal */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                                Información Personal
                            </Typography>
                            
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="nombre"
                                    value={editMode ? editedClient.nombre : client.nombre}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Apellidos"
                                    name="apellidos"
                                    value={editMode ? editedClient.apellidos : (client.apellidos || '')}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                />

                                <TextField
                                    fullWidth
                                    label="Email"
                                    value={client.email}
                                    disabled
                                    InputProps={{
                                        startAdornment: <EmailIcon sx={{ mr: 1, color: 'primary.main' }} />
                                    }}
                                />

                                {client.fechanacimiento && (
                                    <TextField
                                        fullWidth
                                        label="Edad"
                                        value={`${calculateAge(client.fechanacimiento)} años`}
                                        disabled
                                        InputProps={{
                                            startAdornment: <CakeIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />
                                )}
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Physical Information section */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                                Información Física
                            </Typography>
                            
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Sexo"
                                    name="sexo"
                                    value={editMode ? editedClient.sexo : (client.sexo || '')}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        startAdornment: <WcIcon sx={{ mr: 1, color: 'primary.main' }} />
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Peso (kg)"
                                    name="peso"
                                    value={editMode ? editedClient.peso : (client.peso || '')}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        startAdornment: <FitnessCenterIcon sx={{ mr: 1, color: 'primary.main' }} />
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Tipo de Dieta"
                                    name="tipodieta"
                                    value={editMode ? editedClient.tipodieta : (client.tipodieta || '')}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                    InputProps={{
                                        startAdornment: <RestaurantIcon sx={{ mr: 1, color: 'primary.main' }} />
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Objetivos Diarios section */}
                    <Grid item xs={12}>
                        <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                                Objetivos Diarios
                            </Typography>
                            
                            <Grid container spacing={3}>
                                {/* Objetivos Nutricionales */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 'medium' }}>
                                        Objetivos Nutricionales
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField
                                            fullWidth
                                            label="Calorías"
                                            name="objetivocalorias"
                                            value={editMode ? editedClient.objetivocalorias : (client.objetivocalorias || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: (
                                                    <Box component={FiZap} sx={{ mr: 1, color: 'primary.main' }} />
                                                ),
                                                endAdornment: <InputAdornment position="end">kcal</InputAdornment>
                                            }}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            label="Agua"
                                            name="objetivoagua"
                                            value={editMode ? editedClient.objetivoagua : (client.objetivoagua || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: <LocalDrinkIcon sx={{ mr: 1, color: 'primary.main' }} />,
                                                endAdornment: <InputAdornment position="end">L</InputAdornment>
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Proteínas"
                                            name="objetivoprote"
                                            value={editMode ? editedClient.objetivoprote : (client.objetivoprote || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: (
                                                    <Box component={FiTarget} sx={{ mr: 1, color: 'primary.main' }} />
                                                ),
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                {/* Más Objetivos */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 'medium' }}>
                                        Macronutrientes y Actividad
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField
                                            fullWidth
                                            label="Grasas"
                                            name="objetivograsas"
                                            value={editMode ? editedClient.objetivograsas : (client.objetivograsas || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: (
                                                    <Box component={FiDroplet} sx={{ mr: 1, color: 'primary.main' }} />
                                                ),
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Hidratos"
                                            name="objetivohidratos"
                                            value={editMode ? editedClient.objetivohidratos : (client.objetivohidratos || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: (
                                                    <Box component={FiHexagon} sx={{ mr: 1, color: 'primary.main' }} />
                                                ),
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Tiempo de Actividad"
                                            name="objetivotiempo"
                                            value={editMode ? editedClient.objetivotiempo : (client.objetivotiempo || '')}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: <TimerIcon sx={{ mr: 1, color: 'primary.main' }} />,
                                                endAdornment: <InputAdornment position="end">min</InputAdornment>
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Nueva sección para Objetivos, Lesiones y Notas */}
                    <Grid item xs={12}>
                        <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                                Objetivos y Observaciones
                            </Typography>
                            
                            <Grid container spacing={3}>
                                {/* Objetivos del cliente */}
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 'medium' }}>
                                        Objetivos del Cliente
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Objetivos"
                                        name="objetivos"
                                        value={editMode ? editedClient.objetivos : (client.objetivos || '')}
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            startAdornment: (
                                                <Box component={RocketIcon} sx={{ mr: 1, mt: 1, color: 'primary.main' }} />
                                            )
                                        }}
                                    />
                                </Grid>

                                {/* Lesiones */}
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 'medium' }}>
                                        Lesiones
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Lesiones y Condiciones Médicas"
                                        name="lesiones"
                                        value={editMode ? editedClient.lesiones : (client.lesiones || '')}
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            startAdornment: (
                                                <Box component={LocalHospitalIcon} sx={{ mr: 1, mt: 1, color: 'primary.main' }} />
                                            )
                                        }}
                                    />
                                </Grid>

                                {/* Notas y Observaciones */}
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontWeight: 'medium' }}>
                                        Notas y Observaciones
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Notas Adicionales"
                                        name="notas"
                                        value={editMode ? editedClient.notas : (client.notas || '')}
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            startAdornment: (
                                                <Box component={NotesIcon} sx={{ mr: 1, mt: 1, color: 'primary.main' }} />
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProfileView; 