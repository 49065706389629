import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Chip,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Menu,
    MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getFirestore, collection, addDoc, serverTimestamp, query, orderBy, getDocs, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../../../contexts/AuthContext';
import FormDialog from './FormDialog';

const DIAS_SEMANA = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const FormTool = ({ clientId }) => {
    const { userDetails } = useAuth();
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [openAIInfoDialog, setOpenAIInfoDialog] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [questions, setQuestions] = useState([{ question: '', type: 'text' }]);
    const [frequency, setFrequency] = useState('daily');
    const [selectedDay, setSelectedDay] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [forms, setForms] = useState([]);
    const [loadingForms, setLoadingForms] = useState(true);
    const [selectedForm, setSelectedForm] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenFormDialog = () => setOpenFormDialog(true);
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setFormTitle('');
        setFormDescription('');
        setQuestions([{ question: '', type: 'text' }]);
        setFrequency('daily');
        setSelectedDay('');
        setIsEditing(false);
        setSelectedForm(null);
    };

    const handleAddQuestion = () => {
        setQuestions([...questions, { question: '', type: 'text' }]);
    };

    const handleQuestionChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].question = value;
        setQuestions(newQuestions);
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    const getFrequencyText = (freq, day) => {
        switch (freq) {
            case 'daily':
                return 'Diario';
            case 'weekly':
                return `Semanal (${DIAS_SEMANA[day]})`;
            case 'monthly':
                return `Mensual (Día ${day})`;
            default:
                return freq;
        }
    };

    const handleCreateForm = async () => {
        try {
            if (isEditing) {
                await handleUpdateForm();
                return;
            }

            const db = getFirestore();
            const formData = {
                title: formTitle,
                description: formDescription,
                questions: questions,
                frequency,
                selectedDay: frequency !== 'daily' ? selectedDay : null,
                createdAt: serverTimestamp(),
                createdBy: userDetails.uid,
                clientId: clientId,
                status: 'active',
                lastUpdated: serverTimestamp()
            };

            // Guardar en la ruta específica del profesional
            let formRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                formRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'formularios');
            } else {
                formRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'formularios');
            }
            const docRef = await addDoc(formRef, formData);

            // Guardar en la colección principal forms
            const formsRef = collection(db, 'forms');
            await addDoc(formsRef, {
                ...formData,
                formId: docRef.id,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                responses: [],
                nextScheduledDate: null
            });

            setSnackbar({
                open: true,
                message: 'Formulario creado exitosamente',
                severity: 'success'
            });
            handleCloseFormDialog();
        } catch (error) {
            console.error('Error al crear el formulario:', error);
            setSnackbar({
                open: true,
                message: 'Error al crear el formulario',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleOpenAIDialog = () => {
        setOpenAIInfoDialog(true);
    };

    const handleCloseAIDialog = () => {
        setOpenAIInfoDialog(false);
    };

    useEffect(() => {
        fetchForms();
    }, [clientId, userDetails]);

    const fetchForms = async () => {
        try {
            const db = getFirestore();
            let formsRef;

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                formsRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'formularios');
            } else {
                formsRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'formularios');
            }

            const q = query(
                formsRef,
                where('status', '==', 'active'),
                orderBy('createdAt', 'desc')
            );

            const querySnapshot = await getDocs(q);
            const formsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setForms(formsData);
        } catch (error) {
            console.error('Error al cargar los formularios:', error);
            setSnackbar({
                open: true,
                message: 'Error al cargar los formularios',
                severity: 'error'
            });
        } finally {
            setLoadingForms(false);
        }
    };

    const handleMenuOpen = (event, form) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedForm(form);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedForm(null);
    };

    const handleEditClick = () => {
        setFormTitle(selectedForm.title);
        setFormDescription(selectedForm.description);
        setQuestions(selectedForm.questions);
        setFrequency(selectedForm.frequency);
        setSelectedDay(selectedForm.selectedDay || '');
        setIsEditing(true);
        setOpenFormDialog(true);
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
        handleMenuClose();
    };

    const handleUpdateForm = async () => {
        try {
            const db = getFirestore();
            const formData = {
                title: formTitle,
                description: formDescription,
                questions: questions,
                frequency,
                selectedDay: frequency !== 'daily' ? selectedDay : null,
                lastUpdated: serverTimestamp()
            };

            // Actualizar en la ruta específica del profesional
            let formRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                formRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'formularios', selectedForm.id);
            } else {
                formRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'formularios', selectedForm.id);
            }
            await updateDoc(formRef, formData);

            // Crear nuevo documento en forms con los datos actualizados
            const formsRef = collection(db, 'forms');
            await addDoc(formsRef, {
                ...formData,
                formId: selectedForm.id,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                clientId: clientId,
                status: 'active',
                createdAt: serverTimestamp(),
                responses: [],
                nextScheduledDate: null
            });

            setSnackbar({
                open: true,
                message: 'Formulario actualizado exitosamente',
                severity: 'success'
            });
            handleCloseFormDialog();
            fetchForms();
        } catch (error) {
            console.error('Error al actualizar el formulario:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar el formulario',
                severity: 'error'
            });
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const db = getFirestore();
            
            // Eliminar de la ruta específica del profesional
            let formRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                formRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'formularios', selectedForm.id);
            } else {
                formRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'formularios', selectedForm.id);
            }
            await updateDoc(formRef, { status: 'deleted', deletedAt: serverTimestamp() });

            // Registrar la eliminación en forms
            const formsRef = collection(db, 'forms');
            await addDoc(formsRef, {
                formId: selectedForm.id,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                clientId: clientId,
                status: 'deleted',
                deletedAt: serverTimestamp(),
                createdAt: serverTimestamp()
            });

            setSnackbar({
                open: true,
                message: 'Formulario eliminado exitosamente',
                severity: 'success'
            });
            fetchForms();
        } catch (error) {
            console.error('Error al eliminar el formulario:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar el formulario',
                severity: 'error'
            });
        } finally {
            setOpenDeleteDialog(false);
            setSelectedForm(null);
        }
    };

    return (
        <Card sx={{ mb: 3 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AssignmentIcon sx={{ mr: 1 }} />
                    <Typography variant="h6">
                        Formularios
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" paragraph>
                    Crea y gestiona formularios personalizados para obtener feedback y seguimiento de tus clientes.
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleOpenFormDialog}
                    sx={{ mb: 3 }}
                >
                    Crear Nuevo Formulario
                </Button>

                <Divider sx={{ my: 3 }} />

                {/* Lista de Formularios Activos */}
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Formularios Activos
                    </Typography>
                    
                    {loadingForms ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : forms.length > 0 ? (
                        <List>
                            {forms.map((form) => (
                                <ListItem
                                    key={form.id}
                                    sx={{
                                        bgcolor: 'background.paper',
                                        mb: 1,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                    <ListItemText
                                        primary={form.title}
                                        secondary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {form.description}
                                                </Typography>
                                                <Chip
                                                    label={getFrequencyText(form.frequency, form.selectedDay)}
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                                <Typography variant="caption" color="text.secondary">
                                                    {form.questions.length} preguntas
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="more"
                                            onClick={(e) => handleMenuOpen(e, form)}
                                        >
                                            <MoreVertIcon fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', my: 3 }}>
                            No hay formularios activos. ¡Crea uno nuevo!
                        </Typography>
                    )}
                </Box>
            </CardContent>

            <FormDialog
                open={openFormDialog}
                onClose={handleCloseFormDialog}
                formTitle={formTitle}
                formDescription={formDescription}
                questions={questions}
                frequency={frequency}
                selectedDay={selectedDay}
                onFormTitleChange={setFormTitle}
                onFormDescriptionChange={setFormDescription}
                onAddQuestion={handleAddQuestion}
                onRemoveQuestion={handleRemoveQuestion}
                onQuestionChange={handleQuestionChange}
                onCreateForm={handleCreateForm}
                onFrequencyChange={setFrequency}
                onSelectedDayChange={setSelectedDay}
                isEditing={isEditing}
                onOpenAIDialog={handleOpenAIDialog}
            />

            {/* Modal informativo de IA */}
            <Dialog
                open={openAIInfoDialog}
                onClose={handleCloseAIDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1
                }}>
                    <RocketLaunchIcon color="primary" />
                    Próximamente
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ 
                        textAlign: 'center',
                        py: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <Typography variant="h6" gutterBottom>
                            ¡Estamos trabajando en ello!
                        </Typography>
                        <Typography color="text.secondary">
                            La funcionalidad de generación de formularios con IA estará disponible muy pronto.
                            Estamos afinando los últimos detalles para brindarte la mejor experiencia.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAIDialog} variant="contained">
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Menu de opciones */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>
                    <EditIcon sx={{ mr: 1 }} fontSize="small" />
                    Editar formulario
                </MenuItem>
                <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
                    <DeleteIcon sx={{ mr: 1 }} fontSize="small" />
                    Eliminar formulario
                </MenuItem>
            </Menu>

            {/* Diálogo de confirmación de eliminación */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que deseas eliminar este formulario? Esta acción no se puede deshacer.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleDeleteConfirm} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Card>
    );
};

export default FormTool; 