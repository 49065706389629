import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Chip,
    CircularProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFirestore, collection, addDoc, serverTimestamp, query, orderBy, limit, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../../contexts/AuthContext';
import DailyGoalsDialog from './DailyGoalsDialog';

const DailyGoalsTool = ({ clientId }) => {
    const { userDetails } = useAuth();
    const [openGoalsDialog, setOpenGoalsDialog] = useState(false);
    const [goals, setGoals] = useState([{ description: '', completed: false }]);
    const [currentGoals, setCurrentGoals] = useState(null);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editingGoals, setEditingGoals] = useState(false);
    const [isAddingToExisting, setIsAddingToExisting] = useState(false);

    useEffect(() => {
        fetchCurrentGoals();
    }, [clientId, userDetails]);

    const fetchCurrentGoals = async () => {
        try {
            const db = getFirestore();
            let goalsRef;

            // Obtener referencia según el tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                goalsRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios');
            } else {
                goalsRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios');
            }

            // Obtener el documento más reciente
            const q = query(goalsRef, orderBy('lastUpdated', 'desc'), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                setCurrentGoals({
                    id: doc.id,
                    ...doc.data()
                });
            } else {
                setCurrentGoals(null);
            }
        } catch (error) {
            console.error('Error al obtener los objetivos actuales:', error);
            setSnackbar({
                open: true,
                message: 'Error al cargar los objetivos actuales',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleOpenGoalsDialog = () => setOpenGoalsDialog(true);
    const handleCloseGoalsDialog = () => {
        setOpenGoalsDialog(false);
        setGoals([{ description: '', completed: false }]);
        setEditingGoals(false);
        setIsAddingToExisting(false);
    };

    const handleAddGoal = () => {
        setGoals([...goals, { description: '', completed: false }]);
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...goals];
        newGoals[index].description = value;
        setGoals(newGoals);
    };

    const handleRemoveGoal = (index) => {
        const newGoals = goals.filter((_, i) => i !== index);
        setGoals(newGoals);
    };

    const handleCreateGoals = async () => {
        try {
            const db = getFirestore();
            
            if (isAddingToExisting && currentGoals) {
                // Si estamos añadiendo a objetivos existentes
                const updatedGoals = [...currentGoals.goals, ...goals];
                const goalData = {
                    goals: updatedGoals,
                    lastUpdated: serverTimestamp(),
                    status: 'pending'
                };

                // Actualizar en la colección específica del profesional
                let goalsRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    goalsRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios', currentGoals.id);
                } else {
                    goalsRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios', currentGoals.id);
                }
                await updateDoc(goalsRef, goalData);

                // Crear nuevo documento en daily_goals con los objetivos actualizados
                const dailyGoalsRef = collection(db, 'daily_goals');
                await addDoc(dailyGoalsRef, {
                    ...currentGoals,
                    ...goalData,
                    createdAt: serverTimestamp(),
                    profesionalId: userDetails.uid,
                    profesionalType: userDetails.tipo_profesional,
                    centroId: userDetails.IDBO || null,
                    completedGoals: [],
                    completedAt: null,
                    isCompleted: false
                });

                setSnackbar({
                    open: true,
                    message: 'Objetivos añadidos exitosamente',
                    severity: 'success'
                });
            } else {
                // Código existente para crear nuevo set de objetivos
                const goalData = {
                    goals: goals,
                    createdAt: serverTimestamp(),
                    createdBy: userDetails.uid,
                    clientId: clientId,
                    date: new Date().toISOString().split('T')[0],
                    status: 'pending',
                    lastUpdated: serverTimestamp(),
                    isTemplate: true
                };

                let goalsRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    goalsRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios');
                } else {
                    goalsRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios');
                }
                await addDoc(goalsRef, goalData);

                const dailyGoalsRef = collection(db, 'daily_goals');
                await addDoc(dailyGoalsRef, {
                    ...goalData,
                    profesionalId: userDetails.uid,
                    profesionalType: userDetails.tipo_profesional,
                    centroId: userDetails.IDBO || null,
                    completedGoals: [],
                    completedAt: null,
                    isCompleted: false
                });

                setSnackbar({
                    open: true,
                    message: 'Objetivos creados exitosamente',
                    severity: 'success'
                });
            }
            
            handleCloseGoalsDialog();
            fetchCurrentGoals();
        } catch (error) {
            console.error('Error al crear/añadir objetivos:', error);
            setSnackbar({
                open: true,
                message: 'Error al crear/añadir objetivos',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleMenuOpen = (event, goal, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedGoal({ ...goal, index });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedGoal(null);
    };

    const handleEditClick = () => {
        setGoals(currentGoals.goals);
        setOpenGoalsDialog(true);
        setEditingGoals(true);
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = async () => {
        try {
            const db = getFirestore();
            
            // Eliminar de la colección específica del profesional
            let goalsRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                goalsRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios', currentGoals.id);
            } else {
                goalsRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios', currentGoals.id);
            }
            await deleteDoc(goalsRef);

            // Crear nuevo documento en daily_goals indicando la eliminación
            const dailyGoalsRef = collection(db, 'daily_goals');
            await addDoc(dailyGoalsRef, {
                clientId,
                createdAt: serverTimestamp(),
                createdBy: userDetails.uid,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                status: 'deleted',
                lastUpdated: serverTimestamp(),
                isTemplate: false,
                deletedAt: serverTimestamp()
            });

            setSnackbar({
                open: true,
                message: 'Objetivos eliminados exitosamente',
                severity: 'success'
            });
            setCurrentGoals(null);
        } catch (error) {
            console.error('Error al eliminar los objetivos:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar los objetivos',
                severity: 'error'
            });
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    const handleUpdateGoals = async () => {
        try {
            const db = getFirestore();
            const goalData = {
                goals: goals,
                lastUpdated: serverTimestamp(),
                status: 'pending'
            };

            // Actualizar en la colección específica del profesional
            let goalsRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                goalsRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios', currentGoals.id);
            } else {
                goalsRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios', currentGoals.id);
            }
            await updateDoc(goalsRef, goalData);

            // Crear nuevo documento en daily_goals con los objetivos actualizados
            const dailyGoalsRef = collection(db, 'daily_goals');
            await addDoc(dailyGoalsRef, {
                ...currentGoals,
                ...goalData,
                createdAt: serverTimestamp(),
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                completedGoals: [],
                completedAt: null,
                isCompleted: false
            });

            setSnackbar({
                open: true,
                message: 'Objetivos actualizados exitosamente',
                severity: 'success'
            });
            handleCloseGoalsDialog();
            fetchCurrentGoals();
        } catch (error) {
            console.error('Error al actualizar los objetivos:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar los objetivos',
                severity: 'error'
            });
        }
    };

    const handleDeleteGoal = async (goalIndex) => {
        try {
            const db = getFirestore();
            const updatedGoals = currentGoals.goals.filter((_, index) => index !== goalIndex);
            
            const goalData = {
                goals: updatedGoals,
                lastUpdated: serverTimestamp(),
                status: updatedGoals.length === 0 ? 'deleted' : 'pending'
            };

            // Actualizar en la colección específica del profesional
            let goalsRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                goalsRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'objetivos_diarios', currentGoals.id);
            } else {
                goalsRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'objetivos_diarios', currentGoals.id);
            }

            if (updatedGoals.length === 0) {
                await deleteDoc(goalsRef);
            } else {
                await updateDoc(goalsRef, goalData);
            }

            // Crear nuevo documento en daily_goals con los objetivos actualizados
            const dailyGoalsRef = collection(db, 'daily_goals');
            await addDoc(dailyGoalsRef, {
                ...currentGoals,
                ...goalData,
                createdAt: serverTimestamp(),
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null,
                completedGoals: [],
                completedAt: null,
                isCompleted: false,
                deletedGoalIndex: goalIndex
            });

            setSnackbar({
                open: true,
                message: 'Objetivo eliminado exitosamente',
                severity: 'success'
            });

            if (updatedGoals.length === 0) {
                setCurrentGoals(null);
            } else {
                setCurrentGoals(prev => ({
                    ...prev,
                    goals: updatedGoals,
                    lastUpdated: new Date()
                }));
            }
        } catch (error) {
            console.error('Error al eliminar el objetivo:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar el objetivo',
                severity: 'error'
            });
        }
    };

    return (
        <Card sx={{ mb: 3 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <CheckCircleIcon sx={{ mr: 1 }} />
                    <Typography variant="h6">
                        Objetivos Diarios
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" paragraph>
                    Establece objetivos diarios para que tu cliente pueda marcarlos como completados y hacer seguimiento de su progreso.
                </Typography>
                {currentGoals ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setIsAddingToExisting(true);
                                setOpenGoalsDialog(true);
                            }}
                        >
                            Añadir Objetivos
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setIsAddingToExisting(false);
                                setOpenGoalsDialog(true);
                            }}
                        >
                            Nuevo Set
                        </Button>
                    </Box>
                ) : (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setIsAddingToExisting(false);
                            setOpenGoalsDialog(true);
                        }}
                    >
                        Crear Objetivos
                    </Button>
                )}

                {/* Sección de Objetivos Actuales */}
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="subtitle1">
                            Objetivos Actuales
                        </Typography>
                        {currentGoals && (
                            <IconButton
                                size="small"
                                onClick={(e) => handleMenuOpen(e, currentGoals)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : currentGoals ? (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Última actualización: {new Date(currentGoals.lastUpdated?.toDate()).toLocaleDateString()}
                                </Typography>
                                <Chip 
                                    label={currentGoals.status}
                                    size="small"
                                    color={currentGoals.status === 'completed' ? 'success' : 'default'}
                                    sx={{ ml: 2 }}
                                />
                            </Box>
                            <List>
                                {currentGoals.goals.map((goal, index) => (
                                    <ListItem 
                                        key={index} 
                                        sx={{ py: 0.5 }}
                                        secondaryAction={
                                            <IconButton 
                                                edge="end" 
                                                aria-label="delete"
                                                onClick={() => handleDeleteGoal(index)}
                                                size="small"
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemIcon>
                                            {goal.completed ? 
                                                <CheckCircleIcon color="success" /> : 
                                                <RadioButtonUncheckedIcon color="disabled" />
                                            }
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={goal.description}
                                            sx={{
                                                '& .MuiListItemText-primary': {
                                                    color: goal.completed ? 'text.secondary' : 'text.primary',
                                                    textDecoration: goal.completed ? 'line-through' : 'none'
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
                            No hay objetivos establecidos para este cliente.
                        </Typography>
                    )}
                </Box>
            </CardContent>

            {/* Menu de opciones */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>
                    <EditIcon sx={{ mr: 1 }} fontSize="small" />
                    Editar objetivos
                </MenuItem>
                <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
                    <DeleteIcon sx={{ mr: 1 }} fontSize="small" />
                    Eliminar objetivos
                </MenuItem>
            </Menu>

            {/* Diálogo de confirmación de eliminación */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que deseas eliminar estos objetivos? Esta acción no se puede deshacer.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleDeleteConfirm} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de objetivos */}
            <DailyGoalsDialog
                open={openGoalsDialog}
                onClose={handleCloseGoalsDialog}
                goals={goals}
                onAddGoal={handleAddGoal}
                onRemoveGoal={handleRemoveGoal}
                onGoalChange={handleGoalChange}
                onCreateGoals={handleCreateGoals}
                isEditing={editingGoals}
                isAddingToExisting={isAddingToExisting}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Card>
    );
};

export default DailyGoalsTool; 