import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const DailyGoalsDialog = ({
    open,
    onClose,
    goals,
    onAddGoal,
    onRemoveGoal,
    onGoalChange,
    onCreateGoals,
    isEditing,
    isAddingToExisting
}) => {
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                {isEditing ? 'Editar Objetivos Diarios' : 
                 isAddingToExisting ? 'Añadir Objetivos al Set Actual' : 
                 'Crear Nuevo Set de Objetivos'}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 2 }}>
                    {goals.map((goal, index) => (
                        <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <TextField
                                fullWidth
                                label={`Objetivo ${index + 1}`}
                                value={goal.description}
                                onChange={(e) => onGoalChange(index, e.target.value)}
                                placeholder="Ej: Realizar 30 minutos de cardio"
                                multiline
                                rows={2}
                            />
                            <IconButton 
                                color="error" 
                                onClick={() => onRemoveGoal(index)}
                                disabled={goals.length === 1}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    
                    <Button
                        startIcon={<AddIcon />}
                        onClick={onAddGoal}
                        sx={{ mt: 1 }}
                    >
                        Añadir Objetivo
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button 
                    variant="contained" 
                    onClick={onCreateGoals}
                    disabled={goals.some(g => !g.description)}
                >
                    {isEditing ? 'Guardar Cambios' : 
                     isAddingToExisting ? 'Añadir Objetivos' : 
                     'Crear Objetivos'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DailyGoalsDialog; 