import React, { useState, useContext, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    FormControl, 
    Select, 
    MenuItem, 
    InputLabel,
    Paper,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Grid,
    Avatar,
    ListItemAvatar,
    ListItemText,
    Chip,
    Tooltip,
    LinearProgress,
    DialogTitle,
    Switch
} from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { collection, onSnapshot, doc, getDoc, runTransaction, increment, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { useSnackbar } from 'notistack';
import ReactMarkdown from 'react-markdown';
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View, Image } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { 
    Barbell, 
    ForkKnife, 
    Timer,
    Bandaids,
    Microphone,
    MicrophoneSlash
} from "@phosphor-icons/react";
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import PlantillaConfigModal from '../modals/PlantillaConfigModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../config/firebaseConfig';
import { getStorage, ref, getBytes, uploadBytes, getDownloadURL } from 'firebase/storage';
import SendIcon from '@mui/icons-material/Send';
import Lottie from 'lottie-react';
import successAnimation from '../../assets/animations/Success.json';
import { FiCpu } from 'react-icons/fi';
import IA from '../pages/IA';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';

// Estilos para el PDF actualizados
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
        backgroundColor: '#f8f8f8'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
        borderBottom: '1 solid #ddd',
        paddingBottom: 20
    },
    logo: {
        width: 80,
        height: 80,
        marginRight: 15,
        objectFit: 'contain'
    },
    businessInfo: {
        flex: 1
    },
    businessName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5
    },
    businessDetails: {
        fontSize: 10,
        color: '#666',
        marginBottom: 3
    },
    title: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center'
    },
    section: {
        marginBottom: 10
    },
    heading1: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 15,
        color: '#FF5722'
    },
    heading2: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        marginTop: 12,
        color: '#FF5722'
    },
    heading3: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
        marginTop: 10,
        color: '#333'
    },
    paragraph: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 8,
        color: '#555'
    },
    listItem: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 4,
        paddingLeft: 15,
        color: '#555'
    }
});

// Función para procesar el markdown
const processMarkdown = async (markdown) => {
    try {
        await unified()
            .use(remarkParse)
            .use(remarkStringify)
            .process(markdown);

        // Dividir el contenido en secciones
        const sections = markdown.split('\n\n').map(section => {
            if (section.startsWith('# ')) {
                return { type: 'h1', content: section.replace('# ', '') };
            } else if (section.startsWith('## ')) {
                return { type: 'h2', content: section.replace('## ', '') };
            } else if (section.startsWith('### ')) {
                return { type: 'h3', content: section.replace('### ', '') };
            } else if (section.startsWith('- ')) {
                return { 
                    type: 'list', 
                    items: section.split('\n').map(item => item.replace('- ', ''))
                };
            } else {
                return { type: 'paragraph', content: section };
            }
        });

        return sections;
    } catch (error) {
        console.error('Error procesando markdown:', error);
        return [{ type: 'paragraph', content: markdown }];
    }
};

// Componente para el PDF actualizado
const PDFDocument = ({ markdown, clientData, userDetails, generationType }) => {
    const [sections, setSections] = useState([]);
    
    useEffect(() => {
        processMarkdown(markdown).then(setSections);
    }, [markdown]);

    // Determinar la URL del logo según el tipo de profesional
    const businessName = userDetails?.tipo_profesional === 'entrenador_independiente'
        ? userDetails?.display_name
        : userDetails?.tipo_profesional === 'entrenador_centro'
            ? userDetails?.centro?.nombreCentro
            : userDetails?.tipo_profesional === 'propietario_centro'
                ? userDetails?.centro?.nombreCentro
                : userDetails?.display_name;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado mejorado */}
                <View style={styles.header}>
                    <View style={styles.businessInfo}>
                        <Text style={styles.businessName}>
                            {businessName || 'Nombre del Negocio'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            {userDetails?.especialidad || 'Especialidad no especificada'}
                        </Text>
                        {userDetails?.tipo_profesional !== 'entrenador_independiente' && (
                            <Text style={styles.businessDetails}>
                                {userDetails?.centro?.email || 'Email no especificado'}
                            </Text>
                        )}
                        <Text style={styles.businessDetails}>
                            Profesional: {userDetails?.display_name || 'Nombre no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Cliente: {clientData?.nombre || 'Cliente no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Fecha: {new Date().toLocaleDateString('es-ES')}
                        </Text>
                    </View>
                </View>

                {/* Contenido */}
                {sections.map((section, index) => (
                    <View key={index} style={styles.section}>
                        {section.type === 'heading1' && (
                            <Text style={styles.heading1}>{section.content}</Text>
                        )}
                        {section.type === 'heading2' && (
                            <Text style={styles.heading2}>{section.content}</Text>
                        )}
                        {section.type === 'heading3' && (
                            <Text style={styles.heading3}>{section.content}</Text>
                        )}
                        {section.type === 'paragraph' && (
                            <Text style={styles.paragraph}>{section.content}</Text>
                        )}
                        {section.type === 'listItem' && (
                            <Text style={styles.listItem}>• {section.content}</Text>
                        )}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

const getBase64FromStorageUrl = async (url) => {
    try {
        // Extraer el path del storage de la URL
        const storage = getStorage(app);
        const fullPath = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
        const storageRef = ref(storage, fullPath);
        
        // Obtener los bytes de la imagen
        const bytes = await getBytes(storageRef);
        
        // Convertir a base64
        let binary = '';
        const bytes_array = new Uint8Array(bytes);
        for (let i = 0; i < bytes_array.byteLength; i++) {
            binary += String.fromCharCode(bytes_array[i]);
        }
        const base64 = btoa(binary);
        
        // Determinar el tipo de imagen basado en la extensión o usar un tipo por defecto
        const fileExtension = fullPath.split('.').pop()?.toLowerCase() || 'png';
        const mimeType = fileExtension === 'jpg' || fileExtension === 'jpeg' 
            ? 'image/jpeg' 
            : 'image/png';
        
        return `data:${mimeType};base64,${base64}`;
    } catch (error) {
        console.error('Error convirtiendo imagen a base64:', error);
        return null;
    }
};

const Generar = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [instructions, setInstructions] = useState('');
    const [loading, setLoading] = useState(true);
    const [generationType, setGenerationType] = useState('');
    const [routineDays, setRoutineDays] = useState(3);
    const [dietType, setDietType] = useState('diaria');
    const [selectedObjectives, setSelectedObjectives] = useState([]);
    const [routineLocation, setRoutineLocation] = useState('gimnasio');
    const [customLocation, setCustomLocation] = useState('');
    const [calories, setCalories] = useState('');
    const [avoidFoods, setAvoidFoods] = useState([]);
    const [customAvoidFood, setCustomAvoidFood] = useState('');
    const { userDetails } = useContext(AuthContext);
    const [isGenerating, setIsGenerating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [generatedContent, setGeneratedContent] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [openUnavailableModal, setOpenUnavailableModal] = useState(false);
    const [unavailableFeature, setUnavailableFeature] = useState('');
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [editableContent, setEditableContent] = useState('');
    const [editPrompt, setEditPrompt] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [plantillaConfig, setPlantillaConfig] = useState(null);
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
    const [openIASettings, setOpenIASettings] = useState(false);
    const [selectedModel, setSelectedModel] = useState('standard');

    const commonFoodsToAvoid = [
        { value: 'lacteos', label: 'Lácteos', icon: '🥛' },
        { value: 'gluten', label: 'Gluten', icon: '🌾' },
        { value: 'frutos_secos', label: 'Frutos secos', icon: '🥜' },
        { value: 'mariscos', label: 'Mariscos', icon: '🦐' },
        { value: 'huevos', label: 'Huevos', icon: '🥚' },
        { value: 'soja', label: 'Soja', icon: '🫘' },
        { value: 'cerdo', label: 'Cerdo', icon: '🐷' },
        { value: 'pescado', label: 'Pescado', icon: '🐟' }
    ];

    // Efecto para manejar la barra de progreso
    useEffect(() => {
        let timer;
        if (isGenerating && progress < 95) {
            timer = setTimeout(() => {
                // Fórmula para hacer que el progreso sea cada vez más lento
                const increment = Math.max(0.5, (100 - progress) / 15);
                setProgress(prev => Math.min(95, prev + increment));
            }, 200);
        }
        return () => {
            clearTimeout(timer);
            if (!isGenerating) {
                setProgress(0);
            }
        };
    }, [isGenerating, progress]);

    const generationTypes = [
        { 
            value: 'rutina', 
            label: 'Rutina de Entrenamiento', 
            endpoint: 'crear-rutina-beta-v4',
            icon: <Barbell size={24} weight="duotone" />,
            color: '#FF5722'
        },
        { 
            value: 'dieta', 
            label: 'Plan de Alimentación', 
            endpoint: 'crear-dieta-beta-v3',
            icon: <ForkKnife size={24} weight="duotone" />,
            color: '#4CAF50'
        }
    ];

    const objectives = [
        { value: 'perder_peso', label: 'Perder peso', icon: '🎯' },
        { value: 'aumentar_masa', label: 'Aumentar masa muscular', icon: '💪' },
        { value: 'mantenimiento', label: 'Mantenimiento', icon: '⚖️' },
        { value: 'definicion', label: 'Definición muscular', icon: '🏋️' },
        { value: 'fuerza', label: 'Ganar fuerza', icon: '🏋️‍♂️' },
        { value: 'resistencia', label: 'Mejorar resistencia', icon: '🏃' },
        { value: 'flexibilidad', label: 'Aumentar flexibilidad', icon: '🧘‍♂️' },
        { value: 'salud', label: 'Mejorar salud general', icon: '❤️' }
    ];

    useEffect(() => {
        let unsubscribe;
        
        const fetchClients = async () => {
            if (!userDetails) {
                setLoading(false);
                return;
            }

            try {
                let clientsCollectionRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
                }

                unsubscribe = onSnapshot(clientsCollectionRef, (snapshot) => {
                    const clientsData = snapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }))
                        .filter(client => !client.isInSolicitudes);

                    setClients(clientsData);
                    setLoading(false);
                });

            } catch (error) {
                console.error("Error al cargar los clientes:", error);
                setLoading(false);
            }
        };

        fetchClients();
        return () => unsubscribe && unsubscribe();
    }, [userDetails]);

    // Inicializar el reconocimiento de voz
    useEffect(() => {
        const initializeSpeechRecognition = () => {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            
            if (SpeechRecognition) {
                const recognitionInstance = new SpeechRecognition();
                recognitionInstance.continuous = false;
                recognitionInstance.interimResults = false;
                recognitionInstance.lang = 'es-ES';

                recognitionInstance.onstart = () => {
                    setIsRecording(true);
                };

                recognitionInstance.onend = () => {
                    setIsRecording(false);
                };

                recognitionInstance.onerror = (event) => {
                    console.error('Error en reconocimiento de voz:', event.error);
                    setIsRecording(false);
                    enqueueSnackbar('Error en el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                        variant: 'error' 
                    });
                };

                recognitionInstance.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setInstructions(prev => prev + (prev ? ' ' : '') + transcript);
                };

                setRecognition(recognitionInstance);
            }
        };

        initializeSpeechRecognition();

        return () => {
            if (recognition) {
                recognition.onresult = null;
                recognition.onend = null;
                recognition.onerror = null;
                recognition.onstart = null;
            }
        };
    }, []);

    useEffect(() => {
        if (selectedClient && clients) {
            const clientData = clients.find(client => client.id === selectedClient);
            setSelectedClientData(clientData);
        }
    }, [selectedClient, clients]);

    const toggleRecording = () => {
        if (!recognition) {
            enqueueSnackbar('Tu navegador no soporta reconocimiento de voz. Por favor, utiliza Chrome o Edge.', { 
                variant: 'error',
                autoHideDuration: 5000
            });
            return;
        }

        if (isRecording) {
            recognition.stop();
        } else {
            try {
                recognition.start();
            } catch (error) {
                console.error('Error al iniciar reconocimiento:', error);
                setIsRecording(false);
                enqueueSnackbar('Error al iniciar el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                    variant: 'error' 
                });
            }
        }
    };

    const updateInstructionsText = (currentInstructions, selectedObjs, daysCount, dietType, location, customLoc, cals, avoided) => {
        // Primero, eliminar todo el contenido después de la primera línea divisoria
        let newInstructions = currentInstructions.replace(/\n+_+\n.*$/s, '');

        // Determinar si necesitamos añadir la sección de parámetros
        const hasParameters = selectedObjs.length > 0 || daysCount !== null || dietType || (location && daysCount !== null) || (cals && dietType) || (avoided && avoided.length > 0);

        // Añadir línea divisoria y parámetros si es necesario
        if (hasParameters) {
            newInstructions += '\n\n\n\n\n' + '_'.repeat(50) + '\n';

            // Añadir objetivos si hay
            if (selectedObjs.length > 0) {
                const objectivesText = selectedObjs
                    .map(obj => objectives.find(o => o.value === obj)?.label)
                    .filter(Boolean)
                    .join(', ');
                newInstructions += `\nOBJETIVOS PRINCIPALES: ${objectivesText}`;
            }

            // Añadir ubicación solo para rutinas
            if (location && daysCount !== null) {
                const locationText = location === 'otro' ? customLoc : location;
                newInstructions += `\n\nUBICACIÓN: ${locationText}`;
            }

            // Añadir calorías y alimentos a evitar para dietas
            if (dietType) {
                if (cals) {
                    newInstructions += `\n\nCALORÍAS DIARIAS: ${cals} kcal`;
                }
                if (avoided && avoided.length > 0) {
                    const avoidedText = avoided
                        .map(food => {
                            const commonFood = commonFoodsToAvoid.find(f => f.value === food);
                            return commonFood ? commonFood.label : food;
                        })
                        .join(', ');
                    newInstructions += `\n\nALIMENTOS A EVITAR: ${avoidedText}`;
                }
            }

            // Añadir días de rutina o tipo de dieta según corresponda
            if (daysCount !== null) {
                newInstructions += `\n\nIMPORTANTE, DEVUELVE ${daysCount} ${daysCount === 1 ? 'DÍA' : 'DÍAS'} DE RUTINA`;
            } else if (dietType) {
                newInstructions += `\n\nIMPORTANTE, DEVUELVE UNA DIETA ${dietType.toUpperCase()}`;
            }
        }

        return newInstructions;
    };

    const handleObjectiveToggle = (objective) => {
        setSelectedObjectives(prev => {
            const newObjectives = prev.includes(objective)
                ? prev.filter(obj => obj !== objective)
                : [...prev, objective];
            
            const newInstructions = updateInstructionsText(
                instructions,
                newObjectives,
                generationType === 'rutina' ? routineDays : null,
                generationType === 'dieta' ? dietType : null,
                routineLocation,
                customLocation,
                calories,
                avoidFoods
            );
            
            setInstructions(newInstructions);
            return newObjectives;
        });
    };

    const handleRoutineDaysChange = (e) => {
        const newDays = e.target.value;
        setRoutineDays(newDays);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            newDays,
            null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods
        );
        
        setInstructions(newInstructions);
    };

    const handleDietTypeChange = (e) => {
        const newType = e.target.checked ? 'semanal' : 'diaria';
        setDietType(newType);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            null,
            newType,
            routineLocation,
            customLocation,
            calories,
            avoidFoods
        );
        
        setInstructions(newInstructions);
    };

    const handleLocationChange = (event) => {
        const newLocation = event.target.value;
        setRoutineLocation(newLocation);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            generationType === 'dieta' ? dietType : null,
            newLocation,
            customLocation,
            calories,
            avoidFoods
        );
        
        setInstructions(newInstructions);
    };

    const handleCustomLocationChange = (event) => {
        const newCustomLocation = event.target.value;
        setCustomLocation(newCustomLocation);
        
        if (routineLocation === 'otro') {
            const newInstructions = updateInstructionsText(
                instructions,
                selectedObjectives,
                generationType === 'rutina' ? routineDays : null,
                generationType === 'dieta' ? dietType : null,
                routineLocation,
                newCustomLocation,
                calories,
                avoidFoods
            );
            
            setInstructions(newInstructions);
        }
    };

    const handleCaloriesChange = (e) => {
        const newCalories = e.target.value;
        setCalories(newCalories);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            null,
            dietType,
            null,
            null,
            newCalories,
            avoidFoods
        );
        
        setInstructions(newInstructions);
    };

    const handleAvoidFoodToggle = (food) => {
        setAvoidFoods(prev => {
            const newFoods = prev.includes(food)
                ? prev.filter(f => f !== food)
                : [...prev, food];
            
            const newInstructions = updateInstructionsText(
                instructions,
                selectedObjectives,
                null,
                dietType,
                null,
                null,
                calories,
                newFoods
            );
            
            setInstructions(newInstructions);
            return newFoods;
        });
    };

    const handleAddCustomFood = () => {
        if (customAvoidFood.trim()) {
            setAvoidFoods(prev => {
                const newFoods = [...prev, customAvoidFood.trim()];
                
                const newInstructions = updateInstructionsText(
                    instructions,
                    selectedObjectives,
                    null,
                    dietType,
                    null,
                    null,
                    calories,
                    newFoods
                );
                
                setInstructions(newInstructions);
                return newFoods;
            });
            setCustomAvoidFood('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verificar si es crossfit o rehabilitación
        if (generationType === 'crossfit' || generationType === 'rehabilitacion') {
            setUnavailableFeature(generationType === 'crossfit' ? 'CrossFit' : 'Rehabilitación');
            setOpenUnavailableModal(true);
            return;
        }

        setIsGenerating(true);
        setProgress(0);
        setOpenPreview(true);

        try {
            const selectedClientData = clients.find(client => client.id === selectedClient);
            if (!selectedClientData) {
                throw new Error('Cliente no encontrado');
            }

            const selectedType = generationTypes.find(type => type.value === generationType);
            if (!selectedType) {
                throw new Error('Tipo de generación no válido');
            }

            // Obtener la configuración de IA
            let iaConfig;
            try {
                let configDoc;
                if (userDetails.tipo_profesional === 'propietario_centro') {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const iaConfigRef = doc(collection(centroRef, 'IA'), 'configuracion');
                    configDoc = await getDoc(iaConfigRef);
                } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const iaConfigRef = doc(collection(userRef, 'IA'), 'configuracion');
                    configDoc = await getDoc(iaConfigRef);
                }
                
                if (configDoc && configDoc.exists()) {
                    iaConfig = configDoc.data();
                }
            } catch (error) {
                console.error("Error al obtener la configuración de IA:", error);
            }

            // Preparar el payload con la configuración de IA
            const payload = {
                IDPT: userDetails.IDPT,
                IDBO: userDetails.IDBO || null,
                instrucciones: instructions,
                tipo_profesional: userDetails.tipo_profesional,
                tipo_generacion: generationType,
                ia_config: iaConfig || null
            };

            console.log('Payload a enviar:', payload);

            // Determinar el endpoint según el modelo y tipo de generación
            let endpoint;
            if (generationType === 'rutina') {
                endpoint = selectedModel === 'pro' 
                    ? 'https://crear-rutina-beta-v4-pro-39035072089.europe-southwest1.run.app'
                    : 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/crear-rutina-beta-v4';
            } else {
                endpoint = `https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${selectedType.endpoint}`;
            }

            // Realizar la petición al endpoint correspondiente
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            // Verificar la respuesta
            if (!response.ok) {
                let errorMessage;
                try {
                    const errorData = await response.json();
                    console.error('Error detallado:', errorData);
                    errorMessage = errorData.error || 'Error en la generación';
                } catch (e) {
                    const errorText = await response.text();
                    console.error('Error completo:', errorText);
                    errorMessage = 'Error en la generación';
                }
                throw new Error(errorMessage);
            }

            // Procesar la respuesta
            let data;
            try {
                data = await response.json();
                console.log('Respuesta completa:', data);

                const markdown = data.plan_markdown || data.rutina_markdown || data.contenido_markdown;

                if (!markdown) {
                    console.error('La respuesta no contiene el markdown esperado:', data);
                    throw new Error('Formato de respuesta inválido');
                }

                // Eliminar asteriscos del texto
                const cleanMarkdown = markdown.replace(/\*/g, '');

                // Actualizar contador global después de una generación exitosa
                const statsRef = doc(db, 'stats', 'global');
                await runTransaction(db, async (transaction) => {
                    const statsDoc = await transaction.get(statsRef);
                    if (!statsDoc.exists()) {
                        transaction.set(statsRef, { 
                            [`total_${generationType}s`]: 1 
                        });
                    } else {
                        transaction.update(statsRef, {
                            [`total_${generationType}s`]: increment(1)
                        });
                    }
                });

                // Guardar en el historial
                const historialRef = collection(db, 'stats', 'global', 'historial');
                await addDoc(historialRef, {
                    tipo: generationType,
                    entrenador: {
                        id: userDetails.uid,
                        nombre: userDetails.display_name,
                        tipo_profesional: userDetails.tipo_profesional
                    },
                    modelo: selectedModel,
                    fecha: serverTimestamp(),
                    cliente: {
                        id: selectedClient,
                        nombre: selectedClientData.nombre
                    }
                });

                setProgress(100);
                setGeneratedContent(cleanMarkdown);
                setEditableContent(cleanMarkdown);
                enqueueSnackbar('Contenido generado con éxito', { variant: 'success' });
            } catch (error) {
                console.error('Error al procesar la respuesta:', error);
                throw new Error('Error al procesar la respuesta del servidor');
            }

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error en la generación', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handlePromptEdit = async () => {
        if (!editPrompt.trim()) {
            enqueueSnackbar('Por favor, introduce un prompt para editar el contenido', { variant: 'warning' });
            return;
        }

        setIsEditing(true);
        try {
            const endpoint = `editar-${generationType}-beta`;

            // Determinar el nombre del parámetro según el tipo de contenido
            const contentParam = generationType === 'dieta' ? 'dieta_actual' : 'rutina_actual';
            
            const response = await fetch(`https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    tipo_profesional: userDetails.tipo_profesional,
                    IDPT: userDetails.tipo_profesional === 'entrenador_independiente' ? userDetails.IDPT : null,
                    IDBO: userDetails.tipo_profesional !== 'entrenador_independiente' ? userDetails.IDBO : null,
                    id_cliente: selectedClient,
                    instrucciones: editPrompt,
                    [contentParam]: editableContent
                })
            });

            let errorData;
            try {
                errorData = await response.json();
            } catch (e) {
                errorData = await response.text();
            }

            if (!response.ok) {
                console.error('Error de la cloud function:', errorData);
                throw new Error(
                    errorData?.error || 
                    'Error al editar el contenido. Por favor, intenta con otras instrucciones.'
                );
            }

            // Obtener el contenido según el tipo de generación
            let newContent;
            if (generationType === 'dieta') {
                newContent = errorData.dieta_markdown;
            } else if (generationType === 'rutina') {
                newContent = errorData.rutina_markdown;
            } else {
                newContent = errorData.contenido_markdown;
            }
            
            if (!newContent) {
                console.error('Respuesta recibida:', errorData);
                throw new Error('La respuesta no contiene el contenido esperado. Por favor, intenta de nuevo.');
            }

            setEditableContent(newContent);
            setGeneratedContent(newContent);
            setEditPrompt('');
            enqueueSnackbar('Contenido editado con éxito', { variant: 'success' });
        } catch (error) {
            console.error('Error al editar:', error);
            enqueueSnackbar(
                error.message || 
                'Ha ocurrido un error al editar el contenido. Por favor, intenta de nuevo.', 
                { variant: 'error' }
            );
        } finally {
            setIsEditing(false);
        }
    };

    const handleSavePlantillaConfig = (config) => {
        setPlantillaConfig(config);
        // Aquí puedes guardar la configuración en tu backend o donde sea necesario
    };

    const handleGeneratePDF = async () => {
        try {
            setIsGenerating(true);
            setProgress(0);

            // Obtener las URLs de las imágenes
            const businessLogoUrl = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? userDetails?.business_logo_url
                : userDetails?.centro?.business_logo_url;

            const centroPhotoUrl = userDetails?.centro?.photo_url;

            // Obtener la configuración de la plantilla
            const docRef = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? doc(db, 'userDetails', userDetails.uid)
                : doc(db, 'Centros', userDetails.IDBO);

            const docSnap = await getDoc(docRef);
            const plantillaConfig = docSnap.exists() ? docSnap.data().plantillaConfig : null;

            // Preparar los datos para la cloud function
            const pdfData = {
                content: {
                    text: editableContent,
                    type: generationType,
                    clientData: selectedClientData
                },
                businessData: {
                    businessName: plantillaConfig?.businessName || (userDetails?.tipo_profesional === 'entrenador_independiente' 
                        ? userDetails?.display_name 
                        : userDetails?.centro?.nombreCentro),
                    business_logo_url: businessLogoUrl,
                    contactInfo: plantillaConfig?.contactInfo || userDetails?.contactInfo,
                    tipo_profesional: userDetails?.tipo_profesional,
                    centro_photo_url: centroPhotoUrl,
                    nombreCentro: userDetails?.centro?.nombreCentro
                },
                design: {
                    headerStyle: plantillaConfig?.design?.headerStyle || 'modern',
                    primaryColor: plantillaConfig?.design?.primaryColor || '#FF5722',
                    secondaryColor: plantillaConfig?.design?.secondaryColor || '#FF1493',
                    fontSize: plantillaConfig?.design?.fontSize || 'medium',
                    showLogo: plantillaConfig?.showLogo ?? true,
                    showDate: plantillaConfig?.showDate ?? true,
                    showContactInfo: plantillaConfig?.showContactInfo ?? true,
                    footerText: plantillaConfig?.footerText || ''
                }
            };

            console.log('Enviando datos a la cloud function:', pdfData);

            // Llamar a la cloud function
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/pdf-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(pdfData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`Error al generar el PDF: ${errorText}`);
            }

            const result = await response.json();
            console.log('Respuesta de la cloud function recibida');
            
            if (!result.pdf_base64) {
                throw new Error('No se recibió el PDF en base64');
            }

            // Convertir base64 a Blob
            const byteCharacters = atob(result.pdf_base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Crear URL del blob y descargar localmente
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${generationType}_${selectedClientData?.nombre || 'documento'}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setProgress(100);
            enqueueSnackbar('PDF generado correctamente', { variant: 'success' });
            return blob;

        } catch (error) {
            console.error('Error al generar el PDF:', error);
            enqueueSnackbar(error.message || 'Error al generar el PDF', { variant: 'error' });
            return null;
        } finally {
            setIsGenerating(false);
        }
    };

    const handleImplantRoutine = async () => {
        try {
            setIsGenerating(true);
            
            // 1. Convertir la rutina a JSON
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/routine-to-json-v2', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    routine_text: editableContent
                })
            });

            if (!response.ok) {
                throw new Error('Error al convertir la rutina a JSON');
            }

            const routineData = await response.json();
            console.log('Rutina convertida a JSON:', routineData);

            // 2. Guardar en Firestore
            const routinesRef = collection(db, 'routines');
            const newRoutineDoc = await addDoc(routinesRef, {
                ...routineData,
                clientId: selectedClient,
                IDPT: userDetails.IDPT,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });

            console.log('Rutina guardada con ID:', newRoutineDoc.id);
            enqueueSnackbar('Rutina implantada exitosamente', { variant: 'success' });
            
            // Cerrar el modal de previsualización y mostrar animación
            setOpenPreview(false);
            setShowSuccessAnimation(true);
            
            // Cerrar la animación después de 2 segundos
            setTimeout(() => {
                setShowSuccessAnimation(false);
            }, 2000);

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error al procesar la rutina', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handleImplantDiet = async () => {
        try {
            setIsGenerating(true);
            
            // 1. Convertir la dieta a JSON
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/diet-to-json-v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    diet_text: editableContent
                })
            });

            if (!response.ok) {
                throw new Error('Error al convertir la dieta a JSON');
            }

            const dietData = await response.json();
            console.log('Dieta convertida a JSON:', dietData);

            // 2. Generar el PDF y obtener la URL
            const storage = getStorage(app);
            const fileName = `${generationType}_${selectedClient}_${Date.now()}.pdf`;
            const pdfRef = ref(storage, `pdf_generados/${fileName}`);
            
            // Convertir el contenido a PDF y subirlo al Storage
            const pdfBlob = await handleGeneratePDF();
            await uploadBytes(pdfRef, pdfBlob);
            const pdfUrl = await getDownloadURL(pdfRef);

            // 3. Guardar en la colección diets con la nueva estructura
            const dietsRef = collection(db, 'diets');
            const newDietDoc = await addDoc(dietsRef, {
                clientId: selectedClient,
                IDPT: userDetails.IDPT,
                pdfUrl: pdfUrl,
                content: editableContent,
                diet_data: dietData,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });

            console.log('Dieta guardada con ID:', newDietDoc.id);
            enqueueSnackbar('Dieta implantada exitosamente', { variant: 'success' });

            // Cerrar el modal de previsualización y mostrar animación
            setOpenPreview(false);
            setShowSuccessAnimation(true);
            
            // Cerrar la animación después de 2 segundos
            setTimeout(() => {
                setShowSuccessAnimation(false);
            }, 2000);

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error al procesar la dieta', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Generador de Contenido
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="outlined"
                        startIcon={<SettingsIcon />}
                        onClick={() => setOpenConfigModal(true)}
                        sx={{
                            borderColor: 'primary.main',
                            color: 'primary.main',
                            '&:hover': {
                                borderColor: 'primary.dark',
                                backgroundColor: 'rgba(255, 87, 34, 0.04)'
                            }
                        }}
                    >
                        Configurar Plantilla
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<FiCpu size={16} />}
                        onClick={() => setOpenIASettings(true)}
                        sx={{
                            borderColor: 'primary.main',
                            color: 'primary.main',
                            '&:hover': {
                                borderColor: 'primary.dark',
                                backgroundColor: 'rgba(255, 87, 34, 0.04)'
                            }
                        }}
                    >
                        Configurar IA
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
                <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    Selecciona un cliente y el tipo de contenido que deseas generar.
                </Typography>
                <FormControl size="small" sx={{ ml: 1 }}>
                    <Select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'rgba(255, 87, 34, 0.04)',
                            color: '#FF5722',
                            fontWeight: 500,
                            borderRadius: '12px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid rgba(255, 87, 34, 0.2)',
                                borderRadius: '12px',
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(255, 87, 34, 0.3)',
                                }
                            },
                            '&.Mui-focused': {
                                backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#FF5722',
                                    borderWidth: '1px',
                                }
                            },
                            '& .MuiSelect-select': {
                                paddingY: '6px',
                                paddingX: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                minWidth: '220px'
                            },
                            '& .MuiSelect-icon': {
                                color: '#FF5722'
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '12px',
                                    mt: 1,
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                    '& .MuiMenuItem-root': {
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 87, 34, 0.12)'
                                            }
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        <MenuItem value="standard">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, py: 0.5 }}>
                                <FiCpu size={16} style={{ opacity: 0.9 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: 1.2 }}>
                                        FitAI-Gen-1
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" sx={{ 
                                        fontSize: '0.75rem',
                                        opacity: 0.8,
                                        lineHeight: 1.2
                                    }}>
                                        Rápido y avanzado
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <MenuItem value="pro">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, py: 0.5 }}>
                                <FiCpu size={16} style={{ opacity: 0.9 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: 1.2 }}>
                                        FitAI-Gen-1 Pro
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" sx={{ 
                                        fontSize: '0.75rem',
                                        opacity: 0.8,
                                        lineHeight: 1.2
                                    }}>
                                        Lento pero potente
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                    </Select>
                </FormControl>
                <Tooltip title="Elige entre nuestros modelos de IA: FitAI-Gen-1 para resultados rápidos y avanzados, o FitAI-Gen-1 Pro para resultados más potentes pero con mayor tiempo de procesamiento." placement="right">
                    <IconButton size="small" sx={{ 
                        ml: 1, 
                        color: '#FF5722',
                        opacity: 0.8,
                        '&:hover': {
                            opacity: 1,
                            backgroundColor: 'rgba(255, 87, 34, 0.08)'
                        }
                    }}>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Paper elevation={3} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ position: 'relative', mb: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"1"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Selecciona el tipo de contenido
                        </Typography>

                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            {generationTypes.map((type) => (
                                <Grid item xs={12} sm={6} md={3} key={type.value}>
                                    <Paper
                                        elevation={generationType === type.value ? 3 : 1}
                                        sx={{
                                            p: 2,
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                            border: generationType === type.value ? `2px solid ${type.color}` : '2px solid transparent',
                                            '&:hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: 3
                                            },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                            height: '100%',
                                            backgroundColor: generationType === type.value ? `${type.color}10` : 'transparent'
                                        }}
                                        onClick={() => {
                                            setGenerationType(type.value);
                                            // Resetear instrucciones y objetivos al cambiar de tipo
                                            setInstructions('');
                                            setSelectedObjectives([]);
                                        }}
                                    >
                                        <Box sx={{ 
                                            color: type.color,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            {type.icon}
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                            sx={{
                                                fontWeight: generationType === type.value ? 600 : 400
                                            }}
                                        >
                                            {type.label}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"2"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Selecciona el cliente
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="client-select-label">Cliente</InputLabel>
                            <Select
                                labelId="client-select-label"
                                value={selectedClient}
                                label="Cliente"
                                onChange={(e) => setSelectedClient(e.target.value)}
                                required
                                disabled={isGenerating}
                                sx={{
                                    '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }
                                }}
                            >
                                {clients.map((client) => (
                                    <MenuItem 
                                        key={client.id} 
                                        value={client.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            py: 1
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar 
                                                src={client.photo_url} 
                                                alt={`${client.nombre} ${client.apellidos}`}
                                                sx={{ 
                                                    width: 40, 
                                                    height: 40,
                                                    bgcolor: !client.photo_url ? 'primary.main' : 'transparent'
                                                }}
                                            >
                                                {!client.photo_url && `${client.nombre.charAt(0)}${client.apellidos.charAt(0)}`}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${client.nombre} ${client.apellidos}`}
                                            secondary={client.email}
                                            primaryTypographyProps={{
                                                fontWeight: 500
                                            }}
                                        />
                                        {client.plan === 'Pro' && (
                                            <Chip 
                                                label="Pro"
                                                size="small"
                                                sx={{
                                                    background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    ml: 'auto'
                                                }}
                                            />
                                        )}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"3"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Define los objetivos
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: 1,
                            '& > *': { m: 0.5 }
                        }}>
                            {objectives.map((objective) => (
                                <Chip
                                    key={objective.value}
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <span>{objective.icon}</span>
                                            <span>{objective.label}</span>
                                        </Box>
                                    }
                                    onClick={() => handleObjectiveToggle(objective.value)}
                                    color={selectedObjectives.includes(objective.value) ? "primary" : "default"}
                                    variant={selectedObjectives.includes(objective.value) ? "filled" : "outlined"}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: 1
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    {/* Selector de días para rutina */}
                    {(generationType === 'rutina' || generationType === 'dieta') && (
                        <Box sx={{ position: 'relative', mb: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                color: 'text.secondary',
                                '&::before': {
                                    content: '"4"',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                    color: '#FF5722',
                                    marginRight: '8px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}>
                                {generationType === 'rutina' ? 'Configura la rutina' : 'Configura la dieta'}
                            </Typography>
                            {generationType === 'rutina' && (
                                <>
                                    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                                        <InputLabel>Días de Rutina</InputLabel>
                                        <Select
                                            value={routineDays}
                                            onChange={handleRoutineDaysChange}
                                            label="Días de Rutina"
                                        >
                                            {[1,2,3,4,5,6,7].map((day) => (
                                                <MenuItem key={day} value={day}>
                                                    {day} {day === 1 ? 'día' : 'días'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel>Ubicación</InputLabel>
                                        <Select
                                            value={routineLocation}
                                            onChange={handleLocationChange}
                                            label="Ubicación"
                                        >
                                            <MenuItem value="gimnasio">Gimnasio</MenuItem>
                                            <MenuItem value="casa">Casa</MenuItem>
                                            <MenuItem value="hibrido">Híbrido</MenuItem>
                                            <MenuItem value="otro">Otro</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {routineLocation === 'otro' && (
                                        <TextField
                                            fullWidth
                                            label="Especifica la ubicación"
                                            value={customLocation}
                                            onChange={handleCustomLocationChange}
                                            sx={{ mb: 2 }}
                                            placeholder="Ej: Parque, Playa, etc."
                                        />
                                    )}
                                </>
                            )}

                            {generationType === 'dieta' && (
                                <>
                                    <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
                                        <Typography>Tipo de Dieta:</Typography>
                                        <Tooltip title="Dieta Diaria: Se genera un día tipo con diferentes opciones de comidas. Dieta Semanal: Se genera un plan específico para cada día de la semana." placement="right">
                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                                <Typography sx={{ mr: 1 }}>Diaria</Typography>
                                                <Switch
                                                    checked={dietType === 'semanal'}
                                                    onChange={handleDietTypeChange}
                                                    color="primary"
                                                />
                                                <Typography sx={{ ml: 1 }}>Semanal</Typography>
                                            </Box>
                                        </Tooltip>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Calorías diarias"
                                        value={calories}
                                        onChange={handleCaloriesChange}
                                        sx={{ mb: 2 }}
                                        placeholder="Ej: 2000"
                                        InputProps={{
                                            endAdornment: <Typography color="text.secondary">kcal</Typography>
                                        }}
                                    />

                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ mb: 1 }}>
                                            Alimentos a evitar
                                        </Typography>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            flexWrap: 'wrap', 
                                            gap: 1,
                                            mb: 2
                                        }}>
                                            {commonFoodsToAvoid.map((food) => (
                                                <Chip
                                                    key={food.value}
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                            <span>{food.icon}</span>
                                                            <span>{food.label}</span>
                                                        </Box>
                                                    }
                                                    onClick={() => handleAvoidFoodToggle(food.value)}
                                                    color={avoidFoods.includes(food.value) ? "primary" : "default"}
                                                    variant={avoidFoods.includes(food.value) ? "filled" : "outlined"}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        transition: 'all 0.2s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-1px)',
                                                            boxShadow: 1
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={customAvoidFood}
                                                onChange={(e) => setCustomAvoidFood(e.target.value)}
                                                placeholder="Añadir otro alimento a evitar..."
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        handleAddCustomFood();
                                                    }
                                                }}
                                            />
                                            <Button
                                                variant="outlined"
                                                onClick={handleAddCustomFood}
                                                disabled={!customAvoidFood.trim()}
                                            >
                                                Añadir
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"5"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Escribe las instrucciones específicas
                        </Typography>
                        <Box sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={12}
                                label="Instrucciones"
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                required
                                disabled={isGenerating}
                                sx={{ 
                                    mb: 3,
                                    '& .MuiOutlinedInput-root': {
                                        pr: 5 // Espacio para el botón de micrófono
                                    }
                                }}
                                placeholder={`Describe aquí las instrucciones para generar ${
                                    generationType === 'rutina' ? 'la rutina' :
                                    generationType === 'dieta' ? 'el plan de alimentación' :
                                    'el contenido'
                                } para tu cliente...`}
                            />
                            <Tooltip title={isRecording ? "Detener grabación" : "Grabar instrucciones por voz"}>
                                <IconButton
                                    onClick={toggleRecording}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: isRecording ? 'error.main' : 'action.active',
                                        animation: isRecording ? 'pulse 1.5s infinite' : 'none',
                                        '@keyframes pulse': {
                                            '0%': {
                                                transform: 'scale(1)',
                                                opacity: 1
                                            },
                                            '50%': {
                                                transform: 'scale(1.2)',
                                                opacity: 0.8
                                            },
                                            '100%': {
                                                transform: 'scale(1)',
                                                opacity: 1
                                            }
                                        }
                                    }}
                                >
                                    {isRecording ? 
                                        <MicrophoneSlash size={24} weight="duotone" /> : 
                                        <Microphone size={24} weight="duotone" />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Button 
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={isGenerating}
                        sx={{
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            color: 'white',
                            py: 1.5,
                            fontWeight: 'bold',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                            }
                        }}
                    >
                        {isGenerating ? (
                            <>
                                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                Generando...
                            </>
                        ) : (
                            'Generar Contenido'
                        )}
                    </Button>
                </form>
            </Paper>

            {/* Dialog para previsualizar el contenido */}
            <Dialog
                open={openPreview && (generatedContent !== null || isGenerating)}
                onClose={() => !isGenerating && setOpenPreview(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    {isGenerating ? (
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '300px',
                            textAlign: 'center',
                            gap: 3
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Generando contenido personalizado...
                            </Typography>
                            <Box sx={{ width: '100%', maxWidth: 400, position: 'relative' }}>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={progress}
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        backgroundColor: '#f0f0f0',
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5,
                                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                            backgroundSize: '200% 200%',
                                            animation: 'gradient 2s ease infinite',
                                        },
                                        '@keyframes gradient': {
                                            '0%': {
                                                backgroundPosition: '0% 50%'
                                            },
                                            '50%': {
                                                backgroundPosition: '100% 50%'
                                            },
                                            '100%': {
                                                backgroundPosition: '0% 50%'
                                            },
                                        }
                                    }}
                                />
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {progress < 95 ? 'Esto puede tardar unos segundos...' : 'Casi listo...'}
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                <IconButton onClick={() => setOpenPreview(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            
                            <Typography variant="h6" gutterBottom>
                                Vista previa del contenido
                            </Typography>

                            {/* Campo de texto editable con botón de copiar */}
                            <Box sx={{ position: 'relative', mb: 3 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={20}
                                    value={editableContent}
                                    onChange={(e) => setEditableContent(e.target.value)}
                                    variant="outlined"
                                    sx={{ 
                                        '& .MuiOutlinedInput-root': {
                                            fontFamily: 'monospace',
                                            fontSize: '0.9rem',
                                            lineHeight: '1.5',
                                            backgroundColor: '#f8f9fa',
                                            '&:hover': {
                                                backgroundColor: '#fff',
                                            },
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.12)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#FF5722',
                                            }
                                        }
                                    }}
                                />
                                <Tooltip title="Copiar al portapapeles">
                                    <IconButton 
                                        onClick={() => {
                                            navigator.clipboard.writeText(editableContent);
                                            enqueueSnackbar('Contenido copiado al portapapeles', { 
                                                variant: 'success',
                                                autoHideDuration: 2000
                                            });
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            bgcolor: 'background.paper',
                                            boxShadow: 1,
                                            '&:hover': {
                                                bgcolor: 'action.hover'
                                            }
                                        }}
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            {/* Sección de edición por prompt */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Editar mediante prompt
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        value={editPrompt}
                                        onChange={(e) => setEditPrompt(e.target.value)}
                                        placeholder="Escribe tus instrucciones para editar el contenido..."
                                        disabled={isEditing}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={handlePromptEdit}
                                        disabled={isEditing}
                                        sx={{ minWidth: '120px' }}
                                    >
                                        {isEditing ? (
                                            <>
                                                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                                Editando...
                                            </>
                                        ) : (
                                            'Editar'
                                        )}
                                    </Button>
                                </Box>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                    Una vez tengas el resultado que te guste, puedes darle a:
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={generationType === 'rutina' ? handleImplantRoutine : handleImplantDiet}
                                        disabled={isGenerating}
                                        startIcon={<DownloadIcon />}
                                    >
                                        {isGenerating ? 'Generando PDF...' : 'Generar PDF'}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            {/* Modal de funcionalidad no disponible */}
            <Dialog
                open={openUnavailableModal}
                onClose={() => setOpenUnavailableModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                    <Box sx={{ mb: 3 }}>
                        <PsychologyIcon sx={{ 
                            fontSize: 60, 
                            color: 'primary.main',
                            mb: 2
                        }} />
                        <Typography variant="h6" gutterBottom>
                            Funcionalidad en Desarrollo
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            La generación de programas de {unavailableFeature} estará disponible próximamente.
                            Estamos trabajando para ofrecerte la mejor experiencia posible.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => setOpenUnavailableModal(false)}
                        sx={{
                            mt: 2,
                            px: 4,
                            borderRadius: 2
                        }}
                    >
                        Entendido
                    </Button>
                </DialogContent>
            </Dialog>

            <PlantillaConfigModal
                open={openConfigModal}
                onClose={() => setOpenConfigModal(false)}
                onSave={handleSavePlantillaConfig}
                initialConfig={plantillaConfig}
            />

            {/* Modal de configuración de IA */}
            <Dialog
                open={openIASettings}
                onClose={() => setOpenIASettings(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        minHeight: '80vh',
                        maxHeight: '90vh',
                        borderRadius: 2
                    }
                }}
            >
                <DialogTitle>
                    Configuración de IA
                    <IconButton
                        onClick={() => setOpenIASettings(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <IA />
                </DialogContent>
            </Dialog>

            {/* Modal de animación de éxito */}
            <Dialog
                open={showSuccessAnimation}
                onClose={() => setShowSuccessAnimation(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogContent>
                    <Box sx={{ width: '100%', height: '300px' }}>
                        <Lottie
                            animationData={successAnimation}
                            loop={false}
                            autoplay={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Generar; 