import React, { useState, useEffect } from 'react';
import { 
    IconButton, 
    Popover, 
    Box, 
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton as MuiIconButton,
    Button,
    Paper
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

const tutorials = [
    {
        title: "Pantalla de inicio",
        videoUrl: "https://youtu.be/o1JDnU-qTh4"
    },
    {
        title: "Pantalla de clases",
        videoUrl: "URL_DEL_VIDEO_2"
    },
    {
        title: "Lista de clientes y Clientes",
        videoUrl: "https://youtu.be/fv_RZa1hFcM"
    },
    {
        title: "Mensajes y comunicación con clientes",
        videoUrl: "URL_DEL_VIDEO_5"
    },
    {
        title: "Configuración",
        videoUrl: "https://youtu.be/7YeRFtJ2h9o"
    },
    {
        title: "Generar Rutinas y Dietas con IA",
        videoUrl: "https://youtu.be/tdMmaALztqw"
    }
];

const WelcomeDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    p: 2,
                    background: 'linear-gradient(135deg, #FF6B6B 0%, #FF8E53 100%)',
                }
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                color: 'white',
                p: 3
            }}>
                <EmojiPeopleIcon sx={{ fontSize: 60, mb: 2 }} />
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    ¡Bienvenido a PTD Flash!
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
                    Estamos emocionados de tenerte aquí. Con PTD Flash podrás:
                </Typography>
                
                <Paper elevation={0} sx={{ 
                    p: 2, 
                    mb: 3, 
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 2
                }}>
                    <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        <Typography component="li" sx={{ mb: 1 }}>
                            ✨ Gestionar tus clientes de forma profesional
                        </Typography>
                        <Typography component="li" sx={{ mb: 1 }}>
                            🤖 Crear rutinas y dietas con ayuda de IA
                        </Typography>
                        <Typography component="li" sx={{ mb: 1 }}>
                            📊 Hacer seguimiento del progreso
                        </Typography>
                        <Typography component="li">
                            💪 Optimizar tu trabajo como entrenador
                        </Typography>
                    </Box>
                </Paper>

                <Typography variant="body2" sx={{ mb: 3, textAlign: 'center' }}>
                    Para aprender más sobre la plataforma, usa el botón de tutoriales en la esquina superior derecha.
                </Typography>

                <Button 
                    variant="contained" 
                    onClick={onClose}
                    sx={{
                        backgroundColor: 'white',
                        color: '#FF6B6B',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        }
                    }}
                >
                    ¡Empezar ahora!
                </Button>
            </Box>
        </Dialog>
    );
};

const TutorialButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showWelcome, setShowWelcome] = useState(
        localStorage.getItem('hasSeenWelcome') !== 'true'
    );

    useEffect(() => {
        if (showWelcome) {
            localStorage.setItem('hasSeenWelcome', 'true');
        }
    }, [showWelcome]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
        handleClose();
    };

    const handleVideoClose = () => {
        setSelectedVideo(null);
    };

    const handleWelcomeClose = () => {
        setShowWelcome(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'tutorial-popover' : undefined;

    return (
        <>
            <IconButton
                aria-label="tutorials"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    top: 80,
                    right: 16,
                    bgcolor: 'rgba(244, 143, 177, 0.1)',
                    '&:hover': {
                        bgcolor: 'rgba(244, 143, 177, 0.2)',
                    },
                    zIndex: 1300,
                    borderRadius: 2,
                    px: 2,
                    py: 1,
                    gap: 1,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <SchoolIcon sx={{ color: '#F48FB1' }} />
                <Typography
                    variant="button"
                    sx={{
                        color: '#F48FB1',
                        fontWeight: 'medium'
                    }}
                >
                    Tutoriales
                </Typography>
            </IconButton>
            
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ p: 2, maxWidth: 350 }}>
                    <Typography variant="h6" gutterBottom>
                        Tutoriales
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Aprende a usar la plataforma con nuestros videos tutoriales
                    </Typography>
                    <List>
                        {tutorials.map((tutorial, index) => (
                            <ListItem 
                                button 
                                key={index}
                                onClick={() => handleVideoSelect(tutorial)}
                                sx={{
                                    opacity: 0.7,
                                    '&:hover': {
                                        opacity: 1
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <PlayCircleOutlineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={tutorial.title}
                                    secondary={tutorial.videoUrl && tutorial.videoUrl.includes('youtu.be') ? null : "En grabación"}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>

            <Dialog
                open={Boolean(selectedVideo)}
                onClose={handleVideoClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                    }
                }}
            >
                <DialogTitle>
                    {selectedVideo?.title}
                    <MuiIconButton
                        aria-label="close"
                        onClick={handleVideoClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </MuiIconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedVideo?.videoUrl && selectedVideo.videoUrl.includes('youtu.be') ? (
                        <Box sx={{ 
                            position: 'relative',
                            paddingTop: '56.25%', // 16:9 Aspect Ratio
                            width: '100%',
                            height: 0
                        }}>
                            <iframe
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    border: 'none'
                                }}
                                src={selectedVideo.videoUrl.replace('youtu.be/', 'youtube.com/embed/')}
                                title={selectedVideo.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </Box>
                    ) : (
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            py: 4,
                            px: 2,
                            textAlign: 'center'
                        }}>
                            <Typography variant="h6" gutterBottom color="primary">
                                ¡En grabación!
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                                Estamos grabando este tutorial para ti.
                                Estará disponible muy pronto.
                            </Typography>
                            <Button 
                                variant="outlined" 
                                onClick={handleVideoClose}
                                sx={{ mt: 2 }}
                            >
                                Entendido
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            <WelcomeDialog 
                open={showWelcome} 
                onClose={handleWelcomeClose}
            />
        </>
    );
};

export default TutorialButton; 